import { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Button } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { AuditTrailLines } from '../../components/AuditTrailLines';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { NotificationContext } from '../../context/NotificationContext';
import { TenantContext } from '../../context/TenantContext';
import * as apiCalls from '../../logic/apiCalls';

import sharedCss from '../Shared.module.scss';

class AuditTrailInternal extends Component {

    state = {
        auditTrail: null,
        currentPage: 1,

        userFilterOptions: [],
        selectedUserFilterOption: null,
    }

    async componentDidMount() {
        await this.getAuditTrail(0);
        await this.getAuditTrailUsers();
    }

    getAuditTrail = async (pageNumber, selectedUserId) => {
        console.log("Retrieving audit trail");

        try {
            console.log("[generateReport] Invoking the back-end API to get the the audit trail.");

            let auditTrail = {}
            if (!selectedUserId || selectedUserId === "allusers") {
                auditTrail = await apiCalls.getAuditTrail(this.props.authenticationContext, 20, pageNumber);
            } else {
                auditTrail = await apiCalls.getAuditTrailForUser(this.props.authenticationContext, selectedUserId, 20, pageNumber);
            }
            this.setState({
                auditTrail: auditTrail
            });
        }
        catch (err) {
            console.log(`[getAuditTrail] Error retrieving the audit trail: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    getAuditTrailUsers = async () => {
        console.log("Retrieving audit trail users");

        try {
            console.log("[generateReport] Invoking the back-end API to get the the audit trail users.");

            const users = await apiCalls.getAuditTrailUsers(this.props.authenticationContext);
            this.extractStateFromData(users);
        }
        catch (err) {
            console.log(`[getAuditTrailUsers] Error retrieving the audit trail users: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    extractStateFromData = (users) => {

        var userFiltersOptions = [];
        var allUserFilterOption = {value:"allusers", label:"All users"};
        userFiltersOptions.push(allUserFilterOption);

        users.forEach(user => {
            var userFilterOption = {value:user.userId, label:user.username};
            userFiltersOptions.push(userFilterOption);
        });

        this.setState({
            userFilterOptions: userFiltersOptions,
            selectedUserFilterOption: allUserFilterOption,
        });
    }

    handleSelectedUserFilterChange = (selectedUserFilterOption) => {
        if (this.state.selectedUserFilterOption.value !== selectedUserFilterOption.value) {
            this.setState(
                {
                    selectedUserFilterOption: selectedUserFilterOption,
                    currentPage: 1
                }
            );
            this.getAuditTrail(0, selectedUserFilterOption.value);
        }
    }

    loadPage = async (pageNumber) => {
        var adjustedPageNumber = pageNumber;

        const pageCount = this.state.auditTrail.pageCount;

        if (pageNumber < 1) {
            adjustedPageNumber = 1;
        }
        if (pageNumber > pageCount) {
            adjustedPageNumber = pageCount;
        }

        await this.getAuditTrail(adjustedPageNumber - 1, this.state.selectedUserFilterOption?.value);
        this.setState({
            currentPage: adjustedPageNumber
        });
    }

    render() {
         return <Container fluid className={sharedCss.background}>
            <h1>
                Audit trail
                <Link className="float-right" to={this.props.tenantContext.isDistributor ? this.props.tenantContext.isReseller ? '/portal/reseller-settings' : '/portal/distributor-settings' : '/portal/tenant-settings'}>
                    <Button color="primary">Close</Button>
                </Link>
            </h1>
            <Form>
                <div class="row">
                    <div class="col-lg-3 mb-2">
                        <Select isSearchable={true} value={this.state.selectedUserFilterOption} options={this.state.userFilterOptions} onChange={this.handleSelectedUserFilterChange} placeholder='Select filter...' />
                    </div>
                </div>
            </Form>            
            <div style={{ overflow: 'auto' }}>
                <AuditTrailLines auditTrail={this.state.auditTrail} loadPage={this.loadPage} currentPage={this.state.currentPage}/>
            </div>            
        </Container>;
    }
}

export const AuditTrail = (props) => {
    const authenticationContext = useContext(AuthenticationContext);   
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <AuditTrailInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}