import { Component, useContext } from 'react';
import { ChartPie } from './ChartPie';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as apiCalls from '../logic/apiCalls';
import * as chartLogic from '../logic/chartLogic';

export class DeviceStatisticsChartsInternal extends Component {
    state = {
        isLoading: true,
        selectedPeriod: 'All Time',
    }

    constructor(props) {
        super(props);

        this.devices = {};
        this.periods = ['All Time', 'Last Year', 'Last 6 Months', 'Last 3 Months', 'Last Month']
    }

    async componentDidMount() {
        this.getDeviceStatistics(this.state.selectedPeriod);
    }

    handlePeriodChange = async (e) => {
        this.setState({
            selectedPeriod: e.target.value
        });
        this.getDeviceStatistics(e.target.value);
    }

    getDeviceStatistics = async (selectedPeriod) => {
        this.setState({
            isLoading: true,
        });

        try {
            console.log(`[getDeviceStatistics] Invoking the back-end API to retrieve the device statistics.`);

            const deviceStatistics = await apiCalls.getDeviceStatistics(this.props.authenticationContext, this.periodToDate(selectedPeriod));

            if (deviceStatistics)
            {
                let statistics = [];

                for (const [key, value] of Object.entries(deviceStatistics.deviceBrands)) {
                    statistics.push({
                        brand: key,
                        count: value,
                    })
                  }

                this.devices = chartLogic.getDevices(statistics);
            }
        }
        catch (err) {
            console.log(`[getDeviceStatistics] Error retrieving device statistics: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                     
        }

        this.setState({
            isLoading: false,
        });
    }

    periodToDate = (period) => {
        let date = new Date();

        switch (period) {
            case 'Last Year':
                date.setFullYear(date.getFullYear() - 1);
                break;
            case 'Last 6 Months':
                date.setMonth(date.getMonth() - 6);
                break;
            case 'Last 3 Months':
                date.setMonth(date.getMonth() - 3);
                break;
            case 'Last Month':
                date.setMonth(date.getMonth() - 1);
                break;
            default:
                date = new Date('0001-01-01T00:00:00Z');
                break;
        }

        return date;
    }

    render() {
        return (
            <>
                <h5>Device overview</h5>
                <Form className="mt-4" onSubmit={e => e.preventDefault()}>
                    <Form.Group as={Row} controlId="periodSelector">
                        <Col sm="2">
                            <Form.Control as="select" onChange={this.handlePeriodChange}>
                                {this.periods.map((period) => {
                                    return <option key={period} value={period}>{period}</option>
                                })}                        
                            </Form.Control>
                        </Col>    
                    </Form.Group>
                </Form> 
                <ChartPie data={this.devices.data} valueName={this.devices.countLabel} nameName={this.devices.brandLabel} total={this.devices.count} isLoading={this.state.isLoading} />
            </>
        )
    }
}

export const DeviceStatisticsCharts = (props) => {
    const authenticationContext = useContext(AuthenticationContext);   
    const notificationContext = useContext(NotificationContext);
  
    return (
        <DeviceStatisticsChartsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}
