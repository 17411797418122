import { appConfig } from '../logic/appConfigProvider';

export const msalConfig = {
    authority: 'https://login.microsoftonline.com/common',
    clientId: appConfig.frontendMsalClientId,
    apiScope: appConfig.frontendMsalApiScope,
    graphApiScope: "https://graph.microsoft.com/User.Read",
    redirectUri: document.getElementById('root').baseURI + 'post-login-spa',
    postLogoutRedirectUri: document.getElementById('root').baseURI,
    navigateToLoginRequestUrl: false
};