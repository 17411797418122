import { FC, Fragment } from 'react';
import { Group } from '../../../logic/apiCalls';
import { PortalAccessGroup } from './PortalAccessGroup';

interface PortalAccessGroupsProps {
    groups : Group[];
    handleDelete: (group: Group) => void;
}

export const PortalAccessGroups: FC<PortalAccessGroupsProps> = ({ groups, handleDelete }) => {
   
    const renderGroups = () => {
        const groupsRendered = groups.map(group => {
             return <PortalAccessGroup group={group} handleDelete={handleDelete} ></PortalAccessGroup>
        })
        
        return <Fragment>
                {groupsRendered}
            </Fragment>;
    }

    return (
        <div>
            <table className="table table-striped table-bordered my-2">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">ID</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderGroups()}
                </tbody>
            </table>           
        </div>
    )
}