import { useContext, Component } from 'react';
import { Container } from 'reactstrap';
import { AuthenticationContext } from '../../../msal/AuthenticationContext';
import { TenantContext } from '../../../context/TenantContext';
import { NotificationContext } from '../../../context/NotificationContext';
import { ModalIpAddressAddOrUpdate } from './ModalIpAddressAddOrUpdate';
import { ModalIpAddressDelete } from './ModalIpAddressDelete';
import { WhitelistEntries } from './WhitelistEntries';
import { WhitelistRange } from '../../../logic/apiCalls';
import { entryCount } from '../../../utils/whiteListHelper';
import * as apiCalls from '../../../logic/apiCalls';
import { ButtonWithDisabledTooltip } from '../../../components/ButtonWithDisabledTooltip';
import { Alert } from 'react-bootstrap';

// The maximum allowed number of trunks per tenant.
const maximumNumberOfWhitelistEntries = 100;

type NetworkSettingsInternalProps = {
    authenticationContext: any,
    notificationContext: any,
    tenantContext: any,
}

type NetworkSettingsInternalState = {
    isAddUpdateModalOpen: boolean,
    isDeleteModalOpen: boolean,
    isLoadingData: boolean,
}

class NetworkSettingsInternal extends Component<NetworkSettingsInternalProps, NetworkSettingsInternalState> {
    isLoadingWhitelist: boolean = true;
    isLoadingWanIpAddress: boolean = true;
    isModalUpdate: boolean = false;
    ipAddressToUpdate: WhitelistRange | undefined = undefined;
    ipAddressToDelete: WhitelistRange | undefined = undefined;
    whitelist: Array<WhitelistRange> = [];
    currentWanIpAddress: string = "";

    state: NetworkSettingsInternalState  = {
        isAddUpdateModalOpen: false,
        isDeleteModalOpen: false,
        isLoadingData: true,
    }

    async componentDidMount() {
        this.getWhitelist();
        this.getCurrentWanIpAddress();
    }
    
    getWhitelist = async () => {
        try {
            console.log("[getWhitelist] Invoking the back-end API to get all white-list entries of this tenant.");

            this.whitelist = await apiCalls.getWhiteList(this.props.authenticationContext);

            console.log(`[getWhitelist] Successfully retrieved the white-list: ${this.whitelist.length}`)
        }
        catch (err) {  
            console.log(`[getWhitelist] Error retrieving the white-list: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                 
        }
        finally {
            this.isLoadingWhitelist = false;
            this.setLoadingData();
        }
    }

    getCurrentWanIpAddress = async () => {
        try {
            console.log("[getWanIpAddress] Invoking the back-end API to get the WAN IP address of this browser.");

            this.currentWanIpAddress = (await apiCalls.getWanIpAddress(this.props.authenticationContext)).wanIpAddress; 
        }
        catch (err) {
            console.log(`[getWanIpAddress] Error retrieving the WAN IP address: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        finally {
            this.isLoadingWanIpAddress = false;
            this.setLoadingData();
        }
    }

    setLoadingData = () => {
        const isLoadingData = this.isLoadingWhitelist || this.isLoadingWanIpAddress;

        this.setState({
            isLoadingData: isLoadingData
        });
    }

    showAddModal = () => {
        this.isModalUpdate = false;
        this.setState({
            isAddUpdateModalOpen: true,
            
        });
    };

    showUpdateModal = (ipAddressToUpdate : WhitelistRange) => {
        this.isModalUpdate = true;
        this.ipAddressToUpdate = ipAddressToUpdate;
        
        this.setState({
            isAddUpdateModalOpen: true,
        });
    };

    hideAddUpdateModal = () => {
        this.setState({
            isAddUpdateModalOpen: false,
        });
    }

    hideDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: false,
        });
    }

    GetRangeOrAddressText = (entry : WhitelistRange) : string => {
        if (entry.ipAddressStart === entry.ipAddressEnd) {
            return `IP address '${entry.ipAddressStart}'`;
        } else {
            return `IP range '${entry.ipAddressStart}-${entry.ipAddressEnd}'`;
        }
    }

    handleAdd = async (entry : WhitelistRange) => {
        try {
            this.hideAddUpdateModal();    

            await apiCalls.addWhiteListEntry(this.props.authenticationContext, entry.ipAddressStart, entry.ipAddressEnd, entry.description, entry.location);

            this.props.notificationContext.setNotification(`Adding WAN IP entry`, `${this.GetRangeOrAddressText(entry)} was assigned successfully.`, 'success');

            this.getWhitelist();
        }
        catch (err: any) {
            this.props.notificationContext.setNotification("Adding WAN IP entry", `Failed to assign ${this.GetRangeOrAddressText(entry)}. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    handleUpdate = async (entry : WhitelistRange) => {
        try {
            this.hideAddUpdateModal(); 

            await apiCalls.updateWhitelistEntry(this.props.authenticationContext, entry);

            this.props.notificationContext.setNotification(`Updating WAN IP entry.`, `Entry with ${this.GetRangeOrAddressText(entry)} was updated successfully.`, 'success');

            this.getWhitelist();
        }
        catch (err: any) {  
            this.props.notificationContext.setNotification("Updating WAN IP entry.", `Failed to update with ${this.GetRangeOrAddressText(entry)}. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    handleDeleteRequest = (entry : WhitelistRange) => {
        this.ipAddressToUpdate = entry;
        this.setState({
            isDeleteModalOpen: true,
        });
    }

    handleDelete = async (entry : WhitelistRange) => {
        try {
            this.hideDeleteModal();
            await apiCalls.deleteWhitelistEntry(this.props.authenticationContext, entry);

            this.props.notificationContext.setNotification(`Removing WAN IP entry.`, `${this.GetRangeOrAddressText(entry)} was removed successfully.`, 'success');

            this.getWhitelist();
        }
        catch (err: any) {  
            this.props.notificationContext.setNotification("Removing WAN IP entry.", `Failed to remove ${this.GetRangeOrAddressText(entry)}. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    shouldAddButtonBeEnabled = () => {

        const count = entryCount(this.whitelist);

        if (count >= maximumNumberOfWhitelistEntries) {
            return false;
        }

        return true;
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>
            Network settings
        </h1> 

        if (this.state.isLoadingData) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const whitelistRendered = this.whitelist.length > 0 ? 
            <WhitelistEntries entries={this.whitelist} handleEdit={this.showUpdateModal} handleDelete={this.handleDeleteRequest}/> : 
            <Alert className="mt-3" variant='warning' transition={false}>
                No IP addresses configured.
            </Alert>;

        return (
            <>
                <Container fluid>
                    <ModalIpAddressAddOrUpdate show={this.state.isAddUpdateModalOpen} isUpdate={this.isModalUpdate} ipAddressToUpdate={this.ipAddressToUpdate} onHide={this.hideAddUpdateModal} onAdd={this.handleAdd} onUpdate={this.handleUpdate} whitelist={this.whitelist} currentWanIpAddress={this.currentWanIpAddress} maxIpAddresses={maximumNumberOfWhitelistEntries}/>
                    <ModalIpAddressDelete show={this.state.isDeleteModalOpen} onHide={this.hideDeleteModal} onDelete={this.handleDelete} ipAddressToDelete={this.ipAddressToUpdate}/>
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <hr/>
                    <h2>Configured WAN IP addresses</h2>
                    <p>For security purposes the public WAN IP addresses used by devices to connect to the internet need to be set here. This is to allow a connection to the CyberGate service from the site where the device is installed. There is a maximum of 100 addresses that can be set here. Ranges count as multiple addresses.</p>
                    <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddButtonBeEnabled()} disabledTooltip="Maximum number of IP's defined" onClick={this.showAddModal}>Add WAN IP Address or Range</ButtonWithDisabledTooltip>
                    <br/>
                    <br/>
                    {whitelistRendered}
                    <br/>
                    <br/>
                </Container>
            </>
        );
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const NetworkSettings = (props : any) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <NetworkSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}