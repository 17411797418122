import { useContext, Component } from 'react';
import { Container } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { NotificationContext } from '../../context/NotificationContext';
import { AccessDeviceEntries } from '../../components/AccessDeviceEntries';
import { TeamsAppBanner } from '../../components/TeamsAppBanner'
import { Alert } from 'react-bootstrap';
import * as apiCalls from '../../logic/apiCalls';

class DeviceAccessSettingsInternal extends Component {
    state = {
        isLoadingDevices: true,
        devices: [],
        deviceAccessGroupEntryToAdd: {},
        deviceAccessGroupEntryToDelete: {},
    }

    async componentDidMount() {
        this.getDevices();
    }
    
    getDevices = async () => {
        try {
            console.log("[getDevices] Invoking the back-end API to get all devices of this tenant.");

            const devices = await apiCalls.getDevices(this.props.authenticationContext);

            console.log(`[getDevices] Successfully retrieved the devices: ${devices.length}`)
            this.setState({
                devices: devices
            });
        }
        catch (err) {  
            console.log(`[getDevices] Error retrieving the devices: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                 
        }
        finally {
            this.setState({
                isLoadingDevices: false
            });
        }
    }

    addAccessGroupEntry = async (device, accessGroup) => {
        try {
            await apiCalls.deviceAccessGroupEntryAdd(this.props.authenticationContext, device.deviceId, accessGroup.azureGroupId);

            this.props.notificationContext.setNotification(`Adding group for access to device.`, `Group '${accessGroup.azureGroupName}' was assigned to device '${device.deviceDescription}' successfully.`, 'success');
        }
        catch (err) {
            this.props.notificationContext.setNotification("Adding group for access to device.", `Failed to assign Group '${accessGroup.azureGroupName}' to device '${device.deviceDescription}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    removeAccessGroupEntry = async (device, accessGroup) => {
        try {
            await apiCalls.deviceAccessGroupEntryDelete(this.props.authenticationContext, device.deviceId, accessGroup.id);

            this.props.notificationContext.setNotification(`Removing group for access from device.`, `Group '${accessGroup.azureGroupName}' was unassigned from device '${device.deviceDescription}' successfully.`, 'success');
        }
        catch (err) {  
            this.props.notificationContext.setNotification("Removing group for access from device.", `Failed to unassign Group '${accessGroup.azureGroupName}' from device '${device.deviceDescription}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    handleOnUpdate = async (device, selectedGroups) => {
        for (const selectedGroup of selectedGroups) {
            if (!device.accessGroups.find(currentGroup => currentGroup.azureGroupId === selectedGroup.value.azureGroupId)) {
                console.log(`[handleOnUpdate] added group: ${selectedGroup.value.azureGroupName}.`);
                await this.addAccessGroupEntry(device, selectedGroup.value)
            }
        }

        for (const currentGroup of device.accessGroups) {
            if (!selectedGroups.find(selectedGroup => currentGroup.azureGroupId === selectedGroup.value.azureGroupId)) {
                console.log(`[handleOnUpdate] removed group: ${currentGroup.azureGroupName}.`);
                await this.removeAccessGroupEntry(device, currentGroup)
            }
        }

        await this.getDevices();
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>Device access settings</h1> 

        if (this.state.isLoadingDevices) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const devicesRendered = this.state.devices.length > 0 ? 
            <AccessDeviceEntries tenant={this.props.tenantContext} devices={this.state.devices} onUpdate={this.handleOnUpdate}/> : 
            <Alert className="mt-3" variant='warning' transition={false}>
                No devices configured. Devices can be added in the 'Device' settings page.
            </Alert>;

        return (
            <>
                <Container fluid>
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <p>Assign Microsoft groups to a configured device.</p>
                    <p>Only members of these groups will see this device in the 'Device' tab of the CyberGate for Microsoft Teams app.</p>
                    <TeamsAppBanner/>
                    <hr/>
                    {devicesRendered}                  
                    <br/>
                    <br/>
                </Container>
            </>
        );
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const DeviceAccessSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <DeviceAccessSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}