import { Component } from 'react';
import { WarningIconWithTooltip } from './WarningIconWithTooltip';

import css from './CallGroupSupervisor.module.scss';

export class CallGroupSupervisor extends Component {
    render () 
    {
        const isValid = this.props.supervisor.teamsUserName != null && this.props.supervisor.teamsUserName.length > 0;
        const teamsUserName = isValid ? this.props.supervisor.teamsUserName : this.props.supervisor.teamsUserId;

        return <div className={css.flexContainer}>
            <div>
                {teamsUserName}
            </div>
            <div className={css.flexItemLeft}>
                <WarningIconWithTooltip show={!isValid} iconSize={this.props.iconSize} tooltip="Teams user ID can not be resolved to an username. This user was probably removed from Microsoft Entra." />
            </div>
        </div>
    }
}