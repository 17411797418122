// Method will make an educated guess if the provided string is already a displaystring, in which case the string is returned as-is,
// or else perform a camelcase-to-displaystring conversion. This means the first character is capitalized and every captial within the word 
// is seen as a word boundary and will be separated with a space and the first character of this word is converted to lowercase.
// When multiple JSON names are separated by a comma, these are treated separately.
// Some examples:
// "John Doe" => "John Doe"
// "signedInUser" => "Signed in user"
// "audio,video,screen" = > "Audio, Video, Screen"
export function toDisplayString(input) {
    if (input === undefined || input === null || input.length === 0) {
        return "";
    }

    let inputString = input.toString();

    if (inputString.search(' ') > 0) {
        return inputString; // Already a display string.
    }

    const splitted = inputString.split(',');

    let converted = [];
    for (let fragment of splitted) {
        if (fragment.length === 0) {
            continue;
        }

        const convertedFragment = convertCamelCaseToDisplayString(fragment.trim());
        converted.push(convertedFragment);
    }

    return converted.join(', ');
}

// Method will capitalize the first character and will create separate words of every capitalized character 
// in the string. The first character of this word is converted to lowercase.
// Example: 'signedInUser' => 'Signed in user'
function convertCamelCaseToDisplayString(input) {
    let output = [];

    output.push(input[0].toUpperCase());
    for (let i = 1; i < input.length; i++) {
        const character = input[i];

        if (isUpperCase(character)) {
            output.push(' ');
            output.push(character.toLowerCase());
        } else {
            output.push(character);
        }
    }

    return output.join('');
}

// Method returns true if the provided character is an uppercase character.
function isUpperCase(character) {
    if (character === character.toUpperCase() &&
        character !== character.toLowerCase()) {
        return true;
    } else {
        return false;
    }
}