import { Component } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EditButton } from './EditButton';
import { AccessGroupEntries } from './AccessGroupEntries';
import { ModalEditAccessGroupEntries } from './ModalEditAccessGroupEntries';

import css from './DeviceEntry.module.scss';

export class AccessDeviceEntry extends Component {

    state = {
        isEditAccessGroupEntriesModalOpen: false,
    }

    showEditAccessGroupEntriesModal = () => {
        this.setState({
            isEditAccessGroupEntriesModalOpen: true
        }); 
    }

    hideEditAccessGroupEntriesModal = () => {
        this.setState({
            isEditAccessGroupEntriesModalOpen: false,
        }); 
    }

    getLabelWithMaxLength = (label, maxLenght) => {
         if (!label) {
            return null;
        }

        if (label.length <= maxLenght) {
            return <>{label}</>
        }

        const labelTrimmed = label.substring(0, maxLenght - 2).trim() + "...";

        return <>
            <OverlayTrigger 
                placement='right'
                overlay={
                    <Tooltip>
                        {label}
                    </Tooltip>
                }
            >
                <span>{labelTrimmed}</span>
            </OverlayTrigger>
        </>;
    }

    handleOnUpdate = (selectedGroups) => {
        this.props.onUpdate(this.props.device, selectedGroups);
        this.hideEditAccessGroupEntriesModal();
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const groupNamesRendered = this.props.device.accessGroups?.length > 0 ?
            <AccessGroupEntries accessGroups={this.props.device.accessGroups} /> :
            <i>No access group assigned, all users will see this device</i>
    
        return (
            <>
                <ModalEditAccessGroupEntries 
                    device={this.props.device}
                    show={this.state.isEditAccessGroupEntriesModalOpen}
                    onHide={this.hideEditAccessGroupEntriesModal}
                    onUpdate={this.handleOnUpdate} />
                <tr>
                    <td>
                        <div className={css.flexContainer}>
                            {this.getLabelWithMaxLength(this.props.device.deviceDescription, 50)}
                        </div>
                    </td>
                    <td>
                    <Container fluid>
                        <Row>
                            <Col xs={9}>
                                {groupNamesRendered}
                            </Col>
                            <Col>
                                <EditButton className="float-right" tooltip="Modify access by Microsoft groups" onClick={this.showEditAccessGroupEntriesModal} placement='left'/>
                            </Col>
                        </Row>  
                        </Container>                      
                    </td>
                </tr>
            </>);
    }
}