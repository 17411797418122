import { Component } from 'react';
import { DistributorReportLicenseValue } from './DistributorReportLicenseValue';

import css from './DistributorReport.module.scss';

export class DistributorReportCell extends Component {
    render() {
        const month = this.props.month;       
        const licenseLine = <DistributorReportLicenseValue licensesMax={month.max} licensesInUse={month.inUse}/>

        if (month.firstMonth) {
            return <td className={`align-middle ${month.lastMonth?css.tableCellOrange:css.tableCellGreen} ${css.tableCellAlignCenter}`}>
                    {licenseLine}
                </td>;
        } else if (month.lastMonth) {
            return <td className={`align-middle ${css.tableCellOrange} ${css.tableCellAlignCenter}`}>
                    {licenseLine}
                </td>;
        } else if (month.expectedPayment > 0) {
            return <td className={`align-middle ${month.active?css.tableCellGreen:""} ${css.tableCellAlignCenter}`}>
                    {licenseLine}
                </td>;
        } else if (month.active) {
            return <td className={`align-middle ${css.tableCellLightGreen} ${css.tableCellAlignCenter}`}>
                    {licenseLine}
                </td>
        }
        
        return <td className={`align-middle`}>
                </td>;
    }
}