import { Component } from 'react';
import { Button } from 'reactstrap';
import { Spinner, Alert } from 'react-bootstrap';
import { AuthenticationContext } from '../msal/AuthenticationContext';

export class AdminConsent extends Component {
    static contextType = AuthenticationContext;

    redirectToAdminConsent = () => {
        const authenticationContext = this.context;
        authenticationContext.redirectToAdminConsent();
    }

    render() {
        const spinnerStyle = {
            width: '40px',
            height: '40px',
            padding: '8px',
            display: 'inline-block'
        }

        if (this.props.adminConsentGrantedFeatures === "undetermined")
        {
            return <div>
                    <h4>Admin consent</h4>
                    <div>
                        <div style={spinnerStyle} className='float-none'><Spinner animation='border' role='status' size='sm' variant='secondary'/></div>
                    </div>
                    <p />
                </div>;
        } 
        else if (this.props.adminConsentGrantedFeatures.includes("meeting"))
        {
            return <div>
                    <h4>Admin consent</h4>
                    <p />
                    <p>Admin consent has been provided for this tenant.</p>            
                </div>;
        }
        else if (this.props.adminConsentGrantedFeatures.includes("recording") || this.props.adminConsentGrantedFeatures.includes("initial"))
        {
            return <div>
                    <h4>Admin consent</h4>
                    <p />
                    <Alert variant='warning' transition={false}>
                        The CyberGate service needs a new set of access rights to be granted to your Azure environment to enable new functionality.
                        These rights have to be approved by a global administrator of your organization.
                    </Alert>           
                    <p>By clicking the link below, you will be redirected to Microsoft to grant these access rights.</p>
                    <Button className="mb-3" color="primary" onClick={this.redirectToAdminConsent}>Update admin consent</Button>
                </div>;
        }
        else 
        {
            return <div>
                    <h4>Admin consent</h4>
                    <p />
                    <Alert variant='warning' transition={false}>
                        The CyberGate service needs to be granted a limited set of access rights to your Azure environment to function correctly.
                        These rights have to be approved by a global administrator of your organization.
                    </Alert>           
                    <p>By clicking the link below, you will be redirected to Microsoft to grant these access rights.</p>
                    <Button className="mb-3" color="primary" onClick={this.redirectToAdminConsent}>Provide admin consent</Button>
                </div>            
        }
    }
}