import { Component } from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

import { InfoIcon }  from './InfoIcon';

export class TenantCallForwardingConfig extends Component {

    state = {
        isConfirmChangeCallForwardingModalOpen: false,
    }

    async componentDidMount() {
    }
    
    // Confirm change of call forwarding setting showing/hiding stuff.
    showConfirmChangeCallForwardingModal = () => {
        this.setState({
            isConfirmChangeCallForwardingModalOpen: true
        });
    };

    hideConfirmChangeCallForwardingModal = () => {
        this.setState({
            isConfirmChangeCallForwardingModalOpen: false
        });
    };

    executeCallForwardingChange = () => {
        console.log(`Change call forwarding`);

        const newCallForwardingEnabledSetting = !this.props.callForwardingEnabled;

        this.props.onCallForwardingChanged(newCallForwardingEnabledSetting);
        this.hideConfirmChangeCallForwardingModal();
    }

    render() {

        const confirmChangeCallForwardingModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmChangeCallForwardingModalOpen} onHide={this.hideConfirmChangeCallForwardingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.callForwardingEnabled ?
                        <>
                            Are you sure you want to disable call forwarding?<br />
                            The Teams call will no longer be forwarded to other users or user groups.
                        </> :
                        <>
                            Are you sure you want to enable call forwarding?<br />
                            Be aware that the voicemail might answer the call.
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmChangeCallForwardingModal}>
                        Cancel
            </Button>
                    <Button color="primary" onClick={this.executeCallForwardingChange}>
                        Change
            </Button>
                </Modal.Footer>
            </Modal>
        </>

        const callForwardingText = this.props.callForwardingEnabled ?
            "Call forwarding is enabled" :
            "Call forwarding is disabled";

        const callForwardingDescription = this.props.callForwardingEnabled ?
            "The Teams call might be forwarded to another user or user group if this is configured for the called Teams user. Be aware that also the voicemail might answer the call. If this is not the desired behavior, the call forwarding can be disabled." :
            "The Teams call will not be forwarded to another user or user group, even if this is configured for the called Teams user. The voicemail will never answer the call. If this is not the desired behavior, the call forwarding can be enabled.";

        const callForwardingButtonText = this.props.callForwardingEnabled ?
            "Disable call forwarding" : 
            "Enable call forwarding";

        return (<>
                {confirmChangeCallForwardingModalRendered}
                <div>
                    <h4>
                        Call forwarding <InfoIcon tooltip='This setting defines the way Teams handles the routing of the call.'/>
                    </h4>
                    <p/>
                    <p>{callForwardingText}</p>
                    <p>{callForwardingDescription}</p>
                    <Button className="mb-3" color="primary" onClick={this.showConfirmChangeCallForwardingModal}>{callForwardingButtonText}</Button> 
                </div>
            </>);
    }
}
