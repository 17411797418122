import { Component } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { toDisplayString } from '../utils/displayString';

export class AuditTrailDataCell extends Component {
    getLabelWithMaxLength = (label, maxLenght) => {
        if (!label) {
            return null;
        }

        if (label.length <= maxLenght) {
            return <>{label}</>
        }

        const labelTrimmed = label.substring(0, maxLenght - 2).trim() + "...";

        return <>
            <OverlayTrigger 
                placement='left'
                overlay={
                    <Tooltip>
                        {label}
                    </Tooltip>
                }
            >
                <span>{labelTrimmed}</span>
            </OverlayTrigger>
        </>;
    }

    render() {
        const data = JSON.parse(this.props.auditData); 
        
        return <td className={`align-middle`}>
            <ul>
                {Object.keys(data).map((value, index) => {
                    // if (value != "tenantId" && value.endsWith("Id")) {
                    //     return null;
                    // }
                    return <li>{toDisplayString(value)}: {this.getLabelWithMaxLength(data[value]?.toString() ?? "null", 100)}</li>
                })} 
            </ul>
        </td>;
    }
}