import { Component } from 'react';
import { WarningIconWithTooltip } from './WarningIconWithTooltip';

import css from './AccessGroupEntry.module.scss';

export class AccessGroupEntry extends Component {
    render () {
        const isValid = this.props.accessGroup.azureGroupName != null && this.props.accessGroup.azureGroupName.length > 0;
        const groupName = isValid ? `${this.props.accessGroup.azureGroupName} (${this.props.accessGroup.azureGroupType})` : this.props.accessGroup.azureGroupId;
       
        return (
            <li>
                <div className={css.flexContainer}>
                    <div>
                        {groupName}
                    </div>
                    <div className={css.flexItemLeft}>
                        <WarningIconWithTooltip show={!isValid} iconSize={"25px"} tooltip="Teams group ID can not be resolved to an name. This group was probably removed from Microsoft Entra." />
                    </div>
                </div>
            </li>
        );
    }
}