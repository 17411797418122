import { Component } from 'react';
import { DistributorReportLicenseValue } from './DistributorReportLicenseValue';

import css from './DistributorReport.module.scss';

export class DistributorTotalReportLine extends Component {
    render() {
        const totalMaxLicenses = this.props.totalMaxLicenses;
        const totalInUseLicenses = this.props.totalInUseLicenses;
        const monthLicenses = this.props.monthLicenses;
        const showDetails = this.props.showDetails;

        const detailColumns = showDetails ? <>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            </> :
            null

        return (
            <tr>
                <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
                {detailColumns}
                <td className={`align-middle ${css.tableCellAlignRight} ${css.tableCellGray}`}>
                    <b>Total</b>
                </td>
                <td className={`align-middle ${css.tableCellAlignCenter} ${css.tableCellGray}`}>
                    <DistributorReportLicenseValue licensesMax={totalMaxLicenses} licensesInUse={totalInUseLicenses} isBold/>
                </td>
                {monthLicenses.map((value, index) => {
                    return <td key={index} className={`align-middle ${css.tableCellAlignCenter} ${css.tableCellGray}`}>
                            <DistributorReportLicenseValue licensesMax={value.max} licensesInUse={value.inUse} isBold/>
                        </td>                      
                    })}
            </tr>
        )
    }
}
