import { compareCaseInsensitive } from './misc';
import { Group } from '../logic/apiCalls';

export interface GroupOption {
    value: Group;
    label: string;
}

export interface GroupedOption {
    label: string;
    options: GroupOption[];
}

export const createGroupOption = (group: Group): GroupOption => {
    return { value: group, label: group.azureGroupName }
}

export const createSelectedGroupOptions = (groups: Array<Group>): GroupOption[] => {
    let selectedGroupedOptions = [];

    for (const group of groups) {


        selectedGroupedOptions.push({ value: group, label: group.azureGroupName });
    }
    return selectedGroupedOptions;
}

// Method creates the options in the format as required for the 'react-select' component.
// The options are grouped under the different group types ('Microsoft365', 'Team' and 'Security')
// and then sorted on name.
export const createGroupOptions = (groups: Array<Group>): GroupedOption[] => {
    const groupTypeNames = groups.map((item) => item.azureGroupType).filter((value, index, self) => self.indexOf(value) === index).sort();

    let groupedOptions: GroupedOption[] = [];
    for (const groupTypeName of groupTypeNames) {
      let groupOption: GroupedOption = {
        label: groupTypeName,
        options: groups.filter((item) => item.azureGroupType === groupTypeName).sort((a, b) => compareCaseInsensitive(a.azureGroupName, b.azureGroupName)).map((item) => createGroupOption(item)),
      };
  
      groupedOptions.push(groupOption);
    }

    return groupedOptions;
}