import { ServerErrorResponse } from '../utils/errors';
import log from 'loglevel';

export type ApiTokenResponse = {
    token: string,
    isSso : boolean
}

export type ApiTokenProviderCallback = () => Promise<ApiTokenResponse>;

let apiTokenProviderCallback: ApiTokenProviderCallback | null = null;

// Method registers the provided callback which is invoked for every request for which a token is required.
export function RegisterApiTokenProviderCallback(callback: ApiTokenProviderCallback) : void {
    apiTokenProviderCallback = callback;
}

// Method clears the callback which is invoked for every request for which a token is required.
export function ClearApiTokenProviderCallback() : void {
    apiTokenProviderCallback = null;
}

export interface Group {
    azureGroupId: string,
    azureGroupName: string,
    azureGroupType: string
}

export interface WhitelistRange {
    id: number,
    ipAddressStart: string,
    ipAddressEnd: string,
    description: string,
    location: string,
    enabled: boolean
}

async function executeJsonMethodOnApi(authentication: any, method: string, relativeApiPath: string, jsonBody: any = null): Promise<any> {
    const response = await executeMethodOnApi(authentication, method, relativeApiPath, jsonBody);
    return await response.json();
}

async function executeBlobMethodOnApi(authentication: any, method: string, relativeApiPath: string, jsonBody: any = null): Promise<Blob> {
    const response = await executeMethodOnApi(authentication, method, relativeApiPath, jsonBody);
    return await response.blob();
}

async function executeMethodOnApi(authentication: any, method: string, relativeApiPath: string , bodyData: any = null): Promise<Response> {
    try {
        log.debug(`[executeCallOnBackend] Invoking '${method}' on '${relativeApiPath}'...`)

        const accessToken = await authentication.getApiTokenAsync();
    
        const url = `/api/${relativeApiPath}`;
        
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Authorization', `Bearer ${accessToken.accessToken}`);

        const requestParameters: RequestInit = {
            method: method,
            headers: requestHeaders
        };
    
        // Add the body if one was provided.
        if (bodyData) {
            if (bodyData instanceof FormData)
            {
                requestParameters.body = bodyData;
            }
            else
            {
                requestHeaders.set('Content-Type', 'application/json');
                requestParameters.body = JSON.stringify(bodyData);
            }
        }
    
        const response = await fetch(url, requestParameters);
    
        if (!response.ok) {
            // Some non-success response received.
            throw new ServerErrorResponse(null, response.status, response.statusText, await response.text());
        }
    
        // A success response is received from the back-end.
        return response;
    }
    catch (error: any) {
        if (error instanceof ServerErrorResponse) {
            const responseBody = error.responseBody ? `\r\n${error.responseBody}` : "";
            log.error(`Server error response received while invoking '${method}' on '/${relativeApiPath}' endpoint. Received ${error.statusCode} '${error.statusText}'.${responseBody}`);
        } else {
            log.error(`Error occurred while invoking '${method}' on '/${relativeApiPath}': ${error.message}`);    
        }

        throw (error);
    }
}

export async function confirmLogin(authentication: any): Promise<any> {
    return await executeMethodOnApi(authentication, 'POST', 'tenant/loginconfirmation', null);
}

export async function getTenant(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant', null);
}

export async function getCommunicationTestScript(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/communicationtestscript', null);
}

export async function tenantUpdateCallForwarding(authentication: any,
                                            callForwardingEnabled: boolean): Promise<any> {
    const tenantDto = {
        callForwardingEnabled: callForwardingEnabled,
    }

    return await executeMethodOnApi(authentication, 'PATCH', 'tenant', tenantDto);
}

export async function tenantUpdateTrunkSupport(authentication: any,
                                            trunkSupportEnabled: boolean): Promise<any> {
    const tenantDto = {
        trunkSupportEnabled: trunkSupportEnabled,
    }

    return await executeMethodOnApi(authentication, 'PATCH', 'tenant', tenantDto);
}

export async function tenantUpdateSecureOnlyPolicy(authentication: any,
                                            secureOnlyPolicyEnabled: boolean): Promise<any> {
    const tenantDto = {
        secureOnlyPolicyEnabled: secureOnlyPolicyEnabled,
    }

    return await executeMethodOnApi(authentication, 'PATCH', 'tenant', tenantDto);
}

export async function tenantUpdateCallRecording(authentication: any,
                                            callRecordingEnabled: boolean): Promise<any> {
    const tenantDto = {
        callRecordingEnabled: callRecordingEnabled
    }

    return await executeMethodOnApi(authentication, 'PATCH', 'tenant', tenantDto);
}

export async function getTenantLogo(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/logo', null);
}

export async function getDistributor(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/distributor', null);
}

export async function addTenantByDistributor(authentication: any,
                                    tenantId: string,
                                    tenantLabel: string,
                                    emailAddress: string,
                                    isDistributor: boolean,
                                    quantity: number): Promise<any> {
    const tenantDto = {
        tenantId: tenantId,
        tenantLabel: tenantLabel,
        emailAddress: emailAddress,
        enabled: true,
        isDistributor: isDistributor,
        quantity: quantity,
    }

    return await executeMethodOnApi(authentication, 'POST', `tenant/distributor`, tenantDto);
}

export async function updateTenantByDistributor(authentication: any,
                                    tenantId: string,
                                    tenantLabel: string,
                                    emailAddress: string,
                                    enabeled: boolean,
                                    isDistributor: boolean,
                                    quantity: number): Promise<any> {
    const tenantDto = {
        tenantLabel: tenantLabel,
        emailAddress: emailAddress,
        enabled: enabeled,
        isDistributor: isDistributor,
        quantity: quantity,
    }

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/distributor/${tenantId}`, tenantDto);
}


export async function updateDistributorQuantity(authentication: any, quantity: number): Promise<any> {
    var quantityDto = {
        quantity: quantity
    }

    return await executeMethodOnApi(authentication, 'POST', `tenant/distributor/quantity`, quantityDto);
}

export async function getDistributorLogo(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/distributor/logo', null);
}

export async function setDistributorLogo(authentication: any, file: FormData): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'POST', 'tenant/distributor/logo', file);
}

export async function getSubscriptions(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/subscription', null);
}

export async function resolveMarketplaceToken(authentication: any, token: string): Promise<any> {
    var tokenDto = {
        Token: token,      
    }

    return await executeJsonMethodOnApi(authentication, 'POST', 'marketplace/resolvetoken', tokenDto);
}

export async function activateSubscription(authentication: any, subscriptionId: string): Promise<any> {

    return await executeMethodOnApi(authentication, 'POST', `marketplace/activatesubscription/${subscriptionId}`, null);
}

export async function updateMarketplaceSubscriptionQuantity(authentication: any, subscriptionId: string, quantity: number): Promise<any> {
    var quantityDto = {
        quantity: quantity           
    }

    return await executeMethodOnApi(authentication, 'POST', `marketplace/subscription/${subscriptionId}/quantity`, quantityDto);
}


export async function getWhiteList(authentication: any): Promise<Array<WhitelistRange>> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/whitelist', null);
}

export async function addWhiteListEntry(authentication: any, ipAddressStart: string, ipAddressEnd: string, description: string, location: string): Promise<any> {
    var whiteListDto = {
        ipAddressStart: ipAddressStart,
        ipAddressEnd: ipAddressEnd,
        description: description,
        location: location,
    }

    return await executeMethodOnApi(authentication, 'POST', 'tenant/whitelist', whiteListDto);
}

export async function updateWhitelistEntry(authentication: any, entry: WhitelistRange): Promise<any> {
    var whiteListDto = {
        description: entry.description,
        location: entry.location,
        enabled: entry.enabled
    }

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/whitelist/${entry.id}`, whiteListDto);
}

export async function deleteWhitelistEntry(authentication: any, entry: WhitelistRange): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/whitelist/${entry.id}`, null);
}

export async function getTrunks(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/trunk', null);
}

export async function addTrunk(authentication: any, name: string, autoAddEnabled: boolean): Promise<any> {
    var trunkDto = {
        name: name,
        autoAddEnabled: autoAddEnabled,
    };

    return await executeMethodOnApi(authentication, 'POST', 'tenant/trunk', trunkDto);
}

export async function updateTrunk(authentication: any, sipTrunkId: string, name: string, autoAddEnabled: boolean): Promise<any> {
    var trunkDto = {
        name: name,
        autoAddEnabled: autoAddEnabled
    };

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/trunk/${sipTrunkId}`, trunkDto);
}

export async function deleteTrunk(authentication: any, sipTrunkId: string): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/trunk/${sipTrunkId}`, null);
}

export type VideoSettings = {
    isEnforceGroupCallsEnabled: boolean,
    isSupportiveUserCreated: boolean,
    isVideoFor1on1CallsDisabled: boolean
}

export async function getVideoSettings(authentication: any): Promise<VideoSettings> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/videosettings', null);
}

export async function getDevices(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/device', null);
}

export async function getDevicesStatus(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/device/status`, null);
}

export async function getDeviceStatus(authentication: any, deviceId: string): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/device/${deviceId}/status`, null);
}

export async function addDevice(authentication: any, displayName: string, sipUsername: string, type: string, location: string, recorded: boolean, twoWayVideo: boolean, incomingCalls: boolean, doorOpenDtmf: string): Promise<any> {
    var deviceDto = {
        deviceDescription: displayName,
        sipUsername: sipUsername,
        type: type,
        location: location,
        recorded: recorded,
        twoWayVideo: twoWayVideo,
        incomingCalls: incomingCalls,
        doorOpenDtmf: doorOpenDtmf,
    };

    return await executeMethodOnApi(authentication, 'POST', 'tenant/device', deviceDto);
}

export async function trunkAddDevice(authentication: any, sipTrunkId: string, displayName: string, sipUsername: string, type: string, location: string, recorded: boolean, twoWayVideo: boolean, incomingCalls: boolean, doorOpenDtmf: string): Promise<any> {
    var deviceDto = {
        sipTrunkId: sipTrunkId,
        deviceDescription: displayName,
        sipUsername: sipUsername,
        type: type,
        location: location,
        recorded: recorded,
        twoWayVideo: twoWayVideo,
        incomingCalls: incomingCalls,
        doorOpenDtmf: doorOpenDtmf,
    };

    return await executeMethodOnApi(authentication, 'POST', 'tenant/device', deviceDto);
}

export async function updateDevice(authentication: any, deviceId: string, displayName: string, sipUsername: string, type: string, location: string, recorded: boolean, twoWayVideo: boolean, incomingCalls: boolean, doorOpenDtmf: string): Promise<any> {
    var deviceDto = {
        deviceDescription: displayName,
        sipUsername: sipUsername,
        type: type,
        location: location,
        recorded: recorded,
        twoWayVideo: twoWayVideo,
        incomingCalls: incomingCalls,
        doorOpenDtmf: doorOpenDtmf,
    };

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/device/${deviceId}`, deviceDto);
}

export async function deleteDevice(authentication: any, deviceId: string): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/device/${deviceId}`, null);
}

export async function getDeviceSecurityGroups(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/device/securitygroups`, null);
}

export async function deviceAccessGroupEntryAdd(authentication: any, deviceId: string, azureId: string): Promise<any> {
    var deviceAccessGroupDto = {
        azureGroupId: azureId,
    };

    return await executeMethodOnApi(authentication, 'POST', `tenant/device/${deviceId}/accessgroup`, deviceAccessGroupDto);
}

export async function deviceAccessGroupEntryDelete(authentication: any, deviceId: string, accessGroupId: number): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/device/${deviceId}/accessgroup/${accessGroupId}`, null);
}

export async function getFeatureConfigurationScript(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/featureconfigurationscript', null);
}

export async function getMultiringGroups(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/multiringgroup', null);
}

export async function getMultiringGroup(authentication: any, callGroupId: string): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/multiringgroup/${callGroupId}`, null);
}

export async function addMultiringGroup(authentication: any, name: string, description: string): Promise<any> {
    var multiringGroupDto = {
        name: name,
        description: description,
    }

    return await executeMethodOnApi(authentication, 'POST', 'tenant/multiringgroup', multiringGroupDto);
}

export async function updateMultiringGroup(authentication: any, callGroupId: string, name: string, description: string): Promise<any> {
    var multiringGroupDto = {
        name: name,
        description: description,
    }

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/multiringgroup/${callGroupId}`, multiringGroupDto);
}

export async function deleteMultiringGroup(authentication: any, callGroupId: string): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/multiringgroup/${callGroupId}`, null);
}

export async function getGlobalMeetingSettings(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/meeting/global`, null);
}

export async function updateGlobalMeetingSettings(authentication: any, timeoutNoUsersLeftSeconds: number, timeoutNoUsersJoinedSeconds: number): Promise<any> {
    var globalMeetingSettingsDto = {
        timeoutNoUsersLeftSeconds: timeoutNoUsersLeftSeconds,
        timeoutNoUsersJoinedSeconds: timeoutNoUsersJoinedSeconds,
    };

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/meeting/global`, globalMeetingSettingsDto);
}

export async function getMeetings(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/meeting', null);
}

export async function getMeeting(authentication: any, callGroupId: string): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/meeting/${callGroupId}`, null);
}

export async function addMeeting(authentication: any, name: string, description: string, meetingUrl: string): Promise<any> {
    var meetingDto = {
        name: name,
        description: description,
        meetingUrl: meetingUrl,
    }

    return await executeMethodOnApi(authentication, 'POST', 'tenant/meeting', meetingDto);
}

export async function updateMeeting(authentication: any, callGroupId: string, name: string, description: string, meetingUrl: string): Promise<any> {
    var meetingDto = {
        name: name,
        description: description,
        meetingUrl: meetingUrl,
    }

    return await executeMethodOnApi(authentication, 'PATCH', `tenant/meeting/${callGroupId}`, meetingDto);
}

export async function deleteMeeting(authentication: any, callGroupId: string): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/meeting/${callGroupId}`, null);
}

export async function getCallGroups(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/callgroup', null);
}

export async function addCallGroupParticipant(authentication: any, callGroupId: string, teamsUserName: string): Promise<any> {
    var callGroupParticipantDto = {
        teamsUserName: teamsUserName
    }

    return await executeMethodOnApi(authentication, 'POST', `tenant/callgroup/${callGroupId}/participant`, callGroupParticipantDto);
}

export async function deleteCallGroupParticipant(authentication: any, callGroupId: string, participantId: string): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/callgroup/${callGroupId}/participant/${participantId}`, null);
}

export async function addCallGroupSupervisor(authentication: any, callGroupId: string, teamsUserName: string): Promise<any> {
    var callGroupSupervisorDto = {
        teamsUserName: teamsUserName
    }

    return await executeMethodOnApi(authentication, 'POST', `tenant/callgroup/${callGroupId}/supervisor`, callGroupSupervisorDto);
}

export async function deleteCallGroupSupervisor(authentication: any, callGroupId: string, supervisorId: number): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/callgroup/${callGroupId}/supervisor/${supervisorId}`, null);
}

export async function getPortalAccess(authentication: any): Promise<Array<Group>> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/portalaccess', null);
}

export async function addPortalAccess(authentication: any, azureGroupId: string): Promise<any> {
    var portalAccessDto = {
        azureGroupId: azureGroupId
    }

    return await executeMethodOnApi(authentication, 'POST', `tenant/portalaccess`, portalAccessDto);
}

export async function deletePortalAccess(authentication: any, azureGroupId: string): Promise<any> {
    return await executeMethodOnApi(authentication, 'DELETE', `tenant/portalaccess/${azureGroupId}`, null);
}

export async function confirmAdminConsent(authentication: any): Promise<any> {
    return await executeMethodOnApi(authentication, 'POST', 'tenant/adminconsentconfirmed', null);
}

export async function getAdminConsentGrantedFeatures(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/adminconsentgrantedfeatures', null);
}

export async function getWanIpAddress(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/mywanipaddress', null);
}

export async function getAvailableGroups(authentication: any): Promise<Array<Group>> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/groups', null);
}

export async function getAvailableDomains(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/domains', null);
}

export async function resolveUsername(authentication: any, teamsUserName: string): Promise<any> {
    var resolveUser = {
        teamsUserName: teamsUserName
    }

    return await executeJsonMethodOnApi(authentication, 'POST', 'tenant/resolveusername', resolveUser);
}

export async function isCyberGateNameAvailable(authentication: any, name: string): Promise<any> {
    var cyberGateNameDto = {
        name: name
    }

    return await executeJsonMethodOnApi(authentication, 'POST', 'tenant/cybergatenameavailable', cyberGateNameDto);
}

export async function getAuditTrail(authentication: any, pageSize: number, pageNumber: number): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/audittrail?pageSize=${pageSize}&pageNumber=${pageNumber}`, null);
}

export async function getAuditTrailForUser(authentication: any, userId: string, pageSize: number, pageNumber: number): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/audittrail/${userId}?pageSize=${pageSize}&pageNumber=${pageNumber}`, null);
}

export async function getAuditTrailUsers(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/audittrail/users', null);
}

export async function getDistributorReport(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/distributorreport', null);
}

export async function getTenantReport(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/tenantreport', null);
}

export async function getGlobalStatistics(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/globalstatistics', null);
}

export async function getDeviceStatistics(authentication: any, fromDate: Date): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', `tenant/devicestatistics?fromDate=${fromDate.toISOString()}`, null);
}

export async function getMarketplaceReport(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/marketplacereport', null);
}

export async function getStatisticsReport(authentication: any): Promise<any> {
    return await executeJsonMethodOnApi(authentication, 'GET', 'tenant/statisticsreport', null);
}

export async function getDistributorReportCsv(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/distributorreportcsv', null);
}

export async function getTenantReportCsv(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/tenantreportcsv', null);
}

export async function getMarketplaceReportCsv(authentication: any): Promise<any> {
    return await executeBlobMethodOnApi(authentication, 'GET', 'tenant/marketplacereportcsv', null);
}

export async function updateMicrosoftPaymentHistoryFile(authentication: any, file: FormData): Promise<any> {

    return await executeMethodOnApi(authentication, 'POST', `tenant/microsoftpaymenthistory`, file);
}

export async function updateMicrosoftTransactionHistoryFile(authentication: any, file: FormData): Promise<any> {

    return await executeMethodOnApi(authentication, 'POST', `tenant/microsofttransactionhistory`, file);
}
