import { Component, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup  from 'react-bootstrap/InputGroup';
import { Alert } from 'react-bootstrap';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';
import { CallGroupParticipants } from './CallGroupParticipants';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import { DeleteButton } from './DeleteButton';
import { CopyButton } from './CopyButton';
import { EditButton } from './EditButton';
import * as SipHelper from '../utils/sipHelper';
import * as apiCalls from '../logic/apiCalls';

import css from './Meeting.module.scss';

// The maximum allowed number of participants in a meeting.
const maximumNumberOfParticipants = 15;

class MeetingInternal extends Component {
    state = {
        newParticipantUsername: "",
        newParticipantDomainName: this.props.verifiedDomains[0].name,
        meeting: this.props.meeting,
        participants: this.props.meeting.participants,
    }

    getParticipants = async () => {
        try {
            console.log("[getParticipants] Invoking the back-end API to get all participants of this meeting.");

            const meeting = await apiCalls.getMeeting(this.props.authenticationContext, this.props.meeting.callGroupId);

            this.setState({
                participants: meeting.participants
            });
        }
        catch (err) {  
            console.log(`[getParticipants] Error retrieving the participants for meeting ${this.props.meeting.callGroupId}: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification(); 
        }
    }

    handleMeetingParticipantDelete = (meetingParticipant) => {
        this.deleteMeetingParticipant(meetingParticipant);        
    }

    deleteMeetingParticipant = async (meetingParticipant) => {
        try {
            console.log(`[deleteMeetingParticipant] Invoking the back-end API to delete the meeting participant with Id '${meetingParticipant.participantId}' from meeting with Id '${this.props.meeting.callGroupId}'.`);

            await apiCalls.deleteCallGroupParticipant(this.props.authenticationContext, this.props.meeting.callGroupId, meetingParticipant.participantId);

            this.props.notificationContext.setNotification(`Deleting meeting participant.`, `'${meetingParticipant.teamsUserName}' successfully deleted from meeting '${this.props.meeting.name}'.`, 'success');
                
            this.getParticipants();
        }
        catch (err) {   
            this.props.notificationContext.setNotification(`Deleting meeting participant.`, `Error deleting '${meetingParticipant.teamsUserName}' from meeting '${this.props.meeting.name}'. ${err.toDetailedMessage()}`, 'danger');                   
        }
    }
    
    handleParticpantNameChange = (e) => {
        const trimmedInput = e.target.value.trim();

        if (trimmedInput.length > 0 && !SipHelper.isValidUsername(trimmedInput)) {
            return;
        }

        this.setState({
            newParticipantUsername: trimmedInput
        });
    }

    handleParticpantDomainSelectionChange = (e) => {
        this.setState({
            newParticipantDomainName: e.target.value
        });
    }

    createParticipantAddress = () => {
        return `${this.state.newParticipantUsername}@${this.state.newParticipantDomainName}`;
    }

    handleParticipantAdd = async () => {        
        const teamsUserName = this.createParticipantAddress();

        try {
            console.log(`[handleParticipantAdd] Invoking the back-end API to add a meeting participant with for participant '${teamsUserName}' from meeting with Id '${this.props.meeting.callGroupId}'.`);

            await apiCalls.addCallGroupParticipant(this.props.authenticationContext, this.props.meeting.callGroupId, teamsUserName);

            this.props.notificationContext.setNotification(`Adding meeting participant.`, `'${teamsUserName}' successfully added to meeting '${this.props.meeting.name}'.`, 'success');

            this.getParticipants();
            this.setState({
                newParticipantUsername: ""
            });
        }
        catch (err) {
            if (err.statusCode === 422)
            {
                this.props.notificationContext.setNotification(`Adding meeting participant.`, `User '${teamsUserName}' is not known in the Active Directory of your organization'.`, 'danger');
            }
            else
            {
                this.props.notificationContext.setNotification(`Adding meeting participant.`, `Error adding '${teamsUserName}' to meeting '${this.props.meeting.name}'. ${err.toDetailedMessage()}`, 'danger');
            }
        }
    }

    // The Add participant (meeting participant) should only be enabled when the provided address is a valid SIP address,
    // there is no existing participant with the same name and there are no more than 10 existing participants in the meeting.
    shouldAddButtonBeEnabled = () => {
        return this.getAddButtonTooltip() === null;
    }

    getAddButtonTooltip = () => {
        let participantAddress = this.createParticipantAddress();

        if (!SipHelper.isSipAddressValid(participantAddress)) {
            return "Enter valid username";
        }

        const participantAddressLowerCase = participantAddress.toLowerCase();
        if (this.state.participants.some(participant => participant.teamsUserName.toLowerCase() === participantAddressLowerCase)) {
            return "Username already exists";
        }

        if (this.state.participants.length >= maximumNumberOfParticipants) {
            return "Maximum number of users reached";
        }

        return null;
    }

    copyToClipboard = (valueToCopy) => {
        navigator.clipboard.writeText(valueToCopy);
    }

    render () {
        const renderedMeetingParticipants = this.state.participants.length > 0 ?
            <CallGroupParticipants participants={this.state.participants} handleParticipantDelete={this.handleMeetingParticipantDelete} />:
            <Alert variant='warning' transition={false}>
                This meeting does not yet contain any participants.
            </Alert>

        return (
            <div className={`card bg-light mt-5 ${css.myCardWidth}`}>
                <div className="card-body">
                    <DeleteButton className="float-right" tooltip="Delete meeting" onClick={() => this.props.handleDelete(this.props.meeting)}/>
                    <EditButton className="float-right mr-1" tooltip="Edit" onClick={() => this.props.handleEdit(this.props.meeting)} />
                    <h5 className="card-title">{this.props.meeting.name}
                        <CopyButton className="ml-3" tooltip="Copy SIP address" onClick={() => this.copyToClipboard(this.props.meeting.name)}/>
                    </h5>
                    <div className="mb-1">
                        <p className="mb-1">{this.props.meeting.description}</p>
                    </div>
                    <div className="mb-2">
                        <a href={this.props.meeting.teamsMeetingUrl} target='_blank' rel="noopener noreferrer">[Meeting URL]</a>
                    </div>
                    {renderedMeetingParticipants}
                    <Form className="mt-4" onSubmit={e => e.preventDefault()}>
                        <Form.Group as={Row} controlId="newMeetingParticipant">
                            <Col sm="4">
                                <InputGroup>
                                    <Form.Control id="newMeetingParticipant" value={this.state.newParticipantUsername} autoComplete="off" onChange={this.handleParticpantNameChange} />
                                </InputGroup>
                            </Col>
                            <Col className="" sm="0">
                                <h4>@</h4>
                            </Col>
                            <Col sm="3">
                                <Form.Control as="select" onChange={this.handleParticpantDomainSelectionChange}>
                                    {this.props.verifiedDomains.map((domain) => {
                                        return <option key={domain.name} value={domain.name}>{domain.name}</option>
                                    })}                        
                                </Form.Control>
                            </Col>    
                            <ButtonWithDisabledTooltip color='primary' size='sm' disabled={!this.shouldAddButtonBeEnabled()} disabledTooltip={this.getAddButtonTooltip()} onClick={this.handleParticipantAdd}>Add</ButtonWithDisabledTooltip>
                        </Form.Group>
                    </Form>                
                </div>
            </div>
        )
    }
}

export const Meeting = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
  
    return (
        <MeetingInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}