import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, CustomInput } from 'reactstrap';
import { Form } from 'react-bootstrap';
import * as Misc from '../utils/misc';
import Alert from 'react-bootstrap/Alert';
import { InfoIcon }  from './InfoIcon';

import css from './ModalTenantAddOrUpdate.module.scss';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

const guidRegex = new RegExp("^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$");
const labelRegex = new RegExp("^[0-9a-zA-zÀ-ž\\s\\-_@.!(),]+$");
const emailRegex = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");

export class ModalTenantAddOrUpdate extends Component {
    state = {
        tenantId: "",
        tenantLabel: "",
        tenantEmailAddress: "",
        tenantEnabled: true,
        tenantLicenseCount: 0,
        tenantPreviousLicenseCount: 0,
        tenantEndDateEnabled: false,
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }

    getDefaultLicenseCount = () => {
        if (this.props.isDistributor) {
            if (this.props.distributorLicensesLeft > 100) {
                return 100;
            } else {
                return this.props.distributorLicensesLeft
            }
        }
        return 1;
    }

    setInitialValues = () => {
        const isUpdate = this.props.isUpdate;

        const now = new Date();
        const nowString = now.toISOString().split('T')[0];

        const licenseCount = isUpdate ? this.props.isDistributor ? this.props.tenant.maxQuantity : this.props.tenant.quantityRecursive : this.getDefaultLicenseCount();
        const newEmail = isUpdate && this.props.tenant.emailAddress != null ? this.props.tenant.emailAddress : "";

        this.setState(
            {
                tenantId: isUpdate ? this.props.tenant.tenantId : "",
                tenantLabel: isUpdate ? this.props.tenant.tenantLabel : "",
                tenantEmailAddress: newEmail,
                tenantEnabled: isUpdate ? this.props.tenant.enabled : true,
                tenantEndDate: nowString,
                tenantLicenseCount: licenseCount,
                tenantPreviousLicenseCount: licenseCount,
            }
        );
    }

    handleTenantIdChanged = (e) => {
        this.setState({
            tenantId: String(e.target.value).trim()
        });
    }

    handleTenantLabelChanged = (e) => {
        if (e.target.value.length > 0 && !labelRegex.test(e.target.value)) {
            return;
        }

        this.setState({
            tenantLabel: e.target.value
        });
    }

    handleTenantEmailAddressChanged = (e) => {
        this.setState({
            tenantEmailAddress: e.target.value
        });
    }

    handleTenantEnabledChanged = () => {
        if (this.state.tenantEnabled) {
            this.setState({
                tenantEnabled: false,
                tenantLicenseCount: this.state.tenantPreviousLicenseCount,
            });
        } else {
            this.setState({
                tenantEnabled: true
            });
        }      
    }

    handleTenantEndDateEnabledChanged = () => {
        this.setState({
            tenantEndDateEnabled: !this.state.tenantEndDateEnabled
        });
    }

    handleTenantEndDateChanged = (value) => {
        this.setState({
            tenantEndDate: value.target.value
        });
    }

    handleUnlimitedChanged = () => {
        this.setState({
            tenantLicenseCount: this.state.tenantLicenseCount === Misc.intMaxValue ? this.getDefaultLicenseCount() : Misc.intMaxValue
        });
    }

    handleTenantLicenseCountChanged = (e) => {
        let newLicenseCount = e.target.value;

        if (newLicenseCount < 0) {
            newLicenseCount = 0;
        }

        this.setState({
            tenantLicenseCount: parseInt(newLicenseCount),
        });
    }

    handleAddOrUpdate = () => {
        this.props.onAddOrUpdate(this.props.isUpdate, {
            tenantId: this.state.tenantId,
            tenantLabel: this.state.tenantLabel, 
            emailAddress: this.state.tenantEmailAddress, 
            enabled: this.state.tenantEnabled,
            isDistributor: this.props.isDistributor,
            quantity: parseInt(this.state.tenantLicenseCount),
        }, this.props.isUpdate && this.state.tenantEnabled !== this.props.tenant.enabled);
    }

    isValidTenantId = () => {
        if (this.state.tenantId != null && this.state.tenantId.length > 0 && !guidRegex.test(this.state.tenantId)) {
            return false;
        }
        return true;
    }

    addOrUpdateButtonEnabled = () => {
        return this.getAddOrUpdateButtonTooltip() === null;
    }

    getAddOrUpdateButtonTooltip = () => {
        if (this.state.tenantId == null || this.state.tenantId.length <= 0) {
            return "define tenant ID";
        }

        if (this.state.tenantLabel == null || this.state.tenantLabel.length <= 0) {
            return "Define Tenant label";
        }

        if (this.state.tenantEmailAddress == null || this.state.tenantEmailAddress.length <= 0) {
            if (!this.props.isUpdate || this.props.tenant?.emailAddress) {
                return "Define E-mail address";
            }
        }

        if (this.state.tenantId != null && this.state.tenantId.length > 0 && !guidRegex.test(this.state.tenantId)) {
            return "Tenant ID must be a valid GUID";
        }

        if (this.state.tenantLabel != null && this.state.tenantLabel.length > 0 && !labelRegex.test(this.state.tenantLabel)) {
            return "Tenant label contains unsupported characters"
        }

        if (this.state.tenantEmailAddress != null && this.state.tenantEmailAddress.length > 0 && !emailRegex.test(this.state.tenantEmailAddress)) {
            return "Email address is not valid"
        }

        const minimumLicenseCount = this.props.isDistributor ? 1 : 0;
        if (this.state.tenantLicenseCount < minimumLicenseCount ) {
            return `License count must be at least ${minimumLicenseCount}`;
        }

        if (this.props.isUpdate && this.props.isDistributor && this.state.tenantPreviousLicenseCount > this.state.tenantLicenseCount) {
            return "Maximum number of licenses cannot be decreased";
        }

        if (this.state.tenantLabel.length > 256) {
            return "Label must be less than 256 characters";
        }

        if (this.state.tenantEmailAddress.length > 320) {
            return "Email address must be less than 320 characters";
        }

        if (this.props.isUpdate && 
            this.props.tenant?.tenantLabel === this.state.tenantLabel &&
            this.props.tenant?.emailAddress === this.state.tenantEmailAddress &&
            this.props.tenant?.enabled === this.state.tenantEnabled &&
            this.state.tenantLicenseCount === this.state.tenantPreviousLicenseCount) {
            return "No changes detected";
        }

        return null;        
    }

    render()
    {
        const licensesIsMaxValue = this.state.tenantLicenseCount === Misc.intMaxValue;
        const licenses = Misc.intToString(this.state.tenantLicenseCount, "Unlimited");

        const tenantType = this.props.isDistributor ? this.props.isReseller ? "reseller" : "distributor" : "tenant";
        const addOrUpdateLabel = this.props.isUpdate ? "Update" : "Add"

        const unlimitedSwitch = this.props.isDistributorUnlimited ? 
            <>
                <br/>
                <Form.Check className={css.checkboxNoMargin} inline checked={licensesIsMaxValue} type="checkbox" onClick={this.handleUnlimitedChanged} id="UnlimitedCheckbox"/>
                <label className={css.labelNotBold} inline>Unlimited</label>
                <InfoIcon className="float-right" tooltip="'Unlimited' means this distributor can create as many tenants and distributors as it wants. This means this distributor should be billed by the number of licenses used." />
            </>
            : null;

        const tenantIdInvalidWarning = this.isValidTenantId() ? null : 
            <Alert variant='warning' style={{ opacity: 1 }}>
                Is not a valid GUID.
            </Alert>

        const tenantIdRendered = <>
                <Form.Group controlId="setTenantId">
                    <Form.Label>Customer Microsoft Tenant ID</Form.Label>
                    <Form.Control id="setTenantId" value={this.state.tenantId} disabled={this.props.isUpdate} type="text" placeholder="Tenant ID" autoComplete="off" onChange={this.handleTenantIdChanged} />
                    {tenantIdInvalidWarning}
                    <Form.Text className="text-muted">
                        This ID (GUID) must match exactly with the tenant ID of the customer that is registered with Microsoft.<br/>
                        The tenant ID can be found using the instructions of one of the following links:
                        <ul>
                            <li><a href="https://www.whatismytenantid.com" target='_blank' rel="noopener noreferrer">Using the domain registered at Microsoft</a></li>
                            <li><a href="https://docs.microsoft.com/en-us/onedrive/find-your-office-365-tenant-id" target='_blank' rel="noopener noreferrer">Using Azure login</a></li>
                            <li><a href="https://teams.handsontek.net/2019/04/09/how-to-get-microsoft-teams-tenant-id" target='_blank' rel="noopener noreferrer">Using Teams</a></li>
                        </ul>
                    </Form.Text>
                </Form.Group>
            </>

        const tenantLabelRendered = <>
                <Form.Group controlId="setTenantLabel">
                    <Form.Label>Name</Form.Label>
                    <Form.Control id="setTenantLabel" value={this.state.tenantLabel} type="text" placeholder="Customer Name" autoComplete="off" onChange={this.handleTenantLabelChanged} />
                    <Form.Text className="text-muted">
                        This name will be used for displaying and identification of the customer
                    </Form.Text>
                </Form.Group>
            </>

        const tenantEmailAddressRendered = <>
                <Form.Group controlId="setTenantEmailAddress">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control id="setTenantEmailAddress" value={this.state.tenantEmailAddress} type="text" placeholder="Customer contact E-mail address" autoComplete="off" onChange={this.handleTenantEmailAddressChanged} />
                    <Form.Text className="text-muted">
                        This address will be used as contact information for when changes to the CyberGate service need to be communicated
                    </Form.Text>
                </Form.Group>
            </>

        const tenantEnabledRendered = <>
                <Form.Group controlId="setTenantEnabled">
                    <Form.Label>Enabled</Form.Label>
                    <CustomInput checked={this.state.tenantEnabled} type="switch" onClick={this.handleTenantEnabledChanged} id="tenantEnabledSwitch"  />
                    <Form.Text className="text-muted">
                        This is used to enable or disable a tenant
                    </Form.Text>
                </Form.Group>
            </>

        const distributorLicenseCountRendered = <>
                <Form.Group controlId="setDistributorMaxLicenseCount">
                    <Form.Label>Maximum number of licenses</Form.Label>
                    {unlimitedSwitch}
                    <Form.Control id="setDistributorMaxLicenseCount" className="mt-2" type={licensesIsMaxValue ? "text": "number"} disabled={licensesIsMaxValue || !this.state.tenantEnabled} value={licenses} onChange={this.handleTenantLicenseCountChanged}/>
                    <Form.Text className="text-muted">
                        The maximum number of licenses that can be assigned to the tenants created by this {tenantType}.
                    </Form.Text>
                </Form.Group>
            </>

        const tenantLicenseCountRendered = <>
                <Form.Group controlId="setTenantLicenseCount">
                    <Form.Label>Number of device licenses</Form.Label>
                    <Form.Control id="setTenantLicenseCount" type="number" disabled={licensesIsMaxValue || !this.state.tenantEnabled} value={licenses} onChange={this.handleTenantLicenseCountChanged}/>
                    <Form.Text className="text-muted">
                        The number of devices this tenant is allowed to configure.
                    </Form.Text>
                </Form.Group>
            </>

        return <>
            <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${addOrUpdateLabel} ${tenantType}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                        {tenantIdRendered}
                        {tenantLabelRendered}
                        {tenantEmailAddressRendered}
                        {this.props.isUpdate ? tenantEnabledRendered : null}
                        {/* {tenantEndDateRendered} */}
                        {this.props.isDistributor ? distributorLicenseCountRendered : null}
                        {this.props.isDistributor ? null : tenantLicenseCountRendered}
                   </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <ButtonWithDisabledTooltip color="primary" disabled={!this.addOrUpdateButtonEnabled()} disabledTooltip={this.getAddOrUpdateButtonTooltip()} onClick={this.handleAddOrUpdate}>
                        {addOrUpdateLabel}
                    </ButtonWithDisabledTooltip>
                </Modal.Footer>
            </Modal>
        </>;
    }
}