import { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { WarningIconWithTooltip } from './WarningIconWithTooltip';

import css from './DeviceEntry.module.scss';

export class DeviceRecordedColumn extends Component {
    render() {  
        const spinnerStyle = {
            width: '40px',
            height: '40px',
            padding: '8px',
            display: 'inline-block'
        }

        // A placeholder with the same size as the spinner is rendered when nothing has to be shown to 
        // prevent changes in the table layout which looks very annoying.
        const placeholder = <div style={spinnerStyle} className='float-right'/>;
        const featureIsNotConfigured = (this.props.deviceStatus === 'notAssigned');

        const showSpinner = this.props.deviceStatus === 'undetermined';
        const showWarning = (this.props.tenantRecordingEnabled && this.props.deviceRecorded && featureIsNotConfigured);        
    
        let isFeatureWorking = "";
        let warningIcon = placeholder;

        if (showSpinner)
        {
            warningIcon = <div style={spinnerStyle} className='float-right'><Spinner animation='border' role='status' size='sm' variant='secondary'/></div>;
        }
        else if (!this.props.tenantRecordingEnabled)
        {
            isFeatureWorking = "no";
        }
        else if (showWarning)
        {
            isFeatureWorking = "no";

            warningIcon = <WarningIconWithTooltip tooltip="The device can't be recorded yet. Please execute the feature configuration script first. See the 'Download' button at the top of the page." className={this.props.className} />       
        }
        else
        {
            isFeatureWorking = this.props.deviceRecorded ? "yes" : "no";
        }

        return (
            <div className={css.flexContainer}>
                <div>
                    {isFeatureWorking}
                </div>
                <div className={css.flexItemRight}>
                    {warningIcon}
                </div>
            </div>
        )
    }
}