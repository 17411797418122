import qs from 'qs';

const landingpagePath = 'landingpage';
const isLoginFromMarketplaceKey = 'isLoginFromMarketplace';
const marketPlaceTokenKey = 'marketPlaceToken';

// Method returns true in the following cases:
// - The current page is the landingpage
// - The current page is not the landingpage, but the current ongoing login was triggered from the landingspage.
function isMarketPlaceLogin() {
    if (isOnLandingpage()) {
        return true;
    }

    if (sessionStorage.getItem(isLoginFromMarketplaceKey) === 'true') {
        return true;
    }
}

// Method persists in the session storage that a login is started from the landingpage.
// The MarketPlace token is retrieved from the URL. In case no token is found an error is thrown.
function setMarketPlaceLogin() {
    const token = getMarketPlaceTokenFromUrl();
    
    sessionStorage.setItem(isLoginFromMarketplaceKey, 'true');
    sessionStorage.setItem(marketPlaceTokenKey, token);
}

// Method clears the persisted information from the session storage that a login is started from the landingpage.
function clearMarketPlaceLogin() {
    sessionStorage.removeItem(isLoginFromMarketplaceKey);
    sessionStorage.removeItem(marketPlaceTokenKey);
}

// Method gets the MarketPlace token from either the URL or from the session storage.
function getMarketplaceToken() {
    if (isOnLandingpage()) {
        const tokenFromUrl = tryGetMarketPlaceTokenFromUrl();
        if (tokenFromUrl) {
            return tokenFromUrl;
        }
    }

    return sessionStorage.getItem(marketPlaceTokenKey);
}

// Method return True in case the current page is the landingpage.
function isOnLandingpage() {
    return window.location.pathname.toLowerCase().endsWith(landingpagePath);
}

// Method attempts to get the MarketPlace token from the URL. 
// In case the token is not found, the value null is returned.
function tryGetMarketPlaceTokenFromUrl() {
    const queryParameters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (!queryParameters.token) {
        return null;
    }

    return decodeURIComponent(queryParameters.token);
}

// Method gets the MarketPlace token from the URL. An error is thrown when the token is not found.
function getMarketPlaceTokenFromUrl() {
    const token = tryGetMarketPlaceTokenFromUrl();

    if (!token) {
        throw new Error("No MarketPlace token found in URL.");
    }

    return token;
}

export const marketPlaceHelper = {
    isMarketPlaceLogin: isMarketPlaceLogin,
    getMarketplaceToken: getMarketplaceToken,
    getMarketPlaceTokenFromUrl: getMarketPlaceTokenFromUrl,
    setMarketPlaceLogin: setMarketPlaceLogin,
    clearMarketPlaceLogin: clearMarketPlaceLogin
}