import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';

export class ModalTenantDisableConfirm extends Component {
    handleDisableTenant = () => {
        this.props.onDisableConfirm(this.props.tenant);
    }

    render()
    {
        const tenantType = this.props.isDistributor ? "distributor" : "tenant";
        const disableWarning = this.props.isDistributor ? 
            <>
                <p>
                    {`Are you sure you want to disable distributor '${this.props.tenant?.tenantLabel}'?`}<br/>
                    This will disable all devices of this distributor and of all it's customers.
                </p>
                <strong>
                    {`So '${this.props.tenant?.tenantLabel}' and all it's customers can't use the CyberGate until this distributor is re-enabled!`}
                </strong>
            </> :
            <>
                <p>
                    {`Are you sure you want to disable tenant '${this.props.tenant?.tenantLabel}'?`}<br/>
                    This will disable all devices of this tenant.
                </p>
                <strong>
                    {`So '${this.props.tenant?.tenantLabel}' can't use the CyberGate until it is re-enabled.`}
                </strong>
            </>

        return <>
            <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{`Confirm disable of ${tenantType}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {disableWarning}
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleDisableTenant}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}