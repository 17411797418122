import { Component, useContext } from 'react';
import { Button } from 'reactstrap';

import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import * as apiCalls from '../logic/apiCalls';
import { triggerDownloadBlob } from '../utils/download';

class TenantCommunicationTestScriptDownloadInternal extends Component {
    downloadCommunicationTestScript = async () => {
        try {
            console.log("[downloadCommunicationTestScript] Invoking the back-end API to get the communication test script.");

            const scriptBlob = await apiCalls.getCommunicationTestScript(this.props.authenticationContext);

            triggerDownloadBlob(scriptBlob, 'CommunicationTestScript.ps1');
        }
        catch (err) {
            console.log(`[downloadCommunicationTestScript] Error downloading the communication test script: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification(); 
        }
    }

    render() {
        return (<><div>
            <h4>Communication Test Script</h4>
            <br/>
            <p className="my-2">The Communication Test Script helps with troubleshooting possible connection problems between your local network and the online CyberGate service.
            This easy-to-run PowerShell script detects any connections that might be blocked by a firewall, NAT router or Sip gateway. This script can be downloaded with the button below.</p>
            <Button color="primary" onClick={this.downloadCommunicationTestScript}>Download</Button>
            <br/>
            <br/>
        </div></>);
    }
}

export const TenantCommunicationTestScriptDownload = (props) => {
    const authenticationContext = useContext(AuthenticationContext);   
    const notificationContext = useContext(NotificationContext);
  
    return (
        <TenantCommunicationTestScriptDownloadInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}