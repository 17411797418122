import { createContext, useState } from 'react';
import { Container, Jumbotron, Button } from 'reactstrap';

export const ErrorHandlingContext = createContext();

export const ErrorHandlingProvider = (props) => {
    
    const [errorMessage, setErrorMessage] = useState("");
    const [errorDetailMessage, setErrorDetailMessage] = useState("");

    // The contextValue is the API object that is provided via the ErrorHandlingContext.
    const contextValue = {
        setError: (errorMessage, error) => {
            const detailedMessage = convertToDetailedMessage(error);
            console.log(`[ErrorHandlingContext] ${errorMessage}: ${detailedMessage}`);
            setErrorMessage(errorMessage);
            setErrorDetailMessage(detailedMessage);
        } ,        
        clearErrors: () => setErrorMessage("")
    }
    
    const convertToDetailedMessage = (error) => {
        // ToDo: Provide less detail in production mode?
        if (error.toDetailedMessage) {
            return error.toDetailedMessage();
        }
        
        return error.toString();
    }

    // Method attempts to reset the application by clearing the session storage and performing 
    // a full page refresh which forces to reload everything from the server.
    const refreshPage = () => {
        sessionStorage.clear();
        window.location.reload(true)
    }

    let contents;
        
    if (errorMessage === "") {
        contents = <div>{props.children}</div>;           
    } else {
            const detailedMessage = errorDetailMessage != null && errorDetailMessage !== "" ?
            <>
                <small>Detailed message:</small>
                <pre>{errorDetailMessage}</pre>
            </> : null;

            contents = (<Container> 
                <Jumbotron>
                    <h2><strong>Oops, something went wrong....</strong></h2>                
                    <h5 className="mt-3">{errorMessage}</h5>
                    {detailedMessage}
                    <p>Click the button below to refresh the page and try again.</p>
                    <Button color="primary" onClick={() => refreshPage()}>Refresh</Button>                         
                </Jumbotron>
            </Container>);
    }

    return (
        <ErrorHandlingContext.Provider value={contextValue}>
            {contents}
        </ErrorHandlingContext.Provider>
    );
}