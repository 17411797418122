import React, { Component } from 'react';
import css from './SideMenuHeader.module.scss';

export class SideMenuHeader extends Component {
    render() {
        return (
            <div className={css.sideMenuHeader}>
                {this.props.text}
            </div>
        );
    }
}
