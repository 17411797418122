import { Component } from 'react';
import { TenantEntry } from './TenantEntry'; 
import * as Utils from '../utils/helper';
import * as Misc from '../utils/misc';

export class TenantEntries extends Component {
    render() {
        const tenants = this.props.tenants === null ? [] : this.props.tenants;
        const onEdit = this.props.onEdit;
        const editEnabled = this.props.editEnabled;

        let enabledTotal = 0;
        let licensesReservedTotal = 0;
        let licensesInUseTotal = 0;

        for (var tenant of tenants) {
            if (Utils.isTrue(tenant.enabled)) {
                enabledTotal++;

                if (tenant.maxQuantity === Misc.intMaxValue || licensesReservedTotal === Misc.intMaxValue) {
                    licensesReservedTotal = Misc.intMaxValue
                } else {
                    licensesReservedTotal += tenant.maxQuantity;
                }
    
                if (tenant.quantityRecursive === Misc.intMaxValue || licensesInUseTotal === Misc.intMaxValue) {
                    licensesInUseTotal = Misc.intMaxValue
                } else {
                    licensesInUseTotal += tenant.quantityRecursive;
                }
            }
        }

        return (
            <div className='overflow-auto'>
                <table className="table table-striped table-bordered my-2">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" >Name</th>
                            <th scope="col" >E-mail</th>
                            <th scope="col" className="text-center" width="100px">Created</th>
                            <th scope="col" className="text-center" width="100px">Enabled</th>
                            <th scope="col" className="text-center" width="100px">Licenses</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tenants.map((value) => {
                            return <TenantEntry key={value.tenantId} tenant={value} onEdit={onEdit} editEnabled={editEnabled}/>
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="table-secondary">
                            <th scope="col" ></th>
                            <th scope="col" ></th>
                            <th scope="col" className="text-right" >Total:</th>
                            <th scope="col" className="text-right">{enabledTotal}</th>
                            <th scope="col" className="text-right">{Misc.intToString(licensesInUseTotal)}</th>
                        </tr>
                    </tfoot>
                </table>           
            </div>
        );
    }
}