import { useContext, Component } from 'react';
import { Container } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { NotificationContext } from '../../context/NotificationContext';
import { CallGroupsWithSupervisors } from '../../components/CallGroupsWithSupervisors';
import { TeamsAppBanner } from '../../components/TeamsAppBanner'
import { Alert } from 'react-bootstrap';

import * as apiCalls from '../../logic/apiCalls';

const fallbackDomain = {
    "capabilities": "OfficeCommunicationsOnline",
    "isDefault": true,
    "isInitial": false,
    "name": "cybergate.cybertwice.com",
    "type": "Managed"
};

class AvailabilitySupervisorSettingsInternal extends Component {
    state = {
        isLoadingCallGroups: true,      
        isLoadingDomains: true,
        callGroups: [],
        verifiedDomains: [fallbackDomain],
    }

    async componentDidMount() {
        this.getCallGroups();  
        this.getOrganizationDomains();
    }
    
    getCallGroups = async () => {
        try {
            console.log("[getCallGroups] Invoking the back-end API to get all call groups of this tenant.");

            const callGroups = await apiCalls.getCallGroups(this.props.authenticationContext);

            console.log(`[getCallGroups] Successfully retrieved the call groups: ${callGroups.length}`)
            this.setState({
                callGroups: callGroups
            });
        }
        catch (err) {
            console.log(`[getCallGroups] Error retrieving the call groups: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();               
        }
        finally {
            this.setState({
                isLoadingCallGroups: false
            });
        }
    }

    getOrganizationDomains = async () => {
        try {
            console.log("[getOrganizationDomains] Invoking the Graph API to get the organization domains of this tenant.");

            const receivedVerifiedDomains = await apiCalls.getAvailableDomains(this.props.authenticationContext);

            console.log(`[getOrganizationDomains] Successfully retrieved the verified domains: ${JSON.stringify(receivedVerifiedDomains)}`)

            // Find the default domain and put this domain at the top.
            let defaultDomainIndex = receivedVerifiedDomains.findIndex(domain => domain.isDefault === true);
            if (defaultDomainIndex >= 0) {
                let defaultdomain = receivedVerifiedDomains.splice(defaultDomainIndex, 1)[0];
                receivedVerifiedDomains.unshift(defaultdomain);
            }

            this.setState({
                verifiedDomains: receivedVerifiedDomains,
            });
        }
        catch (err) {
            // The global error is not set here, because failure of retrieving the organization names should not break the interface.
            console.log(`[getOrganizationDomains] Error while retrieving organization verified domains for tenant: ${JSON.stringify(err)}`);
        }
        finally {
            this.setState({
                isLoadingDomains: false,
            });
        }
    }

    handleAddSupervisor = async (callGroup, teamsUserName) => {
        try {
            await apiCalls.addCallGroupSupervisor(this.props.authenticationContext, callGroup.callGroupId, teamsUserName);

            this.props.notificationContext.setNotification(`Adding supervisor to call group.`, `Supervisor '${teamsUserName}' was added successfully to call group '${callGroup.name}'.`, 'success');
        }
        catch (err) {
             this.props.notificationContext.setNotification("Adding supervisor to call group.", `Failed to add supervisor '${teamsUserName}' to call group '${callGroup.name}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    handleDeleteSupervisor = async (callGroup, supervisor) => {
        try {
            var supervisorName = supervisor.teamsUserName ? supervisor.teamsUserName : supervisor.teamsUserId;

            await apiCalls.deleteCallGroupSupervisor(this.props.authenticationContext, callGroup.callGroupId, supervisor.id);

            this.props.notificationContext.setNotification(`Removing supervisor from call group.`, `Supervisor '${supervisorName}' was removed successfully from call group '${callGroup.name}'.`, 'success');
        }
        catch (err) {
            this.props.notificationContext.setNotification("Removing supervisor from call group.", `Failed to remove supervisor '${supervisorName}' from call group '${callGroup.name}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    handleUpdateSupervisors = async (callGroup, updatedSupervisors) => {

        for (const updatedSupervisor of updatedSupervisors) {
            if (!callGroup.supervisors.find(sup => sup.teamsUserName?.toLowerCase() === updatedSupervisor.teamsUserName?.toLowerCase())) {
                console.log(`[handleUpdate] addeding supervisor: ${updatedSupervisor.teamsUserName}.`);
                await this.handleAddSupervisor(callGroup, updatedSupervisor.teamsUserName)
            }
        }

        for (const currentSupervisor of callGroup.supervisors) {
            if (!updatedSupervisors.find(sup => sup.id === currentSupervisor.id)) {
                var supervisorName = currentSupervisor.teamsUserName ? currentSupervisor.teamsUserName : currentSupervisor.teamsUserId;
                console.log(`[handleUpdate] removed supervisor: ${supervisorName}.`);
                await this.handleDeleteSupervisor(callGroup, currentSupervisor)
            }
        }

        this.getCallGroups();  
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>Availability supervisor settings</h1> 

        if (this.state.isLoadingCallGroups || this.state.isLoadingDomains) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const callGroupsRendered = this.state.callGroups.length > 0 ? 
            <CallGroupsWithSupervisors
                callGroups={this.state.callGroups}                
                verifiedDomains={this.state.verifiedDomains}
                onUpdateSupervisors={this.handleUpdateSupervisors}/> : 
            <Alert className="mt-3" variant='warning' transition={false}>
                No call groups configured. Call groups can be added in either the 'Multi-ring' or 'Meeting' settings page.
            </Alert>;

        return (
            <>
                <Container fluid>
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <p>Assign supervisors to configured multi-ring groups and meetings.</p>
                    <p>A supervisor can set the availability of every member of the group in the 'Availability' tab of the CyberGate for Microsoft Teams app.</p>
                    <TeamsAppBanner/>
                    <hr/>
                    {callGroupsRendered}                  
                    <br/>
                    <br/>
                </Container>
            </>
        );
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const AvailabilitySupervisorSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <AvailabilitySupervisorSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}