import { PureComponent } from 'react';
import { Spinner } from 'react-bootstrap';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { getColor } from '../logic/chartLogic';

export class ChartPie extends PureComponent {
    // The ResponsiveContainer of the Recharts library has an resizing issue when used inside a flex container. 
    // The work-around described here is applied:
    // https://github.com/recharts/recharts/issues/172#issuecomment-523362671
    render() {
        const loadingOverlayRendered = (<div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spinner animation="border" variant="secondary" />
        </div>)

        const noDataOverlayRendered = (<div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <h6>No data available</h6>
        </div>)

        const renderLabel = function(entry) {
            return entry.name;
        }

        return (
            <div style={{ position: 'relative', width: '100%', paddingBottom: '500px' }}>
                {this.props.isLoading ? loadingOverlayRendered : (
                    this.props.data.length === 0 ? noDataOverlayRendered :
                    <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, }}>
                        <ResponsiveContainer>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={this.props.data}
                                    cx="45%"
                                    cy="50%"
                                    labelLine={true}
                                    label={renderLabel}
                                    outerRadius="70%"
                                    fill="#8884d8"
                                    dataKey={this.props.valueName}
                                    nameKey={this.props.nameName}
                                >
                                    {this.props.data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={getColor(index, this.props.data.length)} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                        {this.props.total > 0 ? 
                            <div class="text-center">
                                <h5>Total: {this.props.total}</h5>
                            </div> : null
                        }
                    </div>
                ) }
            </div>
        )
    }
}