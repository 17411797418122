import { Route, Switch } from 'react-router';
import { AuditTrail } from './pages/portal/AuditTrail';
import { MarketplaceReport } from './pages/portal/MarketplaceReport';
import { DistributorReport } from './pages/portal/DistributorReport';
import { TenantReport } from './pages/portal/TenantReport';
import { Portal } from './pages/portal/Portal';
import { Home } from './pages/Home';
import { PostLogin } from './pages/auth/PostLogin';
import { NotFound } from './pages/NotFound';
import { ProtectedRoute} from './components/ProtectedRoute';
import { AuthenticationProvider } from './msal/AuthenticationContext';
import { TenantProvider } from './context/TenantContext';
import { ErrorHandlingProvider } from './context/ErrorHandlingContext';
import { NotificationProvider } from './context/NotificationContext';
import { Unauthorized } from './pages/auth/Unauthorized';
import { LandingPage } from './pages/LandingPage';
import { PostConsent } from './pages/auth/PostConsent';

import './utils/arrayExtensions';

import './App.module.scss';
import './custom.css'

const RootApp = () => {
    return (
        <ErrorHandlingProvider>
            <NotificationProvider>
                <AuthenticationProvider>
                    <TenantProvider>
                        <Switch>
                            <Route exact path='/' component={Home} />                    
                            <Route exact path='/landingpage' component={LandingPage} />                    
                            <Route exact path='/post-consent-spa' component={PostConsent} />
                            <Route exact path='/post-login-spa' component={PostLogin} />                        
                            <Route exact path='/unauthorized' component={Unauthorized} />

                            <ProtectedRoute exact path='/portal/audit-trail' component={AuditTrail} />                       
                            <ProtectedRoute exact path='/portal/marketplace-report' component={MarketplaceReport} />                        
                            <ProtectedRoute exact path='/portal/distributor-report' component={DistributorReport} />                        
                            <ProtectedRoute exact path='/portal/tenant-report' component={TenantReport} />                       

                            <ProtectedRoute path='/portal' component={Portal} />                        
                            <Route path='*' component={NotFound} />
                        </Switch>
                    </TenantProvider>
                </AuthenticationProvider>
            </NotificationProvider>
        </ErrorHandlingProvider>
    );    
}

export const App = RootApp;
