import { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from '../../components/Layout';
import { DistributorSettings } from './DistributorSettings';
import { GlobalSettings } from './GlobalSettings';
import { NetworkSettings } from './Network/NetworkSettings';
import { TrunkSettings } from './TrunkSettings';
import { DeviceSettings } from './DeviceSettings';
import { LicenseOverview } from './LicenseOverview';
import { MultiringSettings } from './MultiringSettings';
import { MeetingSettings } from './MeetingSettings';
import { PortalAccessSettings } from './PortalAcces/PortalAccessSettings';
import { DeviceAccessSettings } from './DeviceAccessSettings';
import { AvailabilitySupervisorSettings } from './AvailabilitySupervisorSettings';
import { NotFound } from '../NotFound';
import { urls } from '../../logic/urls';

export class Portal extends Component {
    static displayName = Portal.name;
      
    render() {
        return (
            <Layout {...this.props} >
                <Switch>
                    <Route exact path={urls.license} component={LicenseOverview} />
                    <Route exact path={urls.distributor} component={DistributorSettings} />         
                    <Route exact path={urls.reseller} component={DistributorSettings} />         
                    <Route exact path={urls.tenant} component={GlobalSettings} />                    
                    <Route exact path={urls.network} component={NetworkSettings} />                    
                    <Route exact path={urls.portalAccess} component={PortalAccessSettings} />                    
                    <Route exact path={urls.trunk} component={TrunkSettings} />                    
                    <Route exact path={urls.device} component={DeviceSettings} />
                    <Route exact path={urls.multiring} component={MultiringSettings} />                    
                    <Route exact path={urls.meeting} component={MeetingSettings} />
                    <Route exact path={urls.deviceAccess} component={DeviceAccessSettings} />                    
                    <Route exact path={urls.multiringAccess} component={AvailabilitySupervisorSettings} />                    

                    <Route path='*' component={NotFound}/>
                </Switch>
            </Layout>
        );
    }
}
