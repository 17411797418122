import { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EditButton } from './EditButton';
import { CallGroupSupervisors } from './CallGroupSupervisors';
import { ModalEditCallGroupSupervisors } from './ModalEditCallGroupSupervisors';

export class CallGroupWithSupervisors extends Component {
    state = {
        isEditCallGroupSupervisorsModalOpen: false,
    }

    showEditCallGroupSupervisorsModal = () => {
        this.setState({
            isEditCallGroupSupervisorsModalOpen: true
        }); 
    }

    hideEditCallGroupSupervisorsModal = () => {
        this.setState({
            isEditCallGroupSupervisorsModalOpen: false,
        }); 
    }

    handleUpdate = (callGroup, updatedSupervisors) => {
        this.props.onUpdateSupervisors(callGroup, updatedSupervisors)
        this.hideEditCallGroupSupervisorsModal();
    }

    render () {
        const renderedCallGroupSupervisors = this.props.callGroup.supervisors.length > 0 ?
            <CallGroupSupervisors supervisors={this.props.callGroup.supervisors} />:
            null

        const groupTypeLower = this.props.callGroup.callGroupType.toLowerCase();
        const groupTypeLabel = groupTypeLower === 'multiring' ? 'Multi-ring' : groupTypeLower === 'meeting' ? 'Meeting' : 'Unknown';

        return (
            <>
                <ModalEditCallGroupSupervisors 
                    callGroup={this.props.callGroup}
                    verifiedDomains={this.props.verifiedDomains}
                    show={this.state.isEditCallGroupSupervisorsModalOpen}
                    onHide={this.hideEditCallGroupSupervisorsModal}
                    onUpdate={this.handleUpdate} />

                <tr>
                    <td>
                        {this.props.callGroup.description}
                    </td>
                    <td>
                        {groupTypeLabel}
                    </td>
                    <td>
                        {this.props.callGroup.name}
                    </td>                    
                    <td>
                        <Container fluid>
                            <Row>
                                <Col xs={9}>
                                    {renderedCallGroupSupervisors}
                                </Col>
                                <Col>
                                    <EditButton className="float-right" tooltip="Modify supervisor(s)" onClick={this.showEditCallGroupSupervisorsModal} placement='left'/>
                                </Col>
                            </Row>
                        </Container>           
                    </td>
                </tr>
            </>
        );
    }
}