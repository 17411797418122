import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, CustomInput } from 'reactstrap';
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import { InfoIcon }  from './InfoIcon';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

const deviceTypesAndNames = [
    { deviceType: "intercom", displayName: "Intercom" },
    { deviceType: "pager", displayName: "Pager" },
    { deviceType: "camera", displayName: "Camera" },
]

const defaultDeviceType = deviceTypesAndNames[0].deviceType;

const twoWayVideoCompatibilityList = [
    {
        brand: "Commend",
        types: [
            "ID5",
            "OD5",
            "OD10",
        ]
    },
    {
        brand: "IpDoor",
        types: [
            "Touch",
            "Flush"
        ]
    },
    {
        brand: "Akuvox",
        types: [
            "R29S"
        ]
    },
    {
        brand: "2N",
        types: [
            "IP Style",
        ]
    },
];

class ModalDeviceAddOrUpdateInternal extends Component {
    state = {
        deviceId: "",
        displayName: "",
        sipUsername: "",
        type: defaultDeviceType,
        location: "",
        recordingEnabled: false,
        recorded: false,
        twoWayVideo: false,
        incomingCalls: false,
        doorOpenDtmf: "",
    }

    constructor(props) {
        super(props);

        // Build the description validation Regex.
        let nonPrintableCharStart = String.fromCharCode(0x00); // NULL char
        let nonPrintableCharEnd = String.fromCharCode(0x1F); // S7 char
        let delRegex = String.fromCharCode(0xF); // DEL char
        let backslashRegex = "\\\\"; // The parsing of the literal string will reduce this to 2 backslashes, the parsing of this string by the RegExp class will reduce it to a single literal backslash to be matched.
        let forwardslashRegex = "\\/"; 
        let bracketOpeningRegex = "\\["; 
        let bracketClosingRegex = "\\]"; 
        let quotationRegex = "\\\""; 
        let additionalCharsRegex = "!@#$%^&*()={};:";
        
        this.displayNameValidationRegex = new RegExp(`^[^${nonPrintableCharStart}-${nonPrintableCharEnd}${delRegex}${backslashRegex}${forwardslashRegex}${bracketOpeningRegex}${bracketClosingRegex}${quotationRegex}${additionalCharsRegex}]*$`);
        this.sipUsernameValidationRegex = new RegExp(`^[^${nonPrintableCharStart}-${nonPrintableCharEnd}${delRegex}]*$`);// Not Null (ASCII dec 0) to Unit-separator (ASCII dec 31) and not DEL (ASCII dec 127)
        this.dtmfValidationRegex = new RegExp(`^[0-9a-dA-D#*]*$`);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    determineDeviceType = (deviceType) => {
        if (!deviceType || deviceType.length === 0) {
            // Error situation. Device type should not be null or empty.
            return defaultDeviceType;
        }

        var matchingType = deviceTypesAndNames.filter(item => item.deviceType === deviceType);
        if (matchingType.length === 1) {
            return matchingType[0].deviceType;
        }

        // No matching type found.
        return defaultDeviceType;
    }

    setInitialValues = () => {
        const isUpdate = this.props.isUpdate;

        this.setState(
            {
                deviceId: isUpdate ? this.props.deviceToUpdate.deviceId : "",
                displayName: isUpdate ? this.props.deviceToUpdate.deviceDescription : "",
                sipUsername: isUpdate && this.props.deviceToUpdate.sipUsername ? this.props.deviceToUpdate.sipUsername : "",
                type: isUpdate ? this.determineDeviceType(this.props.deviceToUpdate.type) : defaultDeviceType,
                location: isUpdate && this.props.deviceToUpdate.location ? this.props.deviceToUpdate.location : "",
                recorded: isUpdate ? this.props.deviceToUpdate.recorded : this.props.tenant.callRecordingEnabled,
                twoWayVideo: isUpdate ? this.props.deviceToUpdate.twoWayVideo : false,
                incomingCalls: isUpdate ? this.props.deviceToUpdate.incomingCalls : true,
                doorOpenDtmf: isUpdate && this.props.deviceToUpdate.doorOpenDtmf ? this.props.deviceToUpdate.doorOpenDtmf : "",
            }
        );
    }

    handleDoneButtonClicked = () => {
        const trimmedDisplayName = this.state.displayName.trim();
        const trimmedLocation = this.state.location?.trim() ?? '';

        const doneInfo = {
            deviceId: this.state.deviceId,
            displayName: trimmedDisplayName,
            sipUsername: this.state.sipUsername,
            type: this.state.type,
            location: trimmedLocation,
            recorded: this.state.recorded,
            twoWayVideo: this.state.twoWayVideo,
            incomingCalls: this.state.incomingCalls,
            doorOpenDtmf: this.state.doorOpenDtmf,
            isUpdate: this.props.isUpdate
        }

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.props.onHide();
    }

    handleDisplayNameChanged = (e) => {
        const updatedDisplayName = e.target.value;

        if (updatedDisplayName.length > 0 && !this.displayNameValidationRegex.test(updatedDisplayName)) {
            return;
        }
                
        this.setState({
            displayName: updatedDisplayName
        });
    }

    handleTypeChanged = (e) => {
        this.setState({
            type: e.target.value
        });
    }

    handleLocationChanged = (e) => {
        const updatedLocation = e.target.value;

        if (updatedLocation.length > 0 && !this.displayNameValidationRegex.test(updatedLocation)) {
            return;
        }
                
        this.setState({
            location: updatedLocation
        });
    }

    handleDoorOpenDtmfChanged = (e) => {
        const updatedDoorOpenDtmf = e.target.value;

        if (updatedDoorOpenDtmf.length > 0 && !this.dtmfValidationRegex.test(updatedDoorOpenDtmf)) {
            return;
        }
                
        this.setState({
            doorOpenDtmf: updatedDoorOpenDtmf
        });
    }

    handleSipUsernameChanged = (e) => {
        const updatedSipUsername = e.target.value;

        if (!this.sipUsernameValidationRegex.test(updatedSipUsername)) {
            return;
        }
                
        this.setState({
            sipUsername: updatedSipUsername
        });
    }

    toggleRecordDevice = () => {
        this.setState({
            recorded: !this.state.recorded
        });
    }

    toggleTwowayvideo = () => {
        this.setState({
            twoWayVideo: !this.state.twoWayVideo
        });
    }

    toggleIncomingCalls = () => {
        this.setState({
            incomingCalls: !this.state.incomingCalls
        });
    }

    getTwoWayVideoCompatibilityList =() => {

        const tooltip = <div className="text-left">
            <p>Devices that are supported by the CyberGate for 2-way video:</p>
            <ul>
                {twoWayVideoCompatibilityList.map((compat) => {
                    return (<li key={compat.brand}>
                                {compat.brand}
                                <ul>
                                    {compat.types.map((type) => {
                                        return <li key={type}>{type}</li>
                                    })}
                                </ul>
                            </li>);
                    })
                }
            </ul>
        </div>;

        return <InfoIcon className="float-right" tooltip={tooltip} />;
    }

    isSipUsernameNotUnique = () => {
        if (!this.props.sipUsernames) {
            return false
        }

        return this.props.sipUsernames.includes(this.state.sipUsername);
    }

    isSipUsernameCleared = () => {
        if (!this.props.isUpdate || !this.props.deviceToUpdate) {
            return false;
        }

        return !this.state.sipUsername && this.props.deviceToUpdate.sipUsername;
    }

    shouldAddOrUpdateButtonBeEnabled = () => {
        return this.getAddOrUpdateButtonTooltip() === null;
    }

    getAddOrUpdateButtonTooltip = () => {
        if (!this.props.isOpen) {
            return null;
        }

        if (this.isSipUsernameNotUnique()) {
            return "SIP username is not unique";
        }

        if (this.isSipUsernameCleared()) {
            return "SIP username cannot be cleared";
        }

        if (this.state.sipUsername === '' && this.props.isTrunked) {
            return "SIP username may not be empty";
        }

        if (this.state.displayName === '') {
            return "Display name may not be empty";
        }

        if (this.state.displayName.length > 256) {
            return "Display name must be less than 256 characters";
        }

        if (this.state.location.length > 256) {
            return "Location must be less than 256 characters";
        }
            
        const deviceSipUsername = this.props.deviceToUpdate.sipUsername ? this.props.deviceToUpdate.sipUsername : "";
        const deviceDoorOpenDtmf = this.props.deviceToUpdate.doorOpenDtmf ? this.props.deviceToUpdate.doorOpenDtmf : "";

        if (this.state.displayName !== this.props.deviceToUpdate.deviceDescription || 
            this.state.type !== this.props.deviceToUpdate.type || 
            this.state.location !== this.props.deviceToUpdate.location || 
            this.state.sipUsername !== deviceSipUsername || 
            this.state.recorded !== this.props.deviceToUpdate.recorded ||
            this.state.twoWayVideo !== this.props.deviceToUpdate.twoWayVideo ||
            this.state.incomingCalls !== this.props.deviceToUpdate.incomingCalls ||
            this.state.doorOpenDtmf !== deviceDoorOpenDtmf) { 
                return null;
        }

        return "No changes detected";
    }

    render() {
        const headerText = this.props.isUpdate ? 'Update Device' : 'Add Device';
        const doneButtonText = this.props.isUpdate ? 'Update' : 'Add';

        const statusMessage = this.props.tenant.callRecordingEnabled ? <div/> : <Form.Text className="text-muted">Recording is disabled globally. Enable recording in the tenant settings menu.</Form.Text> 

        const isSipUsernameCleared = this.isSipUsernameCleared();
        const sipUsernamePlaceHolder = isSipUsernameCleared || this.props.isTrunked ? "Enter SIP username" : "Enter SIP username (Leave empty for auto-detect)";

        const sipUsernameWarning = this.isSipUsernameNotUnique() ? 
            <Alert variant='warning' style={{ opacity: 1 }}>
                SIP username is not unique within trunk!
            </Alert> : 
            isSipUsernameCleared ?
                <Alert variant='warning' style={{ opacity: 1 }}>
                    SIP username cannot be cleared!
                </Alert> : 
                null;
        
        const sipUsernameRendered = !this.props.isTrunked ? null :                  
                    <Form.Group controlId="newSipUsername">
                        <Form.Label>SIP username</Form.Label>
                        <Form.Control type="text" placeholder={sipUsernamePlaceHolder} value={this.state.sipUsername} onChange={this.handleSipUsernameChanged} />
                        {sipUsernameWarning}
                        <Form.Text className="text-muted">
                            The SIP username is used to identify the device on SIP level. 
                        </Form.Text>
                    </Form.Group>;
        
        const sipUsernameDisplayStyle = this.state.sipUsername ? "font-weight-normal" : "font-italic";
        const sipUsernameDisplayRendered = !this.props.isUpdate || this.props.isTrunked ? null :
                <>
                    <hr/>
                    <Form.Group controlId="currentSipUsername">
                        <Form.Label>Detected SIP username</Form.Label>
                        <Form.Control type="text" className={sipUsernameDisplayStyle} placeholder="[Waiting for device registration]" value={this.state.sipUsername} disabled={true}/>
                    </Form.Group>
                </>

        const userNamePasswordMessageRendered = this.props.isUpdate || this.props.isTrunked ? null : 
            <Form.Group controlId="newUserNamePassword">
                <label className="my-1 mr-2" htmlFor="recordDeviceSwitch">Username & password</label>
                <Form.Text className="text-muted">The username and password for your device are automatically generated when a device is added.</Form.Text>
            </Form.Group>;

        return <Modal style={{ opacity: 1 }} show={this.props.isOpen} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                    {userNamePasswordMessageRendered}
                    <Form.Group controlId="newDeviceDescription">
                        <Form.Label>Display name</Form.Label>
                        <Form.Control type="text" placeholder="Enter display name" value={this.state.displayName} autoComplete="off" onChange={this.handleDisplayNameChanged} />
                        <Form.Text className="text-muted">
                            This name is used as a display name within Teams
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="newDeviceType">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" onChange={this.handleTypeChanged} value={this.state.type}>
                            {deviceTypesAndNames.map((entry) => {
                                return <option key={entry.deviceType} value={entry.deviceType}>{entry.displayName}</option>
                            })}                        
                        </Form.Control>
                        <Form.Text className="text-muted">
                            The device type is used for administrative use only
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="newDeviceLocation">
                        <Form.Label>Location</Form.Label>
                        <Form.Control type="text" placeholder="Enter location" value={this.state.location} autoComplete="off" onChange={this.handleLocationChanged} />
                        <Form.Text className="text-muted">
                            The device location is used for administrative use only
                        </Form.Text>
                    </Form.Group>
                    {sipUsernameRendered}
                    <Form.Group controlId="newRecorded">
                        <label className="my-1 mr-2" htmlFor="recordDeviceSwitch">Record device</label>
                        <CustomInput checked={this.state.recorded} disabled={!this.props.tenant.callRecordingEnabled} type="switch" onChange={this.toggleRecordDevice} id="recordDeviceSwitch" z-index={0} />
                        {statusMessage}
                    </Form.Group>
                    <Form.Group controlId="newTwowayVideo">
                        <label className="my-1 mr-2" htmlFor="twoWayVideoSwitch">Allow 2-way video</label>
                        {this.getTwoWayVideoCompatibilityList()}
                        <CustomInput checked={this.state.twoWayVideo} type="switch" onChange={this.toggleTwowayvideo} id="twoWayVideoSwitch" z-index={0} />
                        <Form.Text className="text-muted">For compatible devices that support receiving video.</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="newIncomingCalls">
                        <label className="my-1 mr-2" htmlFor="incomingCallsSwitch">Allow calls from Teams to device</label>
                        <CustomInput checked={this.state.incomingCalls} type="switch" onChange={this.toggleIncomingCalls} id="incomingCallsSwitch" z-index={0} />
                        <Form.Text className="text-muted">For devices that support incoming SIP calls.</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="newDoorOpenDtmf">
                        <Form.Label>Open-door code (optional)</Form.Label>
                        <Form.Control type="text" placeholder="Enter open-door code" value={this.state.doorOpenDtmf} autoComplete="off" onChange={this.handleDoorOpenDtmfChanged} />
                        <Form.Text className="text-muted">
                            The open-door code is sent as DTMF to the device when the open-door button in the CyberGate for Microsoft Teams App is pressed. Only DTMF charaters are allowed (0123456789&nbsp;#&nbsp;*).
                        </Form.Text>
                    </Form.Group>                 
                    {sipUsernameDisplayRendered}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddOrUpdateButtonBeEnabled()} disabledTooltip={this.getAddOrUpdateButtonTooltip()} onClick={this.handleDoneButtonClicked}>{doneButtonText}</ButtonWithDisabledTooltip>{' '}
            </Modal.Footer>
        </Modal>;
    }
}

export const ModalDeviceAddOrUpdate = (props) => {
    return (
        <ModalDeviceAddOrUpdateInternal {...props}/>
    )
}