import { Component } from 'react';
import { AuditTrailLine } from './AuditTrailLine';
import { Pager } from './Pager';

import css from './AuditTrail.module.scss';

export class AuditTrailLines extends Component {
    render() {
        const events = this.props.auditTrail && this.props.auditTrail.events ? this.props.auditTrail.events : [];
        const pageCount = this.props.auditTrail && this.props.auditTrail.pageCount ? this.props.auditTrail.pageCount : 0;
        const currentPage = this.props.currentPage ? this.props.currentPage : 0;

        return (
            <div className={css.tableFixHeadFoot}>
                <table className="table table-striped table-bordered my-2">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" className="align-middle">Date</th>
                            <th scope="col" className="align-middle">User</th>
                            <th scope="col" className="align-middle">Action</th>
                            <th scope="col" className="align-middle">Data</th>
                        </tr>
                    </thead>
                    <tbody >
                        {events.map((value, index) => {
                            return <AuditTrailLine key={index} auditTrailEntry={value} />
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="bg-light" height="70px">
                            <td className={`align-middle ${css.tableCellAlignCenter}`} colspan="4">
                                <Pager pageCount={pageCount} currentPage={currentPage} loadPage={this.props.loadPage}/>
                            </td>
                        </tr>
                    </tfoot>
                </table>           
            </div>
        )
    }
}