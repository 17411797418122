import React, { Fragment } from 'react';
import { DeviceEntry } from './DeviceEntry'; 
import { DeviceEntryLocationHeader } from './DeviceEntryLocationHeader';
import { InfoIcon }  from './InfoIcon';

export const DeviceEntries = ({ tenant, devices, devicesStatus, handleDelete, handleEdit }) => {
   
    // Ensure undefined, null or empty string are all treated as null value. This ensures that all
    // these variations are grouped together and are displayed last.
    const determineLocationForGrouping = (location) => {
        if (!location || location.length === 0) {
            return null;
        }

        return location;
    }

    const determineLocationDisplayName = (location) => {
        if (!location || location.length === 0) {
            return "Other locations";
        }

        return location;
    }    

    const renderDevices = () => {
        const uniqueLocations = devices.map(item => determineLocationForGrouping(item.location)).filter((v, i, a) => a.indexOf(v) === i).sort();

        const devicesRendered = uniqueLocations.map(location => {
            const devicesWithLocation = devices.filter((v, i, a) => determineLocationForGrouping(v.location) === location);

            const devicesOfLocationRendered = devicesWithLocation.map(device => {
                const deviceStatus = devicesStatus.find(deviceStatus => deviceStatus.deviceId === device.deviceId);
                return <DeviceEntry key={device.deviceId} tenant={tenant} device={device} deviceStatus={deviceStatus} handleDelete={handleDelete} handleEdit={handleEdit}/>
            });

            return <Fragment key={determineLocationDisplayName(location)}>
                <DeviceEntryLocationHeader key="header" location={determineLocationDisplayName(location)}/>
                {devicesOfLocationRendered}
            </Fragment>
        });
        
        return <Fragment>
                {devicesRendered}
            </Fragment>;
    }

    return (
        <div>
            <table className="table table-striped table-bordered my-2">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Display name</th>
                        <th scope="col">Authentication username</th>
                        <th scope="col">Password</th>
                        <th scope="col">
                            Licensed
                            <InfoIcon tooltip='The device will be licensed automatically when the total quantity of all the available licenses is sufficient. A non-licensed device will not be able to connect to the CyberGate service.'/>                            
                        </th>
                        <th scope="col">Recorded</th>
                        <th scope="col">Teams to device</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderDevices()}
                </tbody>
            </table>           
        </div>
    )
}