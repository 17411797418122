import { Component } from 'react';
import { AuditTrailDataCell } from './AuditTrailDataCell';
import * as timeDisplay from '../utils/timeDisplay';

export class AuditTrailLine extends Component {
    render() {
        const entry = this.props.auditTrailEntry;

        return (
            <tr height="70px">
                <td className={`align-middle`}>
                    {timeDisplay.dateStringToDisplayString(entry.date)}
                </td>
                <td className={`align-middle`}>
                    {entry.username}
                </td>
                <td className={`align-middle`}>
                    {entry.action}
                </td>
                <AuditTrailDataCell auditData={entry.data}/>
            </tr>
        )
    }
}