import { Component } from 'react';
import { appConfig } from '../logic/appConfigProvider';
import { Alert } from 'react-bootstrap';

export class TeamsAppBanner extends Component {
    state = {
    }

    async componentDidMount() {
    }

    render() {
        return (<>
            <Alert variant='info' transition={false}>
                <div className="mb-3">

                    <h5><span className="badge badge-secondary mr-3 mb-3">Notice</span>Microsoft Teams app</h5>
                    <li>
                        Set your personal availability for each configured multi-ring group or meeting.
                    </li>
                    <li>
                        Find all your configured devices.
                    </li>
                    <br/>
                    <a href={appConfig.johanAppTeamsStoreUrl} target='_blank' rel="noopener noreferrer">Get CyberGate for Microsoft Teams App here</a>
                </div>
            </Alert>
        </>);
    }
}