import React from 'react';
import { MultiringGroup } from './MultiringGroup'

export const MultiringGroups = ({ multiringGroups, verifiedDomains, handleEdit, handleDelete }) => {
    return (
        <div>
            {multiringGroups.map((multiringGroup) =>
                <MultiringGroup 
                    key={multiringGroup.callGroupId} 
                    multiringGroup={multiringGroup}
                    verifiedDomains={verifiedDomains}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}  />
            )}        
        </div>
    )
}