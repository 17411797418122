import { Component, useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'reactstrap';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { InfoIcon }  from '../../components/InfoIcon';
import { EditButton } from '../../components/EditButton';
import { ButtonWithDisabledTooltip } from '../../components/ButtonWithDisabledTooltip';
import { TenantEntries } from '../../components/TenantEntries';
import { DistributorEntries } from '../../components/DistributorEntries';
import { GlobalStatisticsOverview } from '../../components/GlobalStatisticsOverview';
import { GlobalStatisticsCharts } from '../../components/GlobalStatisticsCharts';
import { DeviceStatisticsCharts } from '../../components/DeviceStatisticsCharts';
import { ModalTenantLogoSelect } from '../../components/ModalTenantLogoSelect';
import { ModalSubscriptionUpdate } from '../../components/ModalSubscriptionUpdate';
import { ModalTenantAddOrUpdate } from '../../components/ModalTenantAddOrUpdate';
import { ModalTenantDisableConfirm } from '../../components/ModalTenantDisableConfirm';
import { ModalDistributorEnableConfirm } from '../../components/ModalDistributorEnableConfirm';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { NotificationContext } from '../../context/NotificationContext';
import { TenantContext } from '../../context/TenantContext';
import * as Misc from '../../utils/misc';
import * as apiCalls from '../../logic/apiCalls';

import css from './DistributorSettings.module.scss';


class DistributorSettingsInternal extends Component {
    tenantToUpdate = null;

    state = {
        isUploadLogoModalOpen: false,
        isUpdateSubscriptionModalOpen: false,
        isAddOrUpdateTenantModalOpen: false,
        isUpdateTenantModal: false,
        isDistributorModal: false,
        
        isConfirmDisableTenantModalOpen: false,
        isConfirmEnableDistributorModalOpen: false,
        isLoading: true,
        distributorLogo: null,
    }

    async componentDidMount() {
        await this.getDistributorLogo();
    }

    getDistributorLogo = async () => {
        try {
            console.log("[getDistributorLogo] Invoking the back-end API to get the distributor logo.");

            const distributorLogo = await apiCalls.getDistributorLogo(this.props.authenticationContext);

            this.setState({
                distributorLogo: URL.createObjectURL(distributorLogo)
            });
        }
        catch (err) {
            if (err.statusCode === 404)
            {
                this.setState({
                    distributorLogo: "./images/cybertwice_logo_v2_notext.svg"
                });
                return;
            }
            console.log(`[getDistributorLogo] Error retrieving your distributor icon: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    setDistributorLogo = async (logoFile) => {
        try {
            if (logoFile.size > 1024 * 1024)
            {
                this.props.notificationContext.setNotification(`Uploading logo.`, `Error uploading logo. '${logoFile.name}' is to large (1MB max). Please scale down.`, 'danger');
                return;
            }

            var data = new FormData()
            data.append('file', logoFile)

            await apiCalls.setDistributorLogo(this.props.authenticationContext, data);

            this.props.notificationContext.setNotification(`Uploading logo.`, `Successfully uploaded logo '${logoFile.name}'.`, 'success');
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Uploading logo.`, `Error uploading logo '${logoFile.name}'. ${err.toDetailedMessage()}`, 'danger');
        } finally {
            this.hideUploadLogoModal();
        }
        await this.getDistributorLogo();
    }

    addTenant = async (tenant) => {
        const tenantType = tenant.isDistributor ? "distributor" : "tenant";

        try {
            console.log("[addTenant] Invoking the back-end API to add a tenant to this distributor.");

            await apiCalls.addTenantByDistributor(this.props.authenticationContext,
                tenant.tenantId,
                tenant.tenantLabel,
                tenant.emailAddress,
                tenant.isDistributor,
                tenant.quantity);

            this.props.notificationContext.setNotification(`Adding ${tenantType}.`, `'${tenant.tenantLabel}' was successfully added.`, 'success');
            this.props.tenantContext.refreshAll();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Adding ${tenantType}.`, `Error adding '${tenant.tenantLabel}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    updateTenant = async (tenant) => {
        const tenantType = tenant.isDistributor ? "distributor" : "tenant";

        try {
            console.log("[updateTenant] Invoking the back-end API to update a tenant of this distributor.");

            await apiCalls.updateTenantByDistributor(this.props.authenticationContext, 
                tenant.tenantId, 
                tenant.tenantLabel,
                tenant.emailAddress,
                tenant.enabled,
                tenant.isDistributor,
                tenant.quantity);

            this.props.notificationContext.setNotification(`Updating ${tenantType}.`, `'${tenant.tenantLabel}' was successfully updated.`, 'success');
            this.props.tenantContext.refreshAll();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating ${tenantType}.`, `Error updating '${tenant.tenantLabel}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    updateDistributorQuantity = async (newSubscriptionQuantity, totalSelfLicenses) => {
        try {
            console.log(`[updateDistributorQuantity] Invoking the back-end API to update own license quantity.`);

            await apiCalls.updateDistributorQuantity(this.props.authenticationContext, newSubscriptionQuantity);

            this.props.notificationContext.setNotification(`Updating own license count.`, `License count is updated to ${totalSelfLicenses}.`, 'success');
            this.props.tenantContext.refreshAll();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Updating own license count.`, `Error updating license count to ${totalSelfLicenses}. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    showUploadLogoModal = () => {
        this.setState({
            isUploadLogoModalOpen: true
        }); 
    }

    hideUploadLogoModal = () => {
        this.setState({
            isUploadLogoModalOpen: false,
        }); 
    }

    showUpdateSubscriptionModal = () => {
        this.setState({
            isUpdateSubscriptionModalOpen: true
        });  
    }

    hideUpdateSubscriptionModal = () => {
        this.setState({
            isUpdateSubscriptionModalOpen: false,
        });
    };

    showAddTenantModal = () => {
        this.tenantToUpdate =  null;

        this.setState({
            isUpdateTenantModal: false,
            isDistributorModal: false,
            isResellerModal: false,
            
            isAddOrUpdateTenantModalOpen: true
        });
    };

    showAddDistributorModal = () => {
        this.tenantToUpdate =  null;
        
        this.setState({
            isUpdateTenantModal: false,
            isDistributorModal: true,
            isResellerModal: this.props.tenantContext.isNextLevelDistributorAReseller(),
            isAddOrUpdateTenantModalOpen: true
        });
    };

    showUpdateTenantModal = (tenant) => {
        this.tenantToUpdate =  tenant;
        
        this.setState({
            isUpdateTenantModal: true,
            isDistributorModal: tenant.isDistributor,
            isResellerModal: this.props.tenantContext.isNextLevelDistributorAReseller(),
            isAddOrUpdateTenantModalOpen: true
        });
    };

    hideTenantModal = () => {
        this.setState({
            isAddOrUpdateTenantModalOpen: false,
        });
    };

    showTenantDisableConfirmModal = (tenant) => {
        this.tenantToUpdate =  tenant;
        
        this.setState({
            isConfirmDisableTenantModalOpen: true,
        });        
    }

    hideTenantDisableConfirmModal = () => {
        this.tenantToUpdate =  null;
        
        this.setState({
            isConfirmDisableTenantModalOpen: false,
        });
    };
    
    showDistributorEnableConfirmModal = (tenant) => {
        this.tenantToUpdate =  tenant;
        
        this.setState({
            isConfirmEnableDistributorModalOpen: true,
        });        
    }

    hideDistributorEnableConfirmModal = () => {
        this.tenantToUpdate =  null;
        
        this.setState({
            isConfirmEnableDistributorModalOpen: false,
        });
    };

    getSubscription = () => {
        if (this.props.tenantContext.selfSubscriptions.length <= 0) {
            // Return a dummy subscription.
            return {
                quantity: 0,
            };
        }

        // There can be more then 1 subscription so for now use the oldest.
        const orderedSubs = this.props.tenantContext.selfSubscriptions.sort((a,b) => (a.creationTime > b.creationTime) ? 1 : ((b.creationTime > a.creationTime) ? -1 : 0));

        return orderedSubs[0];
    }

    handleUpdateSubscription = (newQuantity) => {
        console.log(`[handleUpdateSubscription] Updating own license count to ${newQuantity}`);
        this.hideUpdateSubscriptionModal();

        const subToUpdate = this.getSubscription();
        if (!subToUpdate.subscriptionId) {
            return;
        }

        // Calculate the difference for this subscription to come to the new total.
        const totalLicenseCount = this.props.tenantContext.selfLicenseCount;
        const quantityDifference = newQuantity - totalLicenseCount;
        var newSubQuantity = subToUpdate.quantity + quantityDifference;
        if (newSubQuantity < 0)
        {
            console.log(`[handleUpdateSubscription] Failed updating own license count to ${newQuantity}`);
            return;
        }

        this.updateDistributorQuantity(newSubQuantity, newQuantity);
        this.props.tenantContext.refreshSubscriptions();
    }

    handleAddOrUpdate = (isUpdate, tenant, enabledChanged) => {
        this.hideTenantModal();
        if (isUpdate) {
            if (enabledChanged)
            {

                if (!tenant.enabled)
                {
                    this.showTenantDisableConfirmModal(tenant);
                }
                else
                {
                    if (tenant.isDistributor)
                    {
                        this.showDistributorEnableConfirmModal(tenant);
                    }
                    else
                    {
                        this.updateTenant(tenant);
                    }
                }
            }
            else
            {
                this.updateTenant(tenant);
            }
        } else {
            this.addTenant(tenant);
        }
    }

    handleDisableConfirm = () => {
        this.updateTenant(this.tenantToUpdate);
        this.hideTenantDisableConfirmModal();
    }

    handleEnableConfirm = () => {
        this.updateTenant(this.tenantToUpdate);
        this.hideDistributorEnableConfirmModal();
    }

    render() {

        const distributorTypeString = this.props.tenantContext.tenantType;
        const distributorNextLevelTypeString = this.props.tenantContext.isNextLevelDistributorAReseller() ? "reseller" : "distributor";

        const headerRendered = <h1>{Misc.capitalizeFirstLetter(distributorTypeString)} settings</h1>;

        const distributorIconRendered = this.state.distributorLogo === null ? 
            <p className={css.logoImage}>Loading...</p> :
            <img className={css.logoImage} src={this.state.distributorLogo} alt="Distributor logo" />;

        if (this.props.tenantContext.isLoading) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const controlledDistributors = this.props.tenantContext.distributor ? this.props.tenantContext.distributor.tenants.filter(tenant => tenant.isDistributor) : [];
        const controlledTenants = this.props.tenantContext.distributor ? this.props.tenantContext.distributor.tenants.filter(tenant => !tenant.isDistributor) : [];

        controlledDistributors.sort((a,b) => (a.creationTime < b.creationTime) ? 1 : ((b.creationTime < a.creationTime) ? -1 : 0))
        controlledTenants.sort((a,b) => (a.creationTime < b.creationTime) ? 1 : ((b.creationTime < a.creationTime) ? -1 : 0))

        const distributorsRendered = controlledDistributors.length > 0 ? 
            <DistributorEntries distributors={controlledDistributors} onEdit={this.showUpdateTenantModal} editEnabled={this.props.tenantContext.enabled} /> : 
            <div className='my-4'>
                <Alert variant='info' style={{ opacity: 1 }}>
                    No distributors configured.
                </Alert>
            </div>

        const distributorReportButtonRendered = controlledDistributors.length > 0 ? 
            <Link className="float-right" to='/portal/distributor-report'><Button color="primary">Go to {distributorNextLevelTypeString} report</Button></Link> :
            null

        const tenantsRendered = controlledTenants.length > 0 ? 
            <TenantEntries tenants={controlledTenants} onEdit={this.showUpdateTenantModal} editEnabled={this.props.tenantContext.enabled} /> : 
            <div className='my-4'>
                <Alert variant='info' style={{ opacity: 1 }}>
                    No tenants configured.
                </Alert>
            </div>

        const tenantReportButtonRendered = controlledTenants.length > 0 ? 
            <Link className="float-right" to='/portal/tenant-report'><Button color="primary">Go to tenant report</Button></Link> :
            null

        const distributorsSectionRendered = this.props.tenantContext.distributor?.mayCreateDistributors ?
            <>
                <br/>
                <div>
                    <h4>
                        Your {distributorNextLevelTypeString}s ({controlledDistributors.length})
                        <InfoIcon className="float-right" tooltip={`A ${distributorNextLevelTypeString} is a customer that is allowed to create tenants of their own.`} />
                    </h4>                        
                </div>
                <div>
                    <ButtonWithDisabledTooltip disabled={!this.props.tenantContext.enabled} disabledTooltip="Your tenant is disabled" color="primary" onClick={this.showAddDistributorModal}>Add {distributorNextLevelTypeString}</ButtonWithDisabledTooltip>
                    {distributorReportButtonRendered}
                    {distributorsRendered}
                </div>
            </> : null;


        const maxQuantity = this.props.tenantContext.isDistributor ? Misc.intToString(this.props.tenantContext.distributor.maxQuantity, "Unlimited") : 0;
        const quantityInStock = this.props.tenantContext.isDistributor ? this.props.tenantContext.distributor.quantityInStock : 0;
        const licenseMinimum = this.props.tenantContext.selfLicenseCount - this.getSubscription().quantity;

        const selfLicenseEditButtonRendered = this.props.tenantContext.selfSubscriptions.length <= 0 ? null : <EditButton className="float-left" tooltip="Edit license count" onClick={this.showUpdateSubscriptionModal}/>;

        const cyberTwiceOnlyRendered = this.props.tenantContext.isCyberTwice ? 
            <>
                <GlobalStatisticsOverview/>
                <br/>
                <GlobalStatisticsCharts/>
                <br/>
                <DeviceStatisticsCharts/>
                <br/>
                <br/>
                <Link to='/portal/marketplace-report'><Button color="primary">Go to marketplace report</Button></Link>
                <br/>
                <br/>
             </> : 
            null;
        
        return <Container fluid>
                    <ModalTenantLogoSelect
                        show={this.state.isUploadLogoModalOpen}
                        onHide={this.hideUploadLogoModal}
                        onUploadLogo={this.setDistributorLogo} />
                    <ModalSubscriptionUpdate 
                        distributorLicensesLeft={quantityInStock}
                        quantity={this.props.tenantContext.selfLicenseCount}
                        quantityMinimum={licenseMinimum}
                        subscriptionCountAvailable={quantityInStock}
                        show={this.state.isUpdateSubscriptionModalOpen}
                        onHide={this.hideUpdateSubscriptionModal}
                        onUpdate={this.handleUpdateSubscription} />
                    <ModalTenantAddOrUpdate 
                        tenant={this.tenantToUpdate}
                        show={this.state.isAddOrUpdateTenantModalOpen}
                        onHide={this.hideTenantModal} 
                        isUpdate={this.state.isUpdateTenantModal}
                        isDistributor={this.state.isDistributorModal}
                        isReseller={this.props.tenantContext.isNextLevelDistributorAReseller()}
                        distributorLicensesLeft={quantityInStock}
                        isDistributorUnlimited={maxQuantity === "Unlimited"}
                        onAddOrUpdate={this.handleAddOrUpdate} />
                    <ModalTenantDisableConfirm
                        tenant={this.tenantToUpdate}
                        show={this.state.isConfirmDisableTenantModalOpen}
                        onHide={this.hideTenantDisableConfirmModal} 
                        isDistributor={this.state.isDistributorModal}
                        onDisableConfirm={this.handleDisableConfirm} />
                    <ModalDistributorEnableConfirm
                        tenant={this.tenantToUpdate}
                        show={this.state.isConfirmEnableDistributorModalOpen}
                        onEnableConfirm={this.handleEnableConfirm} />
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <br/>
                    {cyberTwiceOnlyRendered}
                    <br/>
                    <div>
                        <h4>Company logo</h4>
                        <Button onClick={this.showUploadLogoModal} color="primary" className={css.logoButton}>Upload your company logo</Button>
                        {distributorIconRendered}
                        <InfoIcon className="float-right" tooltip="This logo will be visible for your customers when they log into the management portal." />
                    </div>
                    <br/>
                    <div>
                        <h4>Audit trail</h4>
                        <Link to='/portal/audit-trail'><Button color="primary">Show</Button></Link>
                    </div>
                    <br/>
                   <div>
                        <h4>Licenses</h4>
                    </div>
                    <div>
                        <table className="table-light table table-striped table-bordered w-auto">
                            <tbody>
                                <tr height="45px">
                                    <th scope="col" width="200px" className="text-left font-weight-normal">
                                        <div className={css.flexContainer}>
                                            <div>
                                                Maximum
                                            </div>
                                            <div className={css.flexItemRight}>
                                                <InfoIcon className="float-right" tooltip="The maximum amount of licenses that you can hand out to your customers." />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" width="80px" className="text-right font-weight-normal">{maxQuantity}</th>
                                </tr>
                                <tr height="45px">
                                    <th scope="col" width="200px" className="text-left font-weight-normal">
                                        <div className={css.flexContainer}>
                                            <div>
                                                In stock
                                            </div>
                                            <div className={css.flexItemRight}>
                                                <InfoIcon className="float-right" tooltip="The amount of licenses left to hand out." />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" width="80px" className="text-right font-weight-normal">{Misc.intToString(quantityInStock, "Unlimited")}</th>
                                </tr>
                                <tr height="45px">
                                    <th scope="col" width="200px" className="text-left font-weight-normal">
                                        <div className={css.flexContainer}>
                                            <div>
                                                For own use
                                            </div>
                                            <div className={css.flexItemRight}>
                                                <InfoIcon className="float-right" tooltip="Licenses for your own use only. Like for test devices or intercoms on your own building." />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" width="80px" className="text-right font-weight-normal">
                                        {this.props.tenantContext.enabled && selfLicenseEditButtonRendered}
                                        {this.props.tenantContext.selfLicenseCount}
                                    </th>
                                </tr>
                            </tbody>
                        </table> 

                    </div>
                    {distributorsSectionRendered}
                    <br/>
                    <div>
                        <h4>Your tenants ({controlledTenants.length})</h4>
                    </div>
                    <div>
                        <ButtonWithDisabledTooltip disabled={!this.props.tenantContext.enabled} disabledTooltip="Your tenant is disabled" color="primary" onClick={this.showAddTenantModal}>Add tenant</ButtonWithDisabledTooltip>
                        {tenantReportButtonRendered}
                        {tenantsRendered}
                    </div>
                    <br/>               
                    <br/>
                </Container>;
    }
}

// Wrap the component so that multiple contexts are available via the props.
export const DistributorSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <DistributorSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}