import React, { Fragment } from 'react';
import { TrunkEntry } from './TrunkEntry';
import { InfoIcon }  from './InfoIcon';

export const TrunkEntries = ({ tenant, trunks, handleDelete, handleEdit }) => {
   
    const renderTrunks = () => {
        const trunksRendered = trunks.map(trunk => {
             return <TrunkEntry key={trunk.sipTrunkId} tenant={tenant} trunk={trunk} handleDelete={handleDelete} handleEdit={handleEdit}></TrunkEntry>
        })
        
        return <Fragment>
                {trunksRendered}
            </Fragment>;
    }

    return (
        <div>
            <table className="table table-striped table-bordered my-2">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Authentication username</th>
                        <th scope="col">Password</th>
                        <th scope="col">Auto discover<InfoIcon tooltip='Auto discovery of devices. Devices will be automaticly be detected, created and linked to this SIP trunk when enabled.'/></th>
                        <th scope="col">Devices</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTrunks()}
                </tbody>
            </table>           
        </div>
    )
}