// This file contains the settings of the appConfig that needs to be overridden when the application is run in development.

export const appConfig = {
    attestReplayUrl: "https://cybereplay.attest.cybertwice.com",
    attestAdminUrl: "https://admin.attest.cybertwice.com",

    // Test clientId and apiScope
    //frontendMsalClientId: '2b469442-72ef-41c9-a00b-6571779b5c37',
    //frontendMsalApiScope: 'api://a7102ade-c5f0-45ec-9725-379c86b733ad/Tenant.Read',

    // Production clientId and apiScope
    //frontendMsalClientId: 'e4e908d5-b920-44be-b1b7-acd33ce08166',
    //frontendMsalApiScope: 'https://admin.cybergate.cybertwice.com/api/Tenant.Read',

    // CyberGate Management Portal front-end Local clientId and apiScope
    frontendMsalClientId: 'ef4463f6-109a-462d-b6c5-5e5d00e46c48',
    frontendMsalApiScope: 'https://adminlocal.development.cybertwice.com/api/Tenant.Read',

    // Intercom
    //azureActiveDirectoryClientId: "ec32bf45-0406-4ba3-8066-08de2d0877b4",
    // Intercom-Local
    azureActiveDirectoryClientId: "63bc49ba-ff99-4e92-8f22-0118a0b98ec9", 
};
