import { useContext, Component, Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'reactstrap';
import { Button } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { NotificationContext } from '../../context/NotificationContext';
import { MultiringGroups } from '../../components/MultiringGroups';
import { TeamsAppBanner } from '../../components/TeamsAppBanner'
import { ButtonWithDisabledTooltip } from '../../components/ButtonWithDisabledTooltip';
import { ModalMultiringGroupAddOrUpdate } from '../../components/ModalMultiringGroupAddOrUpdate';

import * as apiCalls from '../../logic/apiCalls';

// The maximum allowed number of multi-ring groups per tenant.
const maximumNumberOfMultiringGroups = 64;

const fallbackDomain = {
    "capabilities": "OfficeCommunicationsOnline",
    "isDefault": true,
    "isInitial": false,
    "name": "cybergate.cybertwice.com",
    "type": "Managed"
};

class MultiringSettingsInternal extends Component {
    state = {
        isLoadingMultiringGroups: true,
        isLoadingDomains: true,
        multiringGroups: [],
        verifiedDomains: [fallbackDomain],
        multiringGroupToDelete: {},
        multiringGroupToUpdate: {},
        isMultiringGroupModalOpen: false,
        isUpdateMultiringGroupModal: false,
        isConfirmDeleteModalOpen: false,
    }

    // Get all multi-ring groups when the page is loaded.
    async componentDidMount() {
        this.getMultiringGroups();
        this.getOrganizationDomains();
    }

    showNewMultiringGroupModal = () => {
        this.setState({
            isMultiringGroupModalOpen: true,
            isUpdateMultiringGroupModal: false,
        });
    };

    showUpdateMultiringGroupModal = () => {
        this.setState({
            isMultiringGroupModalOpen: true,
            isUpdateMultiringGroupModal: true,
        });
    };

    hideMultiringGroupModal = () => {
        this.setState({
            isMultiringGroupModalOpen: false
        });
    };

    // Confirm delete of multi-ring group modal showing/hiding stuff.
    showConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: true
        });
    };

    hideConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: false
        });
    };

    getMultiringGroups = async () => {
        try {
            console.log("[getMultiringGroups] Invoking the back-end API to get all multi-ring groups of this tenant.");

            const multiringGroups = await apiCalls.getMultiringGroups(this.props.authenticationContext);

            console.log(`[getMultiringGroups] Successfully retrieved the multi-ring groups: ${multiringGroups.length}`)
            this.setState({
                multiringGroups: multiringGroups
            });
        }
        catch (err) {
            console.log(`[getMultiringGroups] Error retrieving the multi-ring groups: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                 
        }
        finally {
            this.setState({
                isLoadingMultiringGroups: false
            });
        }
    }

    getOrganizationDomains = async () => {
        try {
            console.log("[getOrganizationDomains] Invoking the Graph API to get the organization domains of this tenant.");

            const receivedVerifiedDomains = await apiCalls.getAvailableDomains(this.props.authenticationContext);

            console.log(`[getOrganizationDomains] Successfully retrieved the verified domains: ${JSON.stringify(receivedVerifiedDomains)}`)

            // Find the default domain and put this domain at the top.
            let defaultDomainIndex = receivedVerifiedDomains.findIndex(domain => domain.isDefault === true);
            if (defaultDomainIndex >= 0) {
                let defaultdomain = receivedVerifiedDomains.splice(defaultDomainIndex, 1)[0];
                receivedVerifiedDomains.unshift(defaultdomain);
            }

            this.setState({
                verifiedDomains: receivedVerifiedDomains,
            });
        }
        catch (err) {
            // The global error is not set here, because failure of retrieving the organization names should not break the interface.
            console.log(`[getOrganizationDomains] Error while retrieving organization verified domains for tenant: ${JSON.stringify(err)}`);
        }
        finally {
            this.setState({
                isLoadingDomains: false,
            });
        }
    }

    executeAddOrUpdate = async (doneInfo) => {
        if (doneInfo.isUpdate) {
            this.updateMultiringGroup(doneInfo.callGroupId, doneInfo.multiringGroupName, doneInfo.multiringGroupDescription);
        } else {
            this.addMultiringGroup(doneInfo.multiringGroupName, doneInfo.multiringGroupDescription);
        }

        this.hideMultiringGroupModal();
    }
    
    handleMultiringGroupDelete = (multiringGroup) => {
        this.setState({
            multiringGroupToDelete: multiringGroup,
        });
        this.showConfirmDeleteModal();
    }

    handleMultiringGroupEdit = (multiringGroup) => {
        this.setState({
            multiringGroupToUpdate: multiringGroup,
        });
        this.showUpdateMultiringGroupModal();
    }

    // The Add multi-ring group button should only be enabled when there are currently less than 64 multi-ring 
    // groups configured for this tenant.
    shouldAddMultiringGroupButtonBeEnabled = () => {
        if (this.state.multiringGroups.length >= maximumNumberOfMultiringGroups) {
            return false;
        }

        return true;
    }

    constructMultiringGroupName = (name) => {
        return `${name}@${fallbackDomain.name}`;
    }

    deleteMultiringGroup = async () => {
        try {
            console.log(`[deleteMultiringGroup] Invoking the back-end API to delete the multi-ring group with Id ${this.state.multiringGroupToDelete.callGroupId}.`);

            await apiCalls.deleteMultiringGroup(this.props.authenticationContext, this.state.multiringGroupToDelete.callGroupId);

            this.props.notificationContext.setNotification(`Deleting multi-ring group.`, `'${this.state.multiringGroupToDelete.name}' successfully deleted.`, 'success');

            this.getMultiringGroups();
        }
        catch (err) {   
            this.props.notificationContext.setNotification(`Deleting multi-ring group.`, `Error deleting '${this.state.multiringGroupToDelete.name}'. ${err.toDetailedMessage()}`, 'danger');
        }

        this.hideConfirmDeleteModal();
    }

    addMultiringGroup = async (newMultiringGroupName, newMultiringGroupDescription) => {
        try {
            console.log(`[addMultiringGroup] Invoking the back-end API to add the multi-ring group with name ${newMultiringGroupName}.`);

            await apiCalls.addMultiringGroup(this.props.authenticationContext, this.constructMultiringGroupName(newMultiringGroupName), newMultiringGroupDescription);
            
            this.props.notificationContext.setNotification(`Adding multi-ring group.`, `'${newMultiringGroupName}' successfully added.`, 'success');
            this.getMultiringGroups();
        }
        catch (err) {  
            this.props.notificationContext.setNotification(`Adding multi-ring group.`, `Error adding '${newMultiringGroupName}'. ${err.toDetailedMessage()}`, 'danger');
        }        
    }

    updateMultiringGroup = async (callGroupId, multiringGroupName, multiringGroupDescription) => {
        try {
            console.log(`[updateMultiringGroup] Invoking the back-end API to update the multi-ring group with name ${multiringGroupName}.`);

            await apiCalls.updateMultiringGroup(this.props.authenticationContext, callGroupId, this.constructMultiringGroupName(multiringGroupName), multiringGroupDescription);
            
            this.props.notificationContext.setNotification(`Updating multi-ring group.`, `'${multiringGroupName}' successfully updated.`, 'success');
            this.getMultiringGroups();
        }
        catch (err) {  
            this.props.notificationContext.setNotification(`Updating multi-ring group.`, `Error updating '${multiringGroupName}'. ${err.toDetailedMessage()}`, 'danger');
        }        
    }

    checkNameAvailable = async (name) => {
        try {
            const fullName = this.constructMultiringGroupName(name);

            console.log(`[checkNameAvailable] Invoking the back-end API to check if ${fullName} is available.`);

            const result = await apiCalls.isCyberGateNameAvailable(this.props.authenticationContext, fullName);

            console.log(`[checkNameAvailable] Successfully checked name ${fullName}: ${result.isAvailable ? "is available" : "is not available" }`)
            
            return result.isAvailable;
        }
        catch (err) {
            console.log(`[checkNameAvailable] Error checking name availability: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        return false;
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>Multi-ring settings</h1> 

        if (this.state.isLoadingMultiringGroups || this.state.isLoadingDomains) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const confirmDeleteModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmDeleteModalOpen} onHide={this.hideConfirmDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete multi-ring group '{this.state.multiringGroupToDelete.name}'?</Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.deleteMultiringGroup}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        const multiringGroupsRendered = this.state.multiringGroups.length > 0 ? 
            <MultiringGroups 
                multiringGroups={this.state.multiringGroups} 
                verifiedDomains={this.state.verifiedDomains} 
                handleDelete={this.handleMultiringGroupDelete} 
                handleEdit={this.handleMultiringGroupEdit} /> : 
            <Fragment/>;

        return (
            <Container fluid>
                <ModalMultiringGroupAddOrUpdate
                        show={this.state.isMultiringGroupModalOpen}
                        onHide={this.hideMultiringGroupModal} 
                        isUpdate={this.state.isUpdateMultiringGroupModal}
                        domainName={fallbackDomain.name}
                        multiringGroupToUpdate={this.state.multiringGroupToUpdate}
                        checkNameAvailableFunction={this.checkNameAvailable}
                        onAddOrUpdate={this.executeAddOrUpdate} />
                {confirmDeleteModalRendered}
                {headerRendered}
                {this.props.tenantContext.showWarningWhenDisabled()}
                <p>The multi-ring feature makes it possible to ring multiple users simultaneously. The first responder will get the call. The multi-ring feature is intended for intercom devices.</p>
                <TeamsAppBanner/>
                <hr/> 
                <br/>      
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddMultiringGroupButtonBeEnabled()} disabledTooltip="Maximum number of multi-ring groups defined" onClick={this.showNewMultiringGroupModal}>Add multi-ring group</ButtonWithDisabledTooltip>            
                {multiringGroupsRendered}                  
                <br/>
                <br/>
            </Container>
        );
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const MultiringSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <MultiringSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}