import { Component } from 'react';
import { AccessGroupEntry } from './AccessGroupEntry';

export class AccessGroupEntries extends Component {
    render () {
       
        return (
            <>
                <ul>
                    {this.props.accessGroups.map((group) => {
                        return <AccessGroupEntry key={group.id} accessGroup={group}/>
                    })}
                </ul>
            </>);
    }
}