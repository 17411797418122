import { HSLColour } from '../utils/Colour.src';

/** Type represents a statistic entry which contains data of a single period, which can be for example an hour, day, week or month. */
type Statistic = {
    /** The label of the period of this statistic in ISO8601 format. */
    periodLabel: string,
    /** The total number of licenses in this period. */
    total: number
    /** The increased number of licenses in this period. */
    increase: number
    /** The decreased number of licenses in this period. */
    decrease: number
    /** The delta license count in this period. */
    delta: number
}

type Device = {
    /** The name of the brand. */
    brand: string,
    /** The total number of devices for this brand. */
    count: number
}

/** Get an HTML color string (like '#5c5cd6') for the provided index of the chart entry. This color is determined based upon the 
 *  total number of entries and the index of the current entry. The colors are divided equally over the HSL color scheme.
  */
export const getColor = (index: number, colorCount: number) : string => {    
    const stepSize = 360 / colorCount;
    const offset = 240;
    const hslValue = (offset + (stepSize * index)) % 360;
    
    const barColor = new HSLColour(hslValue, 60, 60) as any;
    return barColor.getCSSHexadecimalRGB();
}

/** Gets the data and bar definitions for the 'License total' graph. */
export const getLicensesTotal = (statistics: Statistic[]) => {
    let [minValue, maxValue] = [statistics[0].total, statistics[0].total];   
    const licensesTotal = statistics.map(statistic => {
        if (statistic.total < minValue) minValue = statistic.total;
        if (statistic.total > maxValue) maxValue = statistic.total;

        return {
            date: statistic.periodLabel,
            total: statistic.total
        }
    });

    minValue = Math.floor(minValue / 200) * 200;
    maxValue = Math.ceil(maxValue / 200) * 200;
    
    return {
        data: licensesTotal,
        definitions: [{displayName: "Total", valueName: "total"}],
        limits: [{minValue: minValue, maxValue: maxValue}]
    }
}

/** Gets the data and bar definitions for the 'License difference' graph. */
export const getLicensesDelta = (statistics: Statistic[]) => {    
    const totalLicenseCount = statistics.map(statistic => {
        return {
            date: statistic.periodLabel,
            increase: statistic.increase,
            decrease: statistic.decrease,
            delta: statistic.delta
        }
    });

    return {
        data: totalLicenseCount,
        definitions: [{displayName: "Increase", valueName: "increase"}, {displayName: "Decrease", valueName: "decrease"}, {displayName: "Delta", valueName: "delta"}]
    }
}

export const getDevices = (statistics: Device[]) => {    
    const devices = statistics.map(statistic => {
        return {
            brand: statistic.brand,
            count: statistic.count,
        }
    });

    return {
        data: devices,
        countLabel: "count",
        brandLabel: "brand",
        count: statistics.reduce((total, statistic) => total + statistic.count, 0)
    }
}
