import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, Jumbotron } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { ErrorHandlingContext } from '../../context/ErrorHandlingContext';
import * as apiCalls from '../../logic/apiCalls';

import qs from 'qs';
import sharedCss from '../Shared.module.scss';

// This component is the landing page from the redirect back from the admin-consent page of Microsoft.
// The admin-consent endpoint of Azure AD does not provide authentication information, but only an indication if the consent was provided or not.
// Example of failure to provide admin consent:
// http://localhost:3000/post-consent-spa?error=access_denied&error_description=AADSTS65004%3a+User+declined+to+consent+to+access+the+app.%0d%0aTrace+ID%3a+80051703-4180-441f-bb26-377b775f4c02%0d%0aCorrelation+ID%3a+9717b7d1-d30e-4bc5-b5f5-be525b1120f6%0d%0aTimestamp%3a+2020-06-19+09%3a26%3a59Z&error_uri=https%3a%2f%2flogin.microsoftonline.com%2ferror%3fcode%3d65004&admin_consent=True&state=LDRDEMF27QEVFT6UYI9J%7d
// Example of successfully providing admin consent:
// http://localhost:3000/post-consent-spa?admin_consent=True&tenant=4507b4d5-9b9b-4b6f-b848-bd21b7aa1667&state=LDRDEMF27QEVFT6UYI9J%7d
export const PostConsent = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const errorHandlingContext = useContext(ErrorHandlingContext);

    const [consentProvided, setConsentProvided] = useState(false);
    const [consentConfirmed, setConsentConfirmed] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const getConsentResult = useCallback(async () => {        
        // Verify if the administrator gave consent or not.

        // ToDo: Verify if the state parameter matches the one that was stored before the re-direct.
        // ToDo: Verify that the tenant in the URL matches the tenant of the currently logged-in user.
        let consentProvided = false;
        let errorDescription = "";

        if (!authenticationContext.isAuthenticated()) {
            errorDescription = "The admin consent should be provided from within the management portal. Please login to the portal and start the admin consent from the tenant settings page.";
        }
        else {
            const queryParameters = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            if (queryParameters.admin_consent && queryParameters.admin_consent.toLowerCase() === 'true') {
                if (queryParameters.error_description) {
                    errorDescription = queryParameters.error_description;
                } else {
                    consentProvided = true;                    
                }
            } else {
                errorDescription = "No valid parameters found in redirect URL.";
            }
        }
        
        setConsentProvided(consentProvided);
        if (!consentProvided)
        {
            errorHandlingContext.setError(errorDescription, "");
        }        
    }, [authenticationContext, errorHandlingContext, props.location.search]);

    // Method sets the 'admin consent confirmed' flag for the tenant of the logged-in user.
    const setAdminConsentConfirmedForTenant = useCallback(async () => {
        try {
            await apiCalls.confirmAdminConsent(authenticationContext);

            setConsentConfirmed(true);
        }
        catch (err) {
            errorHandlingContext.setError("Unexpected error occurred. Please try again later.", err);            
        }
    }, [authenticationContext, errorHandlingContext]);

    // Get the result from the admin consent.
    useEffect(() => {
        getConsentResult();
    }, [getConsentResult]);

    // Set the 'admin consent confirmed' flag for the tenant of the logged-in user in the back-end.
    useEffect(() => {
        if (consentProvided) {
            setAdminConsentConfirmedForTenant();            
        }
    }, [consentProvided, setAdminConsentConfirmedForTenant])

    // Set a timer to redirect the user back to the tenant settings page after successful provindg of the consent.
    useEffect(() => {
        if (consentConfirmed) {
            const id = setTimeout(() => setRedirect(true), 3000);

            return () => {
                clearTimeout(id);
            }
        }
    }, [consentConfirmed])

    if (redirect) {
        if (authenticationContext.isAuthenticated()) {
            console.log("User is authenticated, redirect the user to the tenant settings page.");
            // If the user is currently logged-in, redirect the user to the tenant-settings page where the user very likely came from.
            return <Redirect to='/portal/tenant-settings' />
        } else {
            console.log("User is not authenticated, redirect the user to the home page.")
            return <Redirect to='/' />
        }
    }

    if (consentProvided) {
        const userMessage = authenticationContext.isAuthenticated() ?
            <p>Please wait while we redirect you back to the portal...</p> :
            <div>
                You can now login to your account and configure your devices.
                <Link to='/'>Back to home</Link>
            </div>

        return (<div className={sharedCss.background}>
            <div className="page-header d-flex">
                <div className="p-4">
                    <img className={sharedCss.logo} src="./images/cybertwice_logo_v2_notext.svg" alt="Cybertwice logo" />
                </div>
            </div>
            <div className="m-3">
                <h1 className="text-center">Admin consent successfully provided</h1>
                <p />
                {userMessage}
            </div>
        </div>)
    }

    return (
        <Container>
            <Jumbotron>
                <p><strong>An error occurred during the providing of the admin consent.</strong></p>
                <Link to='/'>Back to home</Link>
            </Jumbotron>
        </Container>        
    )
}