import React from 'react';
import { CallGroupParticipant } from './CallGroupParticipant'; 

export const CallGroupParticipants = ({participants, handleParticipantDelete}) => {
    return (
        <div>
            <table className="table table-striped table-bordered my-4">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Teams username</th>
                        <th scope="col" width="70px">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {participants.map((value) => {
                        return <CallGroupParticipant key={value.participantId} participant={value} handleParticipantDelete={handleParticipantDelete} />
                    })
                    }
                </tbody>
            </table>           
        </div>
    )
}