import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';

class ModalTrunkConfirmDeleteInternal extends Component {
    handleDeleteButtonClicked = () => {
        this.props.onConfirmed(this.props.trunkToDelete);
    }

    render() {
        return <Modal style={{ opacity: 1 }} show={this.props.isOpen} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete SIP trunk '{this.props.trunkToDelete?.name}'?<br/>
                <br/>    
                The SIP trunk and all of it's {this.props.trunkToDelete?.deviceCount} devices will be removed. These devices will no longer be able to use the CyberGate service.           
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.props.onHide}>
                    Cancel
                </Button>
                <Button color="primary" onClick={this.handleDeleteButtonClicked}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}

export const ModalTrunkConfirmDelete = (props) => {
    return (
        <ModalTrunkConfirmDeleteInternal {...props}/>
    )
}