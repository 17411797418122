import React from 'react';
import { Meeting } from './Meeting'

export const Meetings = ({ meetings, verifiedDomains, handleEdit, handleDelete }) => {
    return (
        <div>
            {meetings.map((meeting) =>
                <Meeting 
                    key={meeting.callGroupId} 
                    meeting={meeting}
                    verifiedDomains={verifiedDomains}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}  />
            )}        
        </div>
    )
}