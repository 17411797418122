import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

const logoFileLabel = "Choose your logo file.";

export class ModalTenantLogoSelect extends Component {
    state = {
        logoFile: {name: logoFileLabel},
    }

    componentDidUpdate(prevProps) {
    }

    onLogoFileChange = (file) => {
        this.setState({
            logoFile: file
        });        
    }

    handleUpload = () => {
        this.props.onUploadLogo(this.state.logoFile);
    }

    render()
    {
        const isUploadButtonDisabled = this.state.logoFile.name === logoFileLabel;

        return <>
            <Modal style={{ opacity: 1 }} size="lg" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload distributor logo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile2" as={Row} >
                            <Form.File
                                type="file"
                                label={this.state.logoFile.name}
                                onChange={
                                    (e) => this.onLogoFileChange(e.target.files[0])
                                }
                                accept=".jpg,.png,.svg"
                                custom
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <ButtonWithDisabledTooltip color="primary" disabled={isUploadButtonDisabled} disabledTooltip="Select file to upload" onClick={this.handleUpload}>
                        Upload
                    </ButtonWithDisabledTooltip>
                </Modal.Footer>
            </Modal>
        </>;
    }
}