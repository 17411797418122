import React from 'react';
import { Button } from 'reactstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const EditButton = ({className, tooltip, onClick, placement = undefined}) => {
    return (
        <OverlayTrigger 
            trigger={['hover', 'hover']} // Small work-around to prevent a react-bootstrap warning that 'focus' should be added for touch and keyboard only users.
            placement={placement || 'right'}
            overlay={
                <Tooltip>
                    {tooltip}
                </Tooltip>
            }
        >
            <Button onClick={onClick} color="primary" size="sm" className={className}>
                <FontAwesomeIcon icon="edit" />
            </Button>
        </OverlayTrigger>        
    )     
}