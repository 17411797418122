import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export const WarningIconWithTooltip = ({ className, show = true, iconSize = "40px", tooltip }) => {
    const style = {
        width: iconSize,
        height: iconSize
    }

    if (!show) {
        return <div style={style}/>
    }
    
    return (
        <OverlayTrigger
            trigger={['hover', 'hover']} // Small work-around to prevent a react-bootstrap warning that 'focus' should be added for touch and keyboard only users.
            placement='right'
            overlay={
                <Tooltip>
                    {tooltip}
                </Tooltip>
            }
        >
            <img style={style} src='./images/warningIcon.png' alt='Warning icon' className={className}/>
        </OverlayTrigger>
    )
}