import { Component } from 'react';
import { DistributorReportCell } from './DistributorReportCell';
import { DistributorReportLicenseValue } from './DistributorReportLicenseValue';

import css from './DistributorReport.module.scss';

export class DistributorReportLine extends Component {
    render() {
        const reportLine = this.props.reportLine;
        const showDetails = this.props.showDetails;
        const disableMarking = reportLine.enabled ? "" : css.tableCellOrange

        const detailColumns = showDetails ? 
            <>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.tenantId}
                </td>
                <td className={`align-middle ${disableMarking} ${css.tableCellAlignCenter}`}>
                {reportLine.enabled ? "Yes" : "No"}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.emailAddress}
                </td>
            </>
            : null;

        return (
            <tr height="70px">
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.tenantLabel}
                </td>
                {detailColumns}
                <td className={`align-middle ${css.tableCellAlignCenter}`}>
                    {reportLine.creationTime.split('T')[0]}
                </td>
                <td className={`align-middle ${css.tableCellAlignCenter}`}>
                    <DistributorReportLicenseValue licensesMax={reportLine.licensesMax} licensesInUse={reportLine.licensesInUse}/>
                </td>
                {reportLine.months.map((value, index) => {
                    return <DistributorReportCell key={index} reportLine={reportLine} month={value} />
                })}  
            </tr>
        )
    }
}