import { Component } from 'react';
import { DistributorReportLine } from './DistributorReportLine'; 
import { DistributorTotalReportLine } from './DistributorTotalReportLine'; 

import css from './DistributorReport.module.scss';

export class DistributorReportLines extends Component {
    render() {
        const months = this.props.months;
        const reportLines = this.props.reportLines;
        const totalMaxLicenses = this.props.totalMaxLicenses;
        const totalInUseLicenses = this.props.totalInUseLicenses;
        const monthLicenses = this.props.monthLicenses;
        const showDetails = this.props.showDetails;

        const detailColumns = showDetails ? 
            <>
                <th scope="col" className="align-middle">Tenant ID</th>
                <th scope="col" className={`align-middle ${css.tableCellAlignCenter}`}>Enabled</th>
                <th scope="col" className="align-middle">E-mail</th>
            </>
            : null;

        return (
            <div className={css.tableFixHeadFoot}>
                <table className="table table-striped table-bordered my-2">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" className="align-middle">Tenant</th>
                            {detailColumns}
                            <th scope="col" className={`align-middle ${css.tableCellAlignCenter}`}>Created</th>
                            <th scope="col" className={`align-middle ${css.tableCellAlignCenter}`}>Licenses</th>
                            {months.map((value, index) => {
                                return <th key={index} className={`align-middle ${css.tableCellAlignCenter}`} scope="col">
                                        {value}
                                    </th>
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        {reportLines.map((value, index) => {
                            return <DistributorReportLine showDetails={showDetails} key={index} reportLine={value} />
                        })}
                    </tbody>
                    <tfoot>
                        <DistributorTotalReportLine key='Total' showDetails={showDetails} totalMaxLicenses={totalMaxLicenses} totalInUseLicenses={totalInUseLicenses} monthLicenses={monthLicenses} />
                    </tfoot>
                </table>           
            </div>
        )
    }
}