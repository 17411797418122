import { WhitelistRange } from '../logic/apiCalls';
import { ip2int } from './misc';

export function entryCount(whiteList : WhitelistRange[]) : number {
    let count = 0;

    for (const entry of whiteList) {
        count += entry.ipAddressStart === entry.ipAddressEnd ? 1 : ip2int(entry.ipAddressEnd) - ip2int(entry.ipAddressStart) + 1
    }

    return count;
}

export function rangeOverlaps(startAddress : string, endAddress : string, whiteList : WhitelistRange[]) : boolean {


    const newEntryStart = ip2int(startAddress);

    let newEntryEnd  = newEntryStart;
    
    if (endAddress) {
        newEntryEnd = ip2int(endAddress);
    }

    for (const currentEntry of whiteList) {
        const currentEntryStart = ip2int(currentEntry.ipAddressStart);
        const currentEntryEnd = ip2int(currentEntry.ipAddressEnd);

        if (currentEntryStart <= newEntryEnd && currentEntryEnd >= newEntryStart) {
            return true;
        }
    }

    return false;
}