import { Component } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export class DistributorReportLicenseValue extends Component {
    getLabelWithFormat = (value) => {
        return this.props.isBold ? <b>{value}</b> : <span>{value}</span>;
    }

    getLabelWithTooltip = (value, tooltip) => {
        return <>
            <OverlayTrigger 
                placement='right'
                overlay={
                    <Tooltip>
                        {tooltip}
                    </Tooltip>
                }
            >
                {this.getLabelWithFormat(value)}
            </OverlayTrigger>
        </>;
    }

    render() {
        const licensesMax = this.props.licensesMax;
        const licensesInUse = this.props.licensesInUse;

        const maxValue = licensesMax != null ? this.getLabelWithTooltip(licensesMax, "Maximum licenses") : null; 
        const inUseValue = licensesMax != null ? this.getLabelWithTooltip(licensesInUse, "Licenses in use") : this.getLabelWithFormat(licensesInUse); 
        
        return maxValue === null ?
            <>
                {inUseValue}
            </> :
            <>
                {this.getLabelWithFormat(<>{maxValue} / {inUseValue}</>)}
            </>;
    }
}