import { Component, useContext } from 'react';
import { ChartLine } from './ChartLine';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import * as apiCalls from '../logic/apiCalls';
import * as chartLogic from '../logic/chartLogic';

export class GlobalStatisticsChartsInternal extends Component {
    state = {
        isLoading: true,
    }

    constructor(props) {
        super(props);

        this.licensesTotal = {};
        this.licensesDelta = {};
    }

    async componentDidMount() {
        this.getStatisticsReport();
    }

    getStatisticsReport = async () => {
        try {
            console.log(`[getStatisticsReport] Invoking the back-end API to retrieve the statistics report.`);

            const statisticsReport = await apiCalls.getStatisticsReport(this.props.authenticationContext);

            if (statisticsReport)
            {
                let statistics = [];

                statisticsReport.months.forEach(month => {
                    statistics.push({
                        periodLabel: month.month,
                        total: month.quantity,
                        increase: month.quantityIncrease,
                        decrease: month.quantityDecrease,
                        delta: month.quantityIncrease - month.quantityDecrease
                    })
                });

                this.licensesTotal = chartLogic.getLicensesTotal(statistics);
                this.licensesDelta = chartLogic.getLicensesDelta(statistics);

                this.setState({
                    isLoading: false,
                });
            }
        }
        catch (err) {
            console.log(`[getStatisticsReport] Error retrieving statistics report: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                     
        }
    }

    render() {
        return (
            <>
                <h5>Licenses count total</h5>
                <ChartLine data={this.licensesTotal.data} keyName="date" lines={this.licensesTotal.definitions} limits={this.licensesTotal.limits} isLoading={this.state.isLoading} />
                <h5>Licenses count delta</h5>
                <ChartLine data={this.licensesDelta.data} keyName="date" lines={this.licensesDelta.definitions} isLoading={this.state.isLoading} />
            </>
        )
    }
}

export const GlobalStatisticsCharts = (props) => {
    const authenticationContext = useContext(AuthenticationContext);   
    const notificationContext = useContext(NotificationContext);
  
    return (
        <GlobalStatisticsChartsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}
