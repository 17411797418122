import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, CustomInput } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

class ModalTrunkAddOrUpdateInternal extends Component {
    state = {
        sipTrunkId: "",
        name: "",
        autoAddEnabled: true,
    }

    constructor(props) {
        super(props);

        // Build the description validation Regex.
        let backslashRegex = "\\\\"; // The parsing of the literal string will reduce this to 2 backslashes, the parsing of this string by the RegExp class will reduce it to a single literal backslash to be matched.
        let whiteSpaceRegex = "\\s";    
        let dashRegex = "\\-";
        let additionalCharsRegex = "_@.()";
        // Adding these characters directly in the literal string as unicode characters did not work in production. Still unclear what caused this.
        let attributedCharStart = String.fromCharCode(192); // À char
        let attributedCharEnd = String.fromCharCode(382); // ž char
        
        this.nameValidationRegex = new RegExp(`^[0-9a-zA-Z${attributedCharStart}-${attributedCharEnd}${backslashRegex}${whiteSpaceRegex}${dashRegex}${additionalCharsRegex}]+$`);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const isUpdate = this.props.isUpdate;

        this.setState(
            {
                sipTrunkId: isUpdate ? this.props.trunkToUpdate.sipTrunkId : "",
                name: isUpdate ? this.props.trunkToUpdate.name : "",
                autoAddEnabled: isUpdate ? this.props.trunkToUpdate.autoAddEnabled : true,
            }
        );
    }

    handleDoneButtonClicked = () => {
        const trimmedName = this.state.name.trim();

        const doneInfo = {
            sipTrunkId: this.state.sipTrunkId,
            name: trimmedName,
            autoAddEnabled: this.state.autoAddEnabled,
            isUpdate: this.props.isUpdate
        }

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.props.onHide();
    }

    handleNameChanged = (e) => {
        const updatedName = e.target.value;

        if (updatedName.length > 0 && !this.nameValidationRegex.test(updatedName)) {
            return;
        }
                
        this.setState({
            name: updatedName
        });
    }

    toggleAutoAddEnabled = () => {
        this.setState({
            autoAddEnabled: !this.state.autoAddEnabled
        });
    }

    getAddOrUpdateButtonTooltip = () => {
        if (!this.props.isOpen) {
            return null;
        }

        if (this.state.name === '') {
            return "Trunk must have name";
        }

        if (this.state.name.length > 256) {
            return "Name must be less than 256 characters";
        }

        if (!this.props.isUpdate) {
            return null;
        }

        if (!this.props.trunkToUpdate) {
            return null;
        }

        if (this.state.name !== this.props.trunkToUpdate.name || 
            this.state.autoAddEnabled !== this.props.trunkToUpdate.autoAddEnabled) { 
                return null;
        }

        return "No changes detected";
    }

    shouldAddOrUpdateButtonBeEnabled = () => {
        return this.getAddOrUpdateButtonTooltip() === null;
    }

    render() {
        const headerText = this.props.isUpdate ? 'Update SIP Trunk' : 'Add SIP Trunk';
        const doneButtonText = this.props.isUpdate ? 'Update' : 'Add';

        return <Modal style={{ opacity: 1 }} show={this.props.isOpen} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                    <Form.Group controlId="newTrunkName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control id="newTrunkName" name="newTrunkName" type="text" placeholder="Enter name" value={this.state.name} autoComplete="off" onChange={this.handleNameChanged} />
                        <Form.Text className="text-muted">
                            This name to identify this SIP trunk
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="newAutoAddEnabled">
                        <label className="my-1 mr-2" htmlFor="recordDeviceSwitch">Device auto discovery</label>
                        <CustomInput disabled={true} checked={this.state.autoAddEnabled} type="switch" onChange={this.toggleAutoAddEnabled} id="autoAddEnabledSwitch" z-index={0} />
                        <Form.Text className="text-muted">Devices will be automaticly be detected, created and linked to this SIP trunk when enabled.</Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddOrUpdateButtonBeEnabled()} disabledTooltip={this.getAddOrUpdateButtonTooltip()} onClick={this.handleDoneButtonClicked}>{doneButtonText}</ButtonWithDisabledTooltip>{' '}
            </Modal.Footer>
        </Modal>;
    }
}

export const ModalTrunkAddOrUpdate = (props) => {
    return (
        <ModalTrunkAddOrUpdateInternal {...props}/>
    )
}