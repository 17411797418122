import { FC } from 'react';
import { DeleteButton } from '../../../components/DeleteButton';
import { Group } from '../../../logic/apiCalls';

interface PortalAccessGroupProps {
    group : Group;
    handleDelete: (group: Group) => void;
}

export const PortalAccessGroup: FC<PortalAccessGroupProps> = ({ group, handleDelete }) => {
    return (
        <tr>
            <td className="text-left">
                <div>
                    {group.azureGroupName}
                </div>
            </td>
            <td className="text-left">
                <div>
                    {group.azureGroupId}
                </div>
            </td>
            <td className="text-left">
                <div>
                    {group.azureGroupType}
                </div>
            </td>
            <td className="align-middle">
                <DeleteButton className="float-left mr-1" tooltip="Remove Access" onClick={() => handleDelete(group)} />              
            </td>                     
        </tr>
    )
}