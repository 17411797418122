import { Component, useContext } from 'react';
import { Redirect } from 'react-router';
import { Container } from 'reactstrap';
import { AuthenticationContext, authenticationStates, authorizationStates } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { Spinner } from '../../components/Spinner';
import { marketPlaceHelper } from '../../utils/marketPlaceHelper';
import * as apiCalls from '../../logic/apiCalls';

// This component is the landing page from the redirect back from the Azure AD authentication.
class PostLoginInternal extends Component {
    confirmingLogin = false;
    confirmingLoginTimeout = null;
     
    componentWillUnmount() {
        clearTimeout(this.confirmingLoginTimeout);
    }

    confirmLogin = async () => {
        if (this.confirmingLogin) {
            return;
        }
        this.confirmingLogin = true;

        await apiCalls.confirmLogin(this.props.authenticationContext);

        this.confirmingLoginTimeout = setTimeout(() => {
            this.confirmingLogin = false;
        }, 5000);
    }

    render() {
        const authenticationState = this.props.authenticationContext.getAuthenticationState();
        
        console.log(`[PostLogin, render] Start of rendering. Authenticationstate: '${authenticationState}'`);

        if (authenticationState === authenticationStates.AUTHENTICATING) {
            console.log("[PostLogin] Authentication process in progress, showing spinner");
            return <Container><Spinner/></Container> 
        }

        if (authenticationState === authenticationStates.NOT_AUTHENTICATED) {
            console.log("[PostLogin] Context is not authenticated, redirecting to home...");

            // The user entered this location not from a redirect from the login process (the user should either be authenticated 
            // or an authentication error should have occurred.               
            return <Redirect to='/'/>
        }

        if (this.props.authenticationContext.authorizationState === authorizationStates.UNKNOWN) {
            console.log("[PostLogin] Not known yet if the tenant is signed up, showing spinner.");

            // Directly after a redirect from a successful authentication, it is not known immediately if the tenant of the user 
            // is authorized (has been signed up) to use this application. This is checked by first retrieving an API access token from Azure AD 
            // (using the ID token just received) and then verify if TenantInfo is present for this tenant. 
            // These actions are all performed automatically in the AuthenticationProvider component.
            return <Container><Spinner/></Container> 
        }

        if (this.props.authenticationContext.authorizationState === authorizationStates.UNAUTHORIZED) {
            console.log("[PostLogin] User is unauthorized, redirecting to 'unauthorized' page.");
            // The authorization is finished and this user is not authorized to use this application.
            return <Redirect to='/unauthorized'/>
        }

        // At this point we have an authenticated and authorized user...
        if (marketPlaceHelper.isMarketPlaceLogin()) {
            console.log("[PostLogin] Login from MarketPlace, redirecting to 'landingpage'.");

            const encodedMarketplaceToken = encodeURIComponent(marketPlaceHelper.getMarketplaceToken());
            const landingPageUrl = `/landingpage?token=${encodedMarketplaceToken}`;
            
            this.confirmLogin();

            return <Redirect to={landingPageUrl}/> 
        }

        // The authorization is finished and the tenant is authorized to use this application.
        console.log("[PostLogin] Normal login, redirecting to 'portal'.");

        if (this.props.tenantContext.isLoading) {
            return <Container><Spinner/></Container>;
        }

        this.confirmLogin();

        if (this.props.tenantContext.isDistributor) {
            if (this.props.tenantContext.isReseller) {
                return <Redirect to='/portal/reseller-settings'/>;
            } else {
                return <Redirect to='/portal/distributor-settings'/>;
            }
        }
        return <Redirect to='/portal/license-overview'/>;
    }
}

export const PostLogin = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <PostLoginInternal authenticationContext={authenticationContext} tenantContext={tenantContext} {...props}/>
    )
}