declare global {
    interface Array<T> {
        unique(): void;
        sortOnField(fieldSelector: (item: any) => string, comparer: (a: string, b: string) => number): Array<T>
    }
}

Array.prototype.unique = function () {
    let arr: any[] = [];
    for (let i = 0; i < this.length; i++) {
        if (!arr.includes(this[i])) {
            arr.push(this[i]);
        }
    }

    return arr;
}

Array.prototype.sortOnField = function (fieldSelector: (item: any) => string, comparer: (a: string, b: string) => number) {
    return this.sort((a, b) => comparer(fieldSelector(a), fieldSelector(b)));
}

export { }