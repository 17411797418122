import { Component, createContext } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import * as Misc from '../utils/misc';

import css from './NotificationContext.module.scss';

export const NotificationContext = createContext();
const notificationKeyBase = 'notification';

class NotificationProviderInternal extends Component {

    constructor() {
        super();

        this.state = {
            notifications: [],
            communicationFailureNotification: null,
            setNotification: (headerText, bodyText, icon) => {
                this.setNotification(headerText, bodyText, icon);
            },
            setCommunicationFailureNotification: () => {
                this.setCommunicationFailureNotification();
            }
        }
    }

    setNotification = (headerText, bodyText, icon) => {
        const id = Misc.generateRandomString(16);
        const key = `${notificationKeyBase}_${id}`;
        
        const notification = {
            key: key,
            headerText: headerText,
            bodyText: bodyText,
            icon
        }

        if (icon === "success") { // Success notfication have an auto clear.
            notification.timeoutTimer = setInterval(() => this.clearNotification(key), 5000);
        }

        this.setState({ notifications: [...this.state.notifications, notification] })
    }

    clearNotification = (key) => {
        const notificationToClear = this.state.notifications.find(notification => notification.key === key);
        if (notificationToClear.timeoutTimer) {
            clearInterval(notificationToClear.timeoutTimer);
        }

        this.setState({ notifications: [...this.state.notifications.filter(notification => notification.key !== key)] });
    }

    setCommunicationFailureNotification = () => {
        if (this.state.communicationFailureNotification)
        {
            console.log("[setCommunicationFailureNotification] communication failure already reported");
            return;
        }

        const id = Misc.generateRandomString(16);
        const key = `${notificationKeyBase}_${id}`;
        
        const notification = {
            key: key,
        }

        this.setState({ communicationFailureNotification: notification })
    }

    clearsetCommunicationFailureNotification = () => {
        this.setState({ communicationFailureNotification: null });
    }
    
    render() {
        const notificationsRendered = this.state.notifications.map(item => {
            return (
                <Toast key={item.key} className={css.notification} isOpen={true} position={"bottom-end"} >
                    <ToastHeader toggle={() => this.clearNotification(item.key)} icon={item.icon} >
                        {item.headerText}
                    </ToastHeader>
                    <ToastBody>
                        {item.bodyText}
                    </ToastBody>
                </Toast>
            );
        });

        const comNotify = this.state.communicationFailureNotification;

        const communicationFailureNotificationRendered = comNotify ? 
            <Toast key={comNotify.key} className={css.notification} isOpen={true} position={"bottom-end"} >
                <ToastHeader toggle={() => this.clearsetCommunicationFailureNotification()} icon="danger" >
                    Communication failure              
                </ToastHeader>
                <ToastBody>
                    Please try again later
                </ToastBody>
            </Toast> :
            null

        return (
            <NotificationContext.Provider value={this.state}>
                <div className={css.notifier}>
                    {notificationsRendered}
                    {communicationFailureNotificationRendered}
                </div>
                {this.props.children}
            </NotificationContext.Provider>
        )
    }
}

export const NotificationProvider = (props) => {
  
    return (
        <NotificationProviderInternal {...props}/>
    )
}