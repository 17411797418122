import { Component, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { Alert, Spinner } from 'react-bootstrap';

import { InfoIcon }  from './InfoIcon';
import { appConfig } from '../logic/appConfigProvider';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import * as recorderSetup from '../logic/recorderSetup';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

class TenantRecordingConfigInternal extends Component {
    static contextType = AuthenticationContext;

    state = {
        isConfirmChangeCallRecordingModalOpen: false,
        
        setupRecordingSecondsLeft: recorderSetup.getSetupRecordingSecondsLeft(),
        setupRecordingTimer: null   
    }

    async componentDidMount() {
        if (this.props.tenantInfo.callRecordingEnabled && this.state.setupRecordingSecondsLeft > 0) {
            this.setupRecordingTimerStart();
        }
    }

    showConfirmChangeCallRecordingModal = () => {
        this.setState({
            isConfirmChangeCallRecordingModalOpen: true,
        });
    };

    hideConfirmChangeCallRecordingModal = () => {
        this.setState({
            isConfirmChangeCallRecordingModalOpen: false,
        });
    };

    executeCallRecordingChange = () => {
        const wasRecordingEnabled = this.props.tenantInfo.callRecordingEnabled

        if (!wasRecordingEnabled)
        {
            console.log("[executeCallRecordingChange] Enabling recording.");
            recorderSetup.recordingEnabled(this.context.user?.tenantId);
            this.setupRecordingTimerStart();
        }
        else
        {
            console.log("[executeCallRecordingChange] Disabling recording.");
            recorderSetup.recordingDisabled(this.context.user?.tenantId);
            this.setupRecordingTimerStop();
        }

        this.props.onCallRecordingChange();
        this.hideConfirmChangeCallRecordingModal();
    };

    setupRecordingTimerStart = () => {
        this.setState({
            setupRecordingTimer: setInterval(this.handleRecordingTimer, 1000)
        });        
    }

    setupRecordingTimerStop = () => {
        clearInterval(this.state.setupRecordingTimer);
    }

    handleRecordingTimer = () => {        
        // Calculate the number of seconds left based upon the information stored in the session storage.
        let seconds = recorderSetup.getSetupRecordingSecondsLeft();

        this.setState({
            setupRecordingSecondsLeft: seconds,
        });
        
        // Check if we're at zero.
        if (seconds <= 0) { 
          clearInterval(this.state.setupRecordingTimer);
        }
    }

    secondsToTimeString = (secs) => {
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.floor(divisor_for_seconds);
    
        const zeroPad = (num, places) => String(num).padStart(places, '0')
     
        return zeroPad(hours, 2) + ":" + zeroPad(minutes, 2) + ":" + zeroPad(seconds, 2)
    }

    getEnableRecordingModal = () => {
        return <div>
            <h5>Are you sure you want to enable call recording?</h5>
            <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                <Form.Group controlId="recordingEnableNotice">
                    <Form.Label>Notice</Form.Label>
                    <Form.Text className="text-muted">
                        <ul>
                            <li><h6>Please check the applicable national and state legislation and regulations related to Call Recording before activating this feature.</h6></li>
                            <li><h6>Recordings will be stored in Microsoft Azure (West-Europe).</h6></li>
                            <li><h6>Media is securely stored for a period of 10 days.</h6></li>
                            <li><h6>Recording will start after a call is answered.</h6></li>
                        </ul>
                    </Form.Text>
                </Form.Group>
            </Form>
        </div>
    }

    render() {
        const recordingFeatureGrantedIsBeingDetermined = this.props.tenantInfo.adminConsentGrantedFeatures === "undetermined";
        const recordingFeatureGranted = this.props.tenantInfo.adminConsentGrantedFeatures.includes("recording")
        const updatedOrGranted = this.props.tenantInfo.adminConsentGrantedFeatures.includes("initial") ? "updated" : "granted"
        const recordingEnabled = recordingFeatureGranted && this.props.tenantInfo.callRecordingEnabled;
        const recorderSettingUp = this.state.setupRecordingSecondsLeft > 0;

        const spinnerStyle = {
            width: '40px',
            height: '40px',
            padding: '8px',
            display: 'inline-block'
        }

        const confirmChangeCallRecordingModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmChangeCallRecordingModalOpen} onHide={this.hideConfirmChangeCallRecordingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm {recordingEnabled ? "disable" : "enable"} recording</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recordingEnabled ?
                        <>
                            Are you sure you want to disable call recording?
                        </> :
                        this.getEnableRecordingModal()
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmChangeCallRecordingModal}>
                        Cancel
                    </Button>
                    <ButtonWithDisabledTooltip color="primary" onClick={this.executeCallRecordingChange}>
                        Confirm
                    </ButtonWithDisabledTooltip>
                </Modal.Footer>
            </Modal>
        </>

        const headerRendered = 
            <div>
                <h4>
                    Call recording <InfoIcon tooltip='This setting defines whether the CyberGate calls will be recorded.' />
                </h4>
            </div>
        
        const callRecordingButtonText = recordingEnabled ?
            "Disable call recording" :
            "Enable call recording";
        const buttonRendered = <Button className="mb-3" disabled={!recordingFeatureGranted} color="primary" onClick={this.showConfirmChangeCallRecordingModal}>{callRecordingButtonText}</Button>
        
        const defaultTextRendered = <div>
            <p>Recordings are handled by the CyberTwice cloud service called Attest. 
            {recordingEnabled ? " " : " If enabled, calls from all your devices are recorded. "}
            You can disable recording per device in the Device Settings menu.</p>
            <p>Note that the feature configuration script must have been executed for the recording to work.</p>
        </div>

        // Determine the main content.
        let contentRendered = <></>
        if (recordingFeatureGrantedIsBeingDetermined) {
            contentRendered = 
                <div>
                    <div style={spinnerStyle} className='float-none'>
                        <Spinner animation='border' role='status' size='sm' variant='secondary'/>
                    </div>
                    <p/>
                </div>
        } else if (!recordingFeatureGranted) {
            contentRendered = 
                <div>
                    <p/>
                    <p>Call recording is disabled.</p>
                    {defaultTextRendered}
                    <Alert variant='warning' transition={false}>
                        {`The call recording feature can only be enabled after the admin constent is ${updatedOrGranted}.`}
                    </Alert>
                </div>
        } else if (recordingEnabled) {
            if (recorderSettingUp) {
                contentRendered = 
                    <div>
                        <p/>                        
                        {defaultTextRendered}
                        <Alert variant='warning' transition={false}>
                            <p>Configuring Attest to enable CyberGate recording <div style={spinnerStyle} className='float-none'><Spinner animation='border' role='status' size='sm' variant='secondary' /></div></p>
                            
                            <p>Time left: {this.secondsToTimeString(this.state.setupRecordingSecondsLeft)}</p>
                        </Alert>
                    </div>
                    
            } else {
                contentRendered = 
                    <div>
                        <p/>
                        <p>Call recording is enabled.</p>
                        {defaultTextRendered}                        
                        <Alert variant='warning' transition={false}>
                            <div>
                                <p>Attest links:</p>
                                <ul>
                                    <li><a href={appConfig.attestReplayUrl} target='_blank' rel="noopener noreferrer">ATTEST Replay</a><span> - Search and replay recorded calls.</span></li>
                                    <li><a href={appConfig.attestAdminUrl} target='_blank' rel="noopener noreferrer">Management portal</a><span> - View and modify replay access rights.</span></li>
                                    <li><a href={appConfig.attestReplayTeamsStoreUrl} target='_blank' rel="noopener noreferrer">ATTEST Replay Teams app</a><span> - Install the ATTEST Replay Teams app to search and replay recorded calls within Teams.</span></li>
                                </ul>
                            </div>
                        </Alert>
                    </div>
            }
        } else {
            contentRendered = 
                <div>
                    <p/>
                    <p>Call recording is disabled.</p>
                    {defaultTextRendered}
                </div>
        }
        
        const callRecordingSectionRendered =  
            <div>
                {headerRendered}                          
                {contentRendered}
                {buttonRendered}
            </div>

        return (
            <div>
                {confirmChangeCallRecordingModalRendered}
                {callRecordingSectionRendered}
            </div>
            );
    }
}

export const TenantRecordingConfig = (props) => {
    const authenticationContext = useContext(AuthenticationContext);   
    const notificationContext = useContext(NotificationContext);
  
    return (
        <TenantRecordingConfigInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}