import { Component } from 'react';
import { CallGroupWithSupervisors } from './CallGroupWithSupervisors'

import css from './CallGroupsWithSupervisors.module.scss';

export class CallGroupsWithSupervisors extends Component {
    render () 
    {
        return (
            <div>
                <table className="table table-striped table-bordered my-2">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Group</th>
                            <th scope="col">Type</th>
                            <th scope="col">SIP address</th>
                            <th scope="col">Supervisor(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={css.tableFixDummyRow} >{/* Dummy row to start with white row first */}</tr> 
                        {this.props.callGroups.map((callGroup) =>
                            <CallGroupWithSupervisors 
                                key={callGroup.callGroupId} 
                                callGroup={callGroup}
                                verifiedDomains={this.props.verifiedDomains}
                                onUpdateSupervisors={this.props.onUpdateSupervisors}
                            />
                        )}
                    </tbody>
                </table>           
            </div>
        );
    }
}