import { Component } from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { InfoIcon }  from './InfoIcon';

export class TenantSecurityPoliciesConfig extends Component {

    state = {
        isConfirmChangeSecureOnlyPolicyModalOpen: false,
    }

    async componentDidMount() {
    }

    showConfirmChangeSecureOnlyPolicyModal = () => {
        this.setState({
            isConfirmChangeSecureOnlyPolicyModalOpen: true,
        });
    };

    hideConfirmChangeSecureOnlyPolicyModal = () => {
        this.setState({
            isConfirmChangeSecureOnlyPolicyModalOpen: false,
        });
    };

    executeSecureOnlyPolicyChange = () => {
        const wasSecureOnlyEnabled = this.props.tenantInfo.secureOnlyPolicyEnabled

        if (wasSecureOnlyEnabled)
        {
            console.log("[executeSecureOnlyPolicyChange] Disabling secure-only policy.");
        }
        else
        {
            console.log("[executeSecureOnlyPolicyChange] Enabling secure-only policy.");
        }

        this.props.onSecureOnlyPolicyChange(!wasSecureOnlyEnabled);
        this.hideConfirmChangeSecureOnlyPolicyModal();
    };

    render() {
        const secureOnly = this.props.tenantInfo.secureOnlyPolicyEnabled;

        const confirmChangeSecureOnlyPolicyModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmChangeSecureOnlyPolicyModalOpen} onHide={this.hideConfirmChangeSecureOnlyPolicyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm {secureOnly ? "disable" : "enable"} secure-only policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {secureOnly ?
                        <>
                            <p>Are you sure you want to disable the secure-only policy?</p>
                            <p>This will allow devices to connect with the unsecure protocols TCP/UDP and to use unencrypted audio/video. Connections using TLS 1.2 are still possible, but no longer enforced.</p>
                        </> :
                        <>
                            <p>Are you sure you want to enable the secure-only policy?</p>
                            <p>This will enforce all devices to use TLS 1.2 and use encrypted audio/video.</p>
                            <Alert variant='warning' transition={false}>Warning: Devices that currently communicate over UDP and TCP will stop functioning when secure-only is enabled.</Alert>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmChangeSecureOnlyPolicyModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.executeSecureOnlyPolicyChange}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        const headerRendered = 
            <div>
                <h4>
                    Security policies <InfoIcon tooltip='Set the security policies for your tenant.' />
                </h4>
            </div>

        const secureOnlyPolicyStatus = secureOnly ?
            "Secure-only policy is enabled" :
            "Secure-only policy is disabled";
        
        const securityPolicyButtonText = secureOnly ?
            "Disable secure-only policy" :
            "Enable secure-only policy";
        const buttonRendered = <Button className="mb-3" color="primary" onClick={this.showConfirmChangeSecureOnlyPolicyModal}>{securityPolicyButtonText}</Button>
        
        // Determine the main content.
        let contentRendered = <div>
            <p/>
            <p>{secureOnlyPolicyStatus}</p>
            <p>The secure-only policy will enforce secure SIP communication using TLS 1.2 and encrypted audio/video for all the devices you connect to CyberGate. When enabled, connecting over UDP / TCP to CyberGate will not be possible anymore, only SIP TLS and SRTP will be allowed. Check if your intercom is certified to use SIP TLS and SRTP with CyberGate (<a href='https://www.cybertwice.com/compatibility-list' target='_blank' rel="noopener noreferrer">Compatibility list</a>) and configure your intercom using the manual listed.</p>
            <p>When the policy is disabled, devices can communicate using both secure SIP TLS and unsecure UDP / TCP, as well as use encrypted and unencrypted audio/video.</p>
        </div>
        
        const securityPolicySectionRendered =  
            <div>
                {headerRendered}                          
                {contentRendered}
                {buttonRendered}
            </div>

        return (
            <div>
                {confirmChangeSecureOnlyPolicyModalRendered}
                {securityPolicySectionRendered}
            </div>
            );
    }
}