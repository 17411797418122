import React, { useContext } from 'react';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { TenantContext } from '../context/TenantContext';
import {MobileMenu} from '../components/menu/MobileMenu';

import css from './UserLogin.module.scss'

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

export const UserLogin = ({isTopMenuOpen}) => {
    const authenticationInfo = useContext(AuthenticationContext);
    const tenantContext = useContext(TenantContext);
       
    const username = authenticationInfo.user ? authenticationInfo.user.username : "Unknown";
    const displayName = authenticationInfo.userIdTokenClaims ? authenticationInfo.userIdTokenClaims.name : username;

    return (
        <UncontrolledDropdown>
            <DropdownToggle className={css.userInfo} nav caret>
                {displayName} <br />
                {tenantContext.tenantLabel}
            </DropdownToggle>
            <DropdownMenu className={css.loginDropdown} right>
                <DropdownItem header >
                    <strong>{username}</strong>
                    <p className="text-muted mb-0">{displayName}</p>
                    <p className="text-muted mb-0">{tenantContext.tenantLabel}</p>
                </DropdownItem>
                <DropdownItem divider />
                {isTopMenuOpen && <MobileMenu />}
                <DropdownItem onClick={authenticationInfo.logout}>Sign Out</DropdownItem>
             </DropdownMenu>
        </UncontrolledDropdown>
    )
}
