import React from 'react';
import { DeleteButton } from './DeleteButton';
import { CopyButton } from './CopyButton';
import { EditButton } from './EditButton';

import css from './DeviceEntry.module.scss';

export const TrunkEntry = ({ tenant, trunk, handleDelete, handleEdit }) => {
    const copyToClipboard = (valueToCopy) => {
        navigator.clipboard.writeText(valueToCopy);
    }

    return (
        <tr>
            <td>
                <div className={css.flexContainer}>
                    <div>
                        {trunk.name}
                    </div>
                </div>
            </td>
            <td className="align-middle">
                <div className={css.flexContainer}>
                    <div>
                        {trunk.username}
                    </div>
                    <div className={css.flexItemRight}>
                        <CopyButton className="float-right" tooltip="Copy username" onClick={() => copyToClipboard(trunk.username)}/>
                    </div>
                </div>          
            </td>
            <td className="align-middle">
                <div className={css.flexContainer}>
                    <div>
                        {trunk.password.substring(0, 3)} ●●●●●●●●●●
                    </div>
                    <div className={css.flexItemRight}>
                        <CopyButton className="float-right" tooltip="Copy password" onClick={() => copyToClipboard(trunk.password)}/>
                    </div>
                </div>      
            </td>
            <td className="align-middle">
                {trunk.autoAddEnabled ? "Enabled" : "Disabled"}
            </td>    
            <td className="align-middle">
                {trunk.deviceCount}
            </td>    
            <td className="align-middle">
                <DeleteButton className="float-right mr-1" tooltip="Delete" onClick={() => handleDelete(trunk)} />
                <EditButton className="float-right mr-1" tooltip="Edit" onClick={() => handleEdit(trunk)} />                
            </td>
        </tr>
    )
}