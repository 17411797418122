export function forceDownload(blobUrl, filename) {
    const a = document.createElement('a');

    a.download = filename;
    a.href = blobUrl;

    document.body.appendChild(a);// For Firefox https://stackoverflow.com/a/32226068

    a.click();
    a.remove();
}

export function triggerDownloadBlob(blob, filename) {
    let blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename);
}