import React, { createContext } from 'react';
import { msalConfig } from './MsalConfig';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { getOrganizationDetails } from '../graph/GraphService';
import * as Utils from '../utils/helper';
import { ErrorHandlingContext } from '../context/ErrorHandlingContext';
import { TokenRetrievalError } from '../utils/errors';
import { marketPlaceHelper } from '../utils/marketPlaceHelper';
import { appConfig } from '../logic/appConfigProvider';
 
const isProvidingAdminConsentKey = 'isProvidingAdminConsent';
const adminConsentStateKey = 'adminConsentState';
const userIdTokenClaimsKey = 'userIdTokenClaimsKey';

const cybergateBotAppId = appConfig.azureActiveDirectoryClientId;

let msalAuth;

// Enum defines the possible states of the authenticationState property of the state of the AuthenticationProvider.
export const authenticationStates = {
    NOT_AUTHENTICATED: 'NotAuthenticated', // No user is currently logged-in
    AUTHENTICATING: 'Authenticating', // The redirect back from Microsoft has taken place, but the process of exchanging the authentication code for the tokens is still in progress.
    AUTHENTICATED: 'Authenticated', // The user is successfully logged in. The first time this state is reached, the check for the authorization will be started.    
}

// Enum defines the possible states of the authorizationState property of the state of the AuthenticationProvider.
export const authorizationStates = {
    UNKNOWN: 'Unknown', // The authorization has not been checked. This check is triggered when the authentication state reaches the AUTHENTICATED state.
    AUTHORIZED: 'Authorized', // The currently logged-in user is authorized to use this application. This means that the tenant has been signed up. No user-based checks are currently performed.
    UNAUTHORIZED: 'Unauthorized' // The currently logged-in user uis not authorized to use this application.
}

try {
    console.log("[AuthenticationContext] Creating PublicClientApplication instance...")

    msalAuth = new PublicClientApplication({
        auth: msalConfig
    });  

    console.log("[AuthenticationContext] PublicClientApplication instance created.")     
}
catch (err) {
    // Previous versions of the MSAL library threw an exception during the construction when an invalid token was provided in the URL. 
    // Testing showed that this no longer happens, but this exception handler is kept as a safety net.
    console.error(err);        
    throw new Error("Could not initialize required MSAL library. See console log for details."); // Not possible to proceed without a PublicClientApplication instance. The redirect will show the details of the error that occurred.
}

/// Method returns the user account object of the logged-in user or null if the user is not logged-in.
function getUserAccount() {
    const currentAccounts = msalAuth.getAllAccounts();    

    if (!currentAccounts || currentAccounts.length === 0) {        
        return null;
    } 
    
    if (currentAccounts.length === 1) {
        return currentAccounts[0];
    }
   
    // More than one user signed in. Select the user account of the user that has logged-in last using the getAccountByUsername(username) method.
    console.log(`[getUserAccount] getAllAccounts returned ${currentAccounts.length} accounts.`)

    let accountObj = null;

    // Use the last persisted token claims to get the correct user. 
    const userIdTokenClaims = retrieveUserIdTokenClaims();
    if (userIdTokenClaims) {
        const username = userIdTokenClaims.preferred_username;
        console.log(`[getUserAccount] Selecting the user account by username '${username}'.`);
        accountObj = msalAuth.getAccountByUsername(username);
    }
    
    if (!accountObj) {
        // No persisted token claims found or no user matched with the username, simply select the first account.
        console.log(`[getUserAccount] Selecting the first account.`);
        accountObj = currentAccounts[0];
    }

    return accountObj;
}

/// Method returns the current authentication state which is either NOT_AUTHENTICATED, AUTHENTICATING or AUTHENTICATED.
function getAuthenticationState() {
    if (getUserAccount()) {
        return authenticationStates.AUTHENTICATED; 
    }

    if (msalAuth.interactionInProgress()) {
        return authenticationStates.AUTHENTICATING;
    }   
    
    return authenticationStates.NOT_AUTHENTICATED;
}

// Method stores the provided user Id token claims in the session storage.
// These user Id token claims are received in the redirect callback of the MSAL library and are not part of the account information and cannot be retrieved from the 
// MSAL library. For this reason these claims are persisted in the session storage so they can be used when the page is refreshed to set the initial state of the 
// authenticationContext correctly.
function persistUserIdTokenClaims(userIdTokenClaims) {
    const serialized = JSON.stringify(userIdTokenClaims);
    sessionStorage.setItem(userIdTokenClaimsKey, serialized);
}

// Method retrieves the persisted user Id token claims from the session storage.
// In case no user is logged-in, null is returned.
function retrieveUserIdTokenClaims() {
    const serialized = sessionStorage.getItem(userIdTokenClaimsKey);

    if (!serialized) {
        return null;
    }

    const userIdTokenClaims = JSON.parse(serialized);
    return userIdTokenClaims;
}

// Method clears all information that is persisted in the session storage by this module.
function clearSessionStorage() {    
    marketPlaceHelper.clearMarketPlaceLogin();
    sessionStorage.removeItem(isProvidingAdminConsentKey);
    sessionStorage.removeItem(userIdTokenClaimsKey);
}

function isEmptyOrSpaces(str) {
    return !str || typeof str !== "string" || str.match(/^ *$/) !== null;
}

// Method performs a best-effort attempt to extract the organization name
// from the provided username.
function extractOrganizationNameFromUsername(fullName) {
    if (isEmptyOrSpaces(fullName)) {
        return "";
    }

    const atSignPosition = fullName.indexOf('@');
    if (atSignPosition === -1) {
        // This does not look like an e-mail address, return the full string instead.
        return fullName;
    }

    // Extract everything after the '@' sign.
    const fullDomain = fullName.substring(atSignPosition + 1);

    // The default domain name when an Azure account is created is '<customername>.onmicrosoft.com'.
    // When this pattern is recognized, the 'customername' part is extracted and returned.
    const microsoftDefaultDomain = '.onmicrosoft.com';
    if (fullDomain.toLowerCase().endsWith(microsoftDefaultDomain)) {
        return fullDomain.substring(0, fullDomain.length - microsoftDefaultDomain.length);
    }

    // The customer has added its custom domain to its Azure account, so the fullDomain contains 
    // a domain like 'contoso.com'. Try to remove the top-level domain ('.com') and return the result.
    const lastDotPosition = fullDomain.lastIndexOf('.');
    if (lastDotPosition === -1) {
        // No top-level domain found, return the full domain.
        return fullDomain;
    }

    // Return everything up to the last dot.
    return fullDomain.substring(0, lastDotPosition);
}

export const AuthenticationContext = createContext();


const defaultScope = msalConfig.clientId + '/.default';

export class AuthenticationProvider extends React.Component {
    static contextType = ErrorHandlingContext;

    constructor(props) {
        super(props);

        let userAccount = getUserAccount();

        this.state = {
            getAuthenticationState: () => { return getAuthenticationState() },
            authorizationState: authorizationStates.UNKNOWN,
            detailedAuthorizationState: {},
            isAuthenticated: () => { return this.state.getAuthenticationState() === authenticationStates.AUTHENTICATED},
            isAuthorized: () => { return this.state.authorizationState === authorizationStates.AUTHORIZED },            
            user: userAccount,
            userIdTokenClaims: retrieveUserIdTokenClaims(),            
            login: () => { 
                clearSessionStorage();                

                const accessTokenRequest = {
                    scopes: [defaultScope],                    
                    prompt: "select_account"
                }

                msalAuth.loginRedirect(accessTokenRequest);
            },
            logout: () => {
                clearSessionStorage();

                msalAuth.logout();
            },
            loginFromMarketplace: () => {
                clearSessionStorage();

                marketPlaceHelper.setMarketPlaceLogin();
                
                const accessTokenRequest = {
                    scopes: [defaultScope],                    
                    prompt: "select_account"
                }

                msalAuth.loginRedirect(accessTokenRequest);                
            },
            redirectToAdminConsent: () => {
                clearSessionStorage();

                const redirectUri = document.getElementById('root').baseURI + 'post-consent-spa';
                const state = "LDRDEMF27QEVFT6UYI9J" // ToDo: Generate a random string.
                
                sessionStorage.setItem(isProvidingAdminConsentKey, true);
                sessionStorage.setItem(adminConsentStateKey, state);
                const adminConsentUri = this.constructAdminConsentUrl(redirectUri, state);

                window.location.href = adminConsentUri;
            },
            getApiTokenAsync: async () => {
                const accessTokenRequest = {
                    scopes: [msalConfig.apiScope],
                    account: this.state.user
                }

                try {
                    console.log("[AuthenticationProvider, getApiTokenAsync] getApiTokenAsync invoked...");

                    const accessToken = await msalAuth.acquireTokenSilent(accessTokenRequest);               

                    console.log("[AuthenticationProvider, getApiTokenAsync] Accesstoken for back-end API successfully retrieved!");
                    //console.log(JSON.stringify(accessToken.accessToken))

                    return accessToken;
                }
                catch (err) {
                    console.log(`[AuthenticationProvider, getApiTokenAsync] Error occurred while retrieving token for back-end API: ${err}`);

                    if (err instanceof InteractionRequiredAuthError) {
                        console.log("[AuthenticationProvider, getApiTokenAsync] Starting interactive attempt with redirect...");
                        msalAuth.acquireTokenRedirect(accessTokenRequest);                        
                        return;
                    } 
                    
                    throw new TokenRetrievalError(err, "Error occurred while retrieving token for back-end API");                   
                }
            },
            getGraphApiTokenAsync: async () => {
                const accessTokenRequest = {
                    scopes: [msalConfig.graphApiScope],
                    account: this.state.user
                }

                try {
                    console.log("[AuthenticationProvider, getGraphApiTokenAsync] getGraphApiTokenAsync invoked...");
        
                    const accessToken = await msalAuth.acquireTokenSilent(accessTokenRequest);
                    console.log("[AuthenticationProvider, getGraphApiTokenAsync] Accesstoken for the Graph API successfully retrieved!");
                    //console.log(accessToken.accessToken);

                    return accessToken;
                }
                catch (err) {
                    console.log(`[AuthenticationProvider, getGraphApiTokenAsync] Error occurred while retrieving token for Graph API: ${err}`);

                    if (err instanceof InteractionRequiredAuthError) {
                        console.log("[AuthenticationProvider, getGraphApiTokenAsync] Starting interactive attempt with redirect...");
                        msalAuth.acquireTokenRedirect(accessTokenRequest);
                        return;
                    }                  
                    
                    throw new TokenRetrievalError(err, "Error occurred while retrieving token for Graph API");    
                }
            }
        };
    }        

    // Method ensures the authorizationState is set to UNKNOWN and the persisted cached value is cleared.
    clearAuthorizationState = () => {
        // Ensure the current authorization state is UNKNOWN.
        if (this.state.authorizationState !== authorizationStates.UNKNOWN) {
            console.log(`[AuthenticationProvider, clearAuthorizationState] User not authenticated, setting authorizationState to UNKNOWN. Current state: ${this.state.authorizationState}`); 
            this.setState({
                authorizationState: authorizationStates.UNKNOWN,
                detailedAuthorizationState: {}
            });
        }         

        return;
    }

    // Method determines and sets the authorizationState property of the state. 
    // In case no user is logged-in, the state will be set to UNKNOWN.
    // In case a user is logged-in:
    //  - If this user was redirected from the Marketplace, the tenant is added to the administration in the back-end and the state is set to AUTHORIZED. 
    //  - Else perform the actual check in the back-end if this user is authorized. This either returns AUTHORIZED or UNAUTHORIZED.
    updateAuthorizationState = async () => {        
        
        // The authorization check should only be performed when the user is successfully logged-in.
        if (this.state.getAuthenticationState() !== authenticationStates.AUTHENTICATED) {
            // Unless the user is currently logging in, clear the authorization state.
            if (this.state.getAuthenticationState() !== authenticationStates.AUTHENTICATING) {
                this.clearAuthorizationState();
            }
            
            return;
        }

        // Verify that the organization is already signed-up and that the user is authorized (is a valid  administrator ('global', 'application', or 'cloud application')) to use this application. 
        console.log(`[AuthenticationProvider, updateAuthorizationState] User is authenticated, now checking for authorization...`);            
        let authorizationInfo = await this.checkAuthorizationOnBackend();

        // Sign-up the tenant in case the organization is not authorized, the user is authorized and the user has entered the application via the landingpage.
        // The MarketPlace has added a token in the Landingspage URL (which can be resolved at the MarketPlace to the subscription). 
        // As a safety measurement a tenant can only be added when also a valid MarketPlace token is added in the same request. This prevents 
        // random tenants can be added.
        if (!Utils.isTrue(authorizationInfo.isOrganizationAuthorized) &&
            Utils.isTrue(authorizationInfo.isUserAuthorized) &&
            marketPlaceHelper.isMarketPlaceLogin()) {                            
            console.log(`[AuthenticationProvider, updateAuthorizationState] Tenant is not authorized, user is authorized and the login was triggered by the landingpage. Creating the administration in the back-end...`);
            
            const marketPlaceToken = marketPlaceHelper.getMarketplaceToken();

            const wasSignupSuccessful = await this.signUpTenant(marketPlaceToken);                    
            if (!wasSignupSuccessful) {
                return; // Error overlay will be shown automatically.
            }

            // Perform the authorization check again on the back-end.
            authorizationInfo = await this.checkAuthorizationOnBackend();
        }

        const authorizationState = Utils.isTrue(authorizationInfo.isOrganizationAuthorized) && Utils.isTrue(authorizationInfo.isUserAuthorized) ? 
                                    authorizationStates.AUTHORIZED : 
                                    authorizationStates.UNAUTHORIZED;
        console.log(`[AuthenticationProvider, updateAuthorizationState] Authorization state: ${authorizationState}`);    
                
        this.setState({
            authorizationState: authorizationState,
            detailedAuthorizationState: authorizationInfo                
        });
    }
    
    // Method registers the callback which is invoked when the callback is registered and when the authentication using the redirect flow has finished. 
    // Note that this callback is not invoked immediately when the application is restarted after the redirect back from the Microsoft login 
    // (as with the implicit flow), but when the received authentication_code has been exchanged for an id_token and an acces_token.
    registerMsalRedirectCallback = () => {      
        msalAuth.handleRedirectPromise().then(async (tokenResponse) => {
            if (tokenResponse === null) {
                console.log(`[AuthenticationProvider, handleRedirectPromise] No change in logged-in state of user.`);
                this.updateAuthorizationState();
                return;        
            }            

            console.log(`[AuthenticationProvider, handleRedirectPromise] User '${tokenResponse.account.username}' successfully logged in.`);

            persistUserIdTokenClaims(tokenResponse.idTokenClaims);            
            this.setState({
                user: tokenResponse.account,
                userIdTokenClaims: tokenResponse.idTokenClaims                
            });

            this.updateAuthorizationState();

            return;
        }).catch((error) => {
            this.context.setError("Error occurred during login.", error);
        });
    }

    async componentDidMount() {
        console.log("[AuthenticationProvider, componentDidMount] Method invoked...");

        this.registerMsalRedirectCallback();
    }   

    constructAdminConsentUrl = (redirectUri, state) => {
        const redirectUriEncoded = encodeURI(redirectUri);
        const stateEncoded = encodeURI(state);        
        const clientId = cybergateBotAppId;

        return `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&redirect_uri=${redirectUriEncoded}&state=${stateEncoded}`;
    }
    
    // Method retrieves the authorization information from the back-end.
    // Response looks like this:
    // {
    //   "isOrganizationAuthorized": true,
    //   "isUserAuthorized": true
    // }
    checkAuthorizationOnBackend = async () => {
        try {
            console.log("[AuthenticationProvider, checkAuthorizationOnBackend] Checking the authorization of the user on the back-end...");

            const accessToken = await this.state.getApiTokenAsync();

            const response = await fetch('api/tenant/authorizePortal', {
                headers: { 'Authorization': `Bearer ${accessToken.accessToken}` }
            });

            if (response.status === 200) {
                // A success response is received from the back-end. Verify the tenant is enabled.                
                const data = await response.json();

                // The response from the back-end contains a flag that indicates if the current tenant has the authorization to use this application.
                return data;
            }

            // All other response from the API are handled as an unexpected error.
            throw new Error(`Server returned unexpected status code ${response.status}`);
        }
        catch (err) {
            this.context.setError("Could not verify user authorization at the server.", err);            
        }

        return {};
    }

    // Method attempts to retrieve the organization display name for the currently logged-in user from the Graph API.
    // Testing showed that this often fails when the user has just given consent (which always happens when the 
    // tenant reaches the management portal for the first time). In that case the AccessToken for the Graph API is 
    // successfully retrieved, but does not contain the 'User.Read' scope. It takes more than 15 seconds before the 
    // consent is fully processed at Microsoft and a complete AccessToken is returned. This is too long to have 
    // the user waiting so a fallback is implemented. 
    // This fallback is to retrieve the organization name from the username using the 'extractOrganizationNameFromUsername' 
    // method.
    getOrganizationDisplayName = async () => {
        try {
            console.log("[getOrganizationDisplayName] Invoking the Graph API to get the organization name of this tenant.");

            const accessToken = await this.state.getGraphApiTokenAsync();
            var organization = await getOrganizationDetails(accessToken);

            if (organization && organization.value && organization.value.length > 0) {
                return organization.value[0].displayName;
            }

            console.log(`[getOrganizationDisplayName] Error: Could not extract the organization name from the response: ${JSON.stringify(organization)}`);
        }
        catch (err) {
            // The global error is not set here, because failure of retrieving the organization name should not 
            // break the sign-up process.
            console.log(`[getOrganizationDisplayName] Error while retrieving organization name for tenant: ${JSON.stringify(err)}`);
        }

        // In case the displayname of the tenant could not be retrieved, perform a best-effort attempt to get the 
        // organization name from the full username.
        if (getUserAccount()) {
            console.log("[getOrganizationDisplayName] Organization name could not be retrieved from the Graph API, now extracting the name from the full username.");
            return extractOrganizationNameFromUsername(getUserAccount().username);
        }

        return "Unknown";
    }

    signUpTenant = async (marketPlaceTokenValue) => {
        try {
            console.log("[AuthenticationProvider] signUpTenant invoked.");

            // Retrieve the tenant display name for the logged-in user from the Graph API. 
            // Note: With the User.Read rights (which should be granted for this app), also a limited set
            // of information about the organization can be retrieved.
            const tenantDisplayName = await this.getOrganizationDisplayName();

            // Create the (partial) TenantInfo object which is sent in a POST request to create the initial Tenant info.
            const tenantInfo = {
                tenantLabel: tenantDisplayName
            }

            const addTenantUrl = `/api/tenant?token=${encodeURIComponent(marketPlaceTokenValue)}`;

            const accessToken = await this.state.getApiTokenAsync();
            
            const response = await fetch(addTenantUrl, {
                    method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken.accessToken}`,
                    'Content-Type': 'application/json'
                },
                    body: JSON.stringify(tenantInfo)
                }
            );

            console.log(`Response code from POST request for adding tenant: ${response.status} ${response.statusText}`);     
            if (response.status >= 200 && response.status < 300) {
                return true;
            } else if (response.status === 409) {
                // The tenant already exists in the back-end. This is an exception because this method is only executed when the tenant is not authorzed,
                // which normally means the tenant does not exist in the database. One exception is however when this tenant has manually been set to disabled.
                console.log(`Tenant with Id ${getUserAccount().tenantId} already exist. Returning True as if the sign-up was successful.`);                
                return true;
            } else {
                throw new Error(`Sign-up of tenant failed with response code ${response.status}`);
            }     
        }
        catch (err) {
            this.context.setError("Error occurred during sign-up of tenant.", err);            
        }
      
        return false;
    }    

    render() {
        return (
            <AuthenticationContext.Provider value={this.state}>
                {this.props.children}
            </AuthenticationContext.Provider>
        )
    }  
}