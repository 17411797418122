var graph = require('@microsoft/microsoft-graph-client');

function getGraphClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        }
    });

    return client;
}

export async function getUserDetailsOfLoggedInUser(accessToken) {
    const client = getGraphClient(accessToken);
    return await client.api('/me').get();
}

export async function getOrganizationDetails(accessToken) {
    const client = getGraphClient(accessToken);
    return await client.api('/organization').get();
}

export async function getUserDetailsByFullname(accessToken, fullname) {
    if (!fullname || fullname.length === 0) {
        return null;
    }

    let filterForUserPrincipal = `userPrincipalName eq '${fullname}'`;
    let filterForMail = `mail eq '${fullname}'`;
    let filterForImAddress = `imAddresses/any(a: a eq '${fullname}')`;
    let filter = `${filterForUserPrincipal} OR ${filterForMail} OR ${filterForImAddress}`;
    let filterEncoded = encodeURIComponent(filter);
    let filterClause = `$filter=${filterEncoded}`;

    const client = getGraphClient(accessToken);
    return await client.api(`/users?${filterClause}`).get();
}
