import { WarningIconWithTooltip } from './WarningIconWithTooltip';
import { Spinner } from 'react-bootstrap';

// undetermined, notAssigned, notUpToDate, upToDate
export const DisplayNameIcon = ({ className, state }) => {
    const spinnerStyle = {
        width: '40px',
        height: '40px',
        padding: '8px',
        display: 'inline-block'
    }

    const showSpinner = (state === 'undetermined');
    if (showSpinner) {
        return <div style={spinnerStyle} className='float-right'><Spinner animation='border' role='status' size='sm' variant='secondary'/></div>
    }

    const showDisplayNameWarning = (state === 'notAssigned' || state === 'notUpToDate');    
    const showPlaceHolder = !showDisplayNameWarning && !showSpinner;

    // A placeholder with the same size as the spinner is rendered when nothing has to be shown to 
    // prevent changes in the table layout which looks very annoying.
    if (showPlaceHolder) {
        return <div style={spinnerStyle} className='float-right'/>;
    }
    
    const warningReasonText = showDisplayNameWarning && state === 'notUpToDate' ? 'changed' : 'not configured yet';
    const tooltip = <>
        {`Display name ${warningReasonText}. Please execute the feature configuration script. See the 'Download' button at the top of the page.`}
    </>;
    
    return (
        <WarningIconWithTooltip className={className} tooltip={tooltip}/>
    )
}