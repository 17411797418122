import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { Container, Jumbotron } from 'reactstrap';
import * as Utils from '../../utils/helper'; 

export const Unauthorized = () => {
    const authenticationInfo = useContext(AuthenticationContext);

    const isUserAuthorized = authenticationInfo.detailedAuthorizationState && Utils.isTrue(authenticationInfo.detailedAuthorizationState.isUserAuthorized);

    const renderedUserMessage = isUserAuthorized ? 
        (
            <div>
                <p><strong>Your organization has not signed up yet for the CyberGate service.</strong></p>
                <p>Click this <a href='https://azuremarketplace.microsoft.com/en-us/marketplace/apps/cybertwicebv1586872140395.cybergate'>link</a> to sign up for the CyberGate service from the Microsoft Azure Marketplace.</p>
            </div>
        ) :
        (
            <div>
                <p><strong>You are not authorized to use this application. For access you either need the 'Global', 'Application', 'Cloud Application' or 'Teams' administrator role or you must be member of a configured access group. Please contact your administrator.</strong></p>                
                <p>Please note that it can take up to 5 minutes before a group assignment in Microsoft Entra becomes effective in this portal.</p>
            </div>
        )

    return (
        <Container>
            <Jumbotron>
                {renderedUserMessage}
                <p>Click the button below to log-out and log-in with a different user account.</p>
                <Button color="primary" onClick={authenticationInfo.logout}>Logout</Button>
            </Jumbotron>
        </Container>        
    )
}