export const urls = {
    home: '/',
    license: '/portal/license-overview',    
    distributor: '/portal/distributor-settings',
    reseller: '/portal/reseller-settings',
    tenant: '/portal/tenant-settings',
    network: '/portal/network-settings',
    trunk: '/portal/trunk-settings',
    device: '/portal/device-settings',
    multiring: '/portal/multiringgroup-settings',        
    meeting: '/portal/meeting-settings',
    portalAccess: '/portal/portalaccess-settings',        
    deviceAccess: '/portal/deviceaccess-settings',        
    multiringAccess: '/portal/multiringaccess-settings',
    postLogin: 'post-login-spa'
}