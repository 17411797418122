import { useContext, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'reactstrap';
import { Button } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { NotificationContext } from '../../context/NotificationContext';
import { TenantContext } from '../../context/TenantContext';
import { DeviceEntries } from '../../components/DeviceEntries';
import { TrunkDeviceGroups } from '../../components/TrunkDeviceGroups';
import { ModalDeviceAddOrUpdate } from '../../components/ModalDeviceAddOrUpdate';
import { triggerDownloadBlob } from '../../utils/download';
import * as Utils from '../../utils/helper';
import * as Misc from '../../utils/misc';
import * as apiCalls from '../../logic/apiCalls';
import { ButtonWithDisabledTooltip } from '../../components/ButtonWithDisabledTooltip';
import { Alert } from 'react-bootstrap';

// The maximum allowed number of devices per tenant.
const maximumNumberOfDevices = 500;

class DeviceSettingsInternal extends Component {
    refreshDevicesTimer = 0;
    refreshDevicesTimeoutTimer = 0;

    state = {
        isLoading: true,
        videoSettings: {},
        devices: [],
        devicesStatus: [],
        trunks: [],
        deviceToDelete: {},
        deviceToUpdate: {},
        trunkDeviceToUpdate: {},
        addUpdateDeviceTrunk: {},
        isConfirmDeleteModalOpen: false,
        isDeviceModalOpen: false,
        isDeviceModalUpdate: false,
        isTrunkDeviceAddModalOpen: false,
        isTrunkDeviceModalUpdate: false,
    }

    async componentDidMount() {
        this.getVideoSettings();
        this.getDevices();
        this.getTrunks();
    }

    async componentWillUnmount() {
        this.refreshDevicesTimerStop();
    }

    showConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: true,
        });
    };

    hideConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: false,
        });
    };

    showAddDeviceModal = () => {
        this.setState({
            isDeviceModalUpdate: false,
            isDeviceModalOpen: true,
        });
    };

    showUpdateDeviceModal = () => {
        this.setState({
            isDeviceModalUpdate: true,
            isDeviceModalOpen: true,
        });
    };

    hideDeviceModal = () => {
        this.setState({
            isDeviceModalOpen: false,
        });
    }
    
    showTrunkAddDeviceModal = (trunk) => {
        this.setState({
            isTrunkDeviceAddModalOpen: true,
            isTrunkDeviceModalUpdate: false,
            addUpdateDeviceTrunk: trunk,
            trunkDeviceToUpdate: null,
        });
    };

    showTrunkUpdateDeviceModal = (trunk, device) => {
        this.setState({
            isTrunkDeviceAddModalOpen: true,
            isTrunkDeviceModalUpdate: true,
            addUpdateDeviceTrunk: trunk,
            trunkDeviceToUpdate: device,
        });
    };

    hideTrunkDeviceModal = () => {
        this.setState({
            isTrunkDeviceAddModalOpen: false,
        });
    }

    refreshDevicesTimerStart = () => {
        console.log("[refreshDevicesTimerStart]");

        // Clear any existing timers to prevent orphaned timers.
        clearInterval(this.refreshDevicesTimer);
        clearInterval(this.refreshDevicesTimeoutTimer);
        
        this.refreshDevicesTimer = setInterval(this.handleRefreshDevicesTimer, 10 * 1000);
        this.refreshDevicesTimeoutTimer = setInterval(this.refreshDevicesTimerStop, 2 * 60 * 60 * 1000)
    }

    refreshDevicesTimerStop = () => {
        console.log("[refreshDevicesTimerStop]");

        clearInterval(this.refreshDevicesTimer)
        clearInterval(this.refreshDevicesTimeoutTimer)
    }

    handleRefreshDevicesTimer = () => {
        this.refreshDevicesStatus();
    }

    getVideoSettings = async () => {
        try {
            console.log("[getVideoSettings] Invoking the back-end API to query if the video settings.");

            const result = await apiCalls.getVideoSettings(this.props.authenticationContext);

            this.setState({
                videoSettings: result,
            });               
        }
        catch (err) {
            console.log(`[getVideoSettings] Error querying the video settings: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();               
        }        
    }
    
    getDevices = async () => {
        try {
            console.log("[getDevices] Invoking the back-end API to get all devices of this tenant.");

            const devices = await apiCalls.getDevices(this.props.authenticationContext);

            this.setState({
                devices: devices,
            });               

            this.getDevicesStatus();
        }
        catch (err) {
            console.log(`[getDevices] Error retrieving the devices: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();               
        }
        finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    getDevicesStatus = async () => {
        try {
            const statuses = await apiCalls.getDevicesStatus(this.props.authenticationContext);

            this.setState({
                devicesStatus: statuses,
            });

            this.refreshDevicesTimerStart();
        }
        catch{}       
    }

    refreshDevicesStatus = async () => {
        try {
            const statuses = await apiCalls.getDevicesStatus(this.props.authenticationContext);

            this.setState({
                devicesStatus: statuses,
            });
        }
        catch { }
    }

    getTrunks = async () => {
        try {
            console.log("[getTrunks] Invoking the back-end API to get all trunks of this tenant.");

            const trunks = await apiCalls.getTrunks(this.props.authenticationContext);

            this.setState({
                trunks: trunks,
            });               
        }
        catch (err) {
            console.log(`[getTrunks] Error retrieving the trunks: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                 
        }
    }

    clearDeviceStatus = (deviceId) => {
        this.setState({
            devicesStatus: this.state.devicesStatus.filter(status => status.deviceId !== deviceId),
        });
    }

    handleDeviceDelete = (device) => {
        this.setState({
            deviceToDelete: device,
        });
        this.showConfirmDeleteModal();
    }

    handleDeviceEdit = (device) => {
        this.setState({
            deviceToUpdate: device,
        });
        this.showUpdateDeviceModal();
    }

    executeDeleteDevice = async () => {
        try {
            console.log(`[deleteDevice] Invoking the back-end API to delete the device with Id ${this.state.deviceToDelete.deviceId}.`);

            await apiCalls.deleteDevice(this.props.authenticationContext, this.state.deviceToDelete.deviceId);

            this.props.notificationContext.setNotification("Deleting device.", `'${this.state.deviceToDelete.deviceDescription}' successfully deleted.`, 'success');

            this.getDevices();
        }
        catch (err) {
            this.props.notificationContext.setNotification("Deleting device.", `Error deleting '${this.state.deviceToDelete.deviceDescription}'. ${err.toDetailedMessage()}`, 'danger');                  
        }

        this.hideConfirmDeleteModal();
    }

    executeAddOrUpdate = async (doneInfo) => {
        const deviceAction = doneInfo.isUpdate ? 'updating' : 'adding';
        const deviceActionDone = doneInfo.isUpdate ? 'updated' : 'added';

        try {
            console.log(`[executeAddOrUpdate] Invoking the back-end API ${deviceAction} the device.`);

            if (doneInfo.isUpdate)
            {
                await apiCalls.updateDevice(this.props.authenticationContext, doneInfo.deviceId, doneInfo.displayName, doneInfo.sipUsername, doneInfo.type, doneInfo.location, doneInfo.recorded, doneInfo.twoWayVideo, doneInfo.incomingCalls, doneInfo.doorOpenDtmf);
                this.clearDeviceStatus(doneInfo.deviceId);
            }
            else
            {
                await apiCalls.addDevice(this.props.authenticationContext, doneInfo.displayName, doneInfo.sipUsername, doneInfo.type, doneInfo.location, doneInfo.recorded, doneInfo.twoWayVideo, doneInfo.incomingCalls, doneInfo.doorOpenDtmf);
            }

            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(deviceAction)} device.`, `'${doneInfo.displayName}' successfully ${deviceActionDone}.`, 'success');
            this.getDevices();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(deviceAction)} device.`, `Error ${deviceAction} '${doneInfo.displayName}'. ${err.toDetailedMessage()}`, 'danger');
        }
        finally {
            this.hideDeviceModal();
        }
    }

    executeTrunkAddOrUpdateDevice = async (doneInfo) => {
        const deviceAction = doneInfo.isUpdate ? 'updating' : 'adding';
        const deviceActionDone = doneInfo.isUpdate ? 'updated' : 'added';
        const trunkAction = doneInfo.isUpdate ? 'from' : 'to';

        try {
            console.log(`[executeTrunkAddOrUpdateDevice] Invoking the back-end API ${deviceAction} the device to trunk ${this.state.addUpdateDeviceTrunk?.sipTrunkId}.`);

            if (doneInfo.isUpdate)
            {
                await apiCalls.updateDevice(this.props.authenticationContext, doneInfo.deviceId, doneInfo.displayName, doneInfo.sipUsername, doneInfo.type, doneInfo.location, doneInfo.recorded, doneInfo.twoWayVideo, doneInfo.incomingCalls, doneInfo.doorOpenDtmf);
                this.clearDeviceStatus(doneInfo.deviceId);
            }
            else
            {
                await apiCalls.trunkAddDevice(this.props.authenticationContext, this.state.addUpdateDeviceTrunk?.sipTrunkId, doneInfo.displayName, doneInfo.sipUsername, doneInfo.type, doneInfo.location, doneInfo.recorded, doneInfo.twoWayVideo, doneInfo.incomingCalls, doneInfo.doorOpenDtmf);
            }           

            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(deviceAction)} trunk device.`, `'${doneInfo.displayName}' successfully ${deviceActionDone} ${trunkAction} trunk '${this.state.addUpdateDeviceTrunk?.name}'.`, 'success');
            this.getDevices();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(deviceAction)} trunk device.`, `Error ${deviceAction} '${doneInfo.displayName}' ${trunkAction} trunk '${this.state.addUpdateDeviceTrunk?.name}'. ${err.toDetailedMessage()}`, 'danger');
        }
        finally {
            this.hideTrunkDeviceModal();
        }
    }

    downloadConfigurationScript = async () => {
        try {
            console.log("[downloadConfigurationScript] Invoking the back-end API to get the feature configuration script.");

            const scriptBlob = await apiCalls.getFeatureConfigurationScript(this.props.authenticationContext);

            triggerDownloadBlob(scriptBlob, 'FeatureConfiguration.ps1');
        }
        catch (err) {
            console.log(`[downloadConfigurationScript] Error downloading the feature configuration script: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    getSipUsernamesTrunk = () => {
        if (!this.state.devices) {
            return [];
        }

        const trunkDevices = this.state.devices.filter(device => 
            device.sipTrunkId === this.state.addUpdateDeviceTrunk?.sipTrunkId && 
            (!this.state.trunkDeviceToUpdate ||
            device.deviceId !== this.state.trunkDeviceToUpdate.deviceId));

        if (trunkDevices.length <= 0) {
            return [];
        }

        return trunkDevices.map((device) => {
            return device.sipUsername;
        })
    }

    shouldAddDeviceButtonBeEnabled = () => {
        if (this.state.devices.length >= maximumNumberOfDevices) {
            return false;
        }

        return true;
    }

    shouldScriptButtonBeEnabled = () => {
        if (this.state.videoSettings.isEnforceGroupCallsEnabled && !this.state.videoSettings.isSupportiveUserCreated) {
            return true;
        }

        const filteredDevices = this.state.devicesStatus.filter(deviceStatus => deviceStatus.applicationInstanceStatus === "notAssigned" || deviceStatus.applicationInstanceStatus === "notUpToDate");
        if (filteredDevices.length > 0) {
            return true;
        }

        return false;
    }

    shouldVideoTemporarilyDisabledAlertBeShown = () => {
        return this.state.videoSettings.isVideoFor1on1CallsDisabled && !this.state.videoSettings.isEnforceGroupCallsEnabled;
    }

    shouldNoVideoConfiguredAlertBeShown = () => {
        return this.state.videoSettings.isVideoFor1on1CallsDisabled && 
               this.state.videoSettings.isEnforceGroupCallsEnabled &&
               !this.state.videoSettings.isSupportiveUserCreated;
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>Device settings</h1> 

        if (this.state.isLoading) {
            return (
                <Container fluid>
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <p><em>Loading...</em></p>
                </Container>
            );
        }

        const warningText = Utils.isTrue(this.state.deviceToDelete.enabled) ? `The device '${this.state.deviceToDelete.deviceDescription}' will no longer be able to use the CyberGate service.` : "";
        
        const confirmDeleteModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmDeleteModalOpen} onHide={this.hideConfirmDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete device '{this.state.deviceToDelete.deviceDescription}'?<br/>    
                    {warningText}           
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.executeDeleteDevice}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        const directDevices = this.state.devices.filter(device => !device.sipTrunkId);

        const directDevicesRendered = directDevices.length > 0 ? 
            <DeviceEntries tenant={this.props.tenantContext} devices={directDevices} devicesStatus={this.state.devicesStatus} handleDelete={this.handleDeviceDelete} handleEdit={this.handleDeviceEdit}/> : 
            <Alert className="mt-3" variant='warning' transition={false}>
                No devices configured. Please add a device which will be connected to the CyberGate service.
            </Alert>;

        const trunkDeviceGroupsRendered = this.state.trunks.length > 0 ? 
            <TrunkDeviceGroups tenant={this.props.tenantContext} trunks={this.state.trunks} devices={this.state.devices} devicesStatus={this.state.devicesStatus} onDeviceDelete={this.handleDeviceDelete} onDeviceEdit={this.showTrunkUpdateDeviceModal} onAddDevice={this.showTrunkAddDeviceModal}/> : 
            <Alert variant='warning' transition={false}>
                No SIP trunks configured. Please add a SIP trunk which will be connected to the CyberGate service.
            </Alert>;

        const videoTemporarilyDisabledWarningRendered = this.shouldVideoTemporarilyDisabledAlertBeShown() ?
        <Alert variant='danger' transition={false}>
            Video support in Teams is currently disabled for technical reasons. We are working closely with Microsoft for a solution.
        </Alert> : 
        <></>

        const videoNotConfiguredWarningRendered = this.shouldNoVideoConfiguredAlertBeShown() ?
        <Alert variant='warning' transition={false}>
            Video support in Teams is not yet enabled. Please run the feature configuration script to enable it. 
        </Alert> : 
        <></>

        return (
            <Container fluid>
                {confirmDeleteModalRendered}
                <ModalDeviceAddOrUpdate isOpen={this.state.isDeviceModalOpen} isUpdate={this.state.isDeviceModalUpdate} tenant={this.props.tenantContext} deviceToUpdate={this.state.deviceToUpdate} onHide={this.hideDeviceModal} onAddOrUpdate={this.executeAddOrUpdate} isTrunked={false}/>
                <ModalDeviceAddOrUpdate isOpen={this.state.isTrunkDeviceAddModalOpen} isUpdate={this.state.isTrunkDeviceModalUpdate} tenant={this.props.tenantContext} deviceToUpdate={this.state.trunkDeviceToUpdate} sipUsernames={this.getSipUsernamesTrunk()} onHide={this.hideTrunkDeviceModal} onAddOrUpdate={this.executeTrunkAddOrUpdateDevice} isTrunked={true}/>
                {headerRendered}
                {this.props.tenantContext.showWarningWhenDisabled()}
                {videoTemporarilyDisabledWarningRendered}
                <div>
                    <p className="my-2">Create a device entry for each SIP device you are connecting to CyberGate.<br/>
                    Each created device entry contains an authentication username and password to be used in the configuration of your SIP device together with 'cybergate.cybertwice.com' as the registar address.<br/>
                    For detailed instructions on how to configure the SIP device click <a href='https://support.cybertwice.com/knowledgebase.php?category=2' target='_blank' rel='noreferrer'>here</a> for the brand specific manuals.
                    </p>
                 </div>
                <hr/>
                <div>
                    {videoNotConfiguredWarningRendered}
                    <p className="my-2">To make the display name visible and to enable video in Teams, some configuration in the Teams environment is required.<br/>
                    This can be done automatically by executing the PowerShell script that can be downloaded with the button below.<br/>
                    The user to execute this script must have either the Global Administrator role or both the User Administrator role and the Teams Administrator role.<br/>
                    For more information see the <a href='https://support.cybertwice.com/knowledgebase.php?article=7' target='_blank' rel='noreferrer'>manual</a>.
                    </p>
                    <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldScriptButtonBeEnabled()} disabledTooltip="No changes detected" onClick={this.downloadConfigurationScript}>Download</ButtonWithDisabledTooltip>
                </div>
                <hr/>
                {this.props.tenantContext.trunkSupportEnabled ? 
                    <>
                        <h4>Directly connected devices</h4>
                        <br/>
                    </> : null}
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddDeviceButtonBeEnabled()} disabledTooltip="Maximum number of devices reached" onClick={this.showAddDeviceModal}>Add device</ButtonWithDisabledTooltip>
                {directDevicesRendered}

                {this.props.tenantContext.trunkSupportEnabled ? 
                    <>
                        <br/>
                        <hr/>
                        <br/>
                        <h5>SIP trunks</h5>
                        {trunkDeviceGroupsRendered}
                    </> : null}
                <br/>
                <br/>
            </Container>
        );
    }
}

// Wrap the component so that multiple contexts are available via the props.
export const DeviceSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <DeviceSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}