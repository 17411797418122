import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import css from './SideMenuItem.module.scss';

export class SideMenuItem extends Component {
    render() {
        return (
            <div className={css.sideMenuItem}>
                <NavItem>
                    <NavLink tag={RRNavLink} className={`text-dark ${css.menuLink}`} to={this.props.url}>
                        <span className={css.icon}>
                            <FontAwesomeIcon icon={this.props.icon} />
                        </span>
                        <span className={css.text}>
                            {this.props.text}
                        </span>
                    </NavLink>
                </NavItem>
            </div>
        );
    }
}
