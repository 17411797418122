import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';

export class ModalDistributorEnableConfirm extends Component {
    handleEnableDistributor = () => {
        this.props.onEnableConfirm(this.props.tenant);
    }

    render()
    {
        return <>
            <Modal style={{ opacity: 1 }} show={this.props.show} data-backdrop="static" data-keyboard="false">
                <Modal.Header>
                    <Modal.Title>Confirm enable of distributor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Warning: when a distributor is enabled all of it's customers will stay disabled.                        
                    </p>
                    <strong>
                        {`Please contact '${this.props.tenant?.tenantLabel}' to notify that they need to take action to enable their customers!`}
                    </strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" onClick={this.handleEnableDistributor}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}