import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'reactstrap';
import * as SipHelper from '../utils/sipHelper';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

const descriptionRegex = new RegExp("^[0-9a-zA-zÀ-ž\\s\\-_@.!(),]+$");

export class ModalMultiringGroupAddOrUpdate extends Component {
    multiringGroupNameStableTimer = null;

    state = {
        multiringGroupName: "",
        multiringGroupDescription: "",
        nameAvailable: null,
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }
    
    async componentWillUnmount() {
        clearTimeout(this.multiringGroupNameStableTimer);
    }

    setInitialValues = () => {
        const isUpdate = this.props.isUpdate;

        this.setState(
            {
                multiringGroupName: isUpdate ? this.props.multiringGroupToUpdate.name.split('@')[0] : "",
                multiringGroupDescription: isUpdate ? this.props.multiringGroupToUpdate.description : "",
            }
        );
    }

    // The name that is provided for the new multiringGroup will be used as the 'username' part
    // of the Sip address that will be entered in 'To' address of the device.
    // For this reason the provided name has to be a valid Sip username.
    handleMultiringGroupNameChanged = (e) => {
        const trimmedInput = e.target.value.trim();

        if (trimmedInput.length > 0 && !SipHelper.isValidUsername(trimmedInput)) {
            return;
        }

        this.setState({
            nameAvailable: null,
            multiringGroupName: trimmedInput,
        });

        clearTimeout(this.multiringGroupNameStableTimer);
        this.multiringGroupNameStableTimer = setTimeout(async () => {
            const nameAvailable = await this.props.checkNameAvailableFunction(this.state.multiringGroupName);
            this.setState({
                nameAvailable: nameAvailable,
            });
        }, 500);
    }

    handleMultiringGroupDescriptionChanged = (e) => {
        if (e.target.value.length > 0 && !descriptionRegex.test(e.target.value)) {
            return;
        }

        this.setState({
            multiringGroupDescription: e.target.value,
        });
    }

    // The Save button of the new multiringGroup modal should only be enabled when both the name and the description
    // is not empty and when the provided name is not the same as an existing multiringGroup.
    shouldAddOrUpdateButtonBeEnabled = () => {
        return this.getAddOrUpdateButtonTooltip() === null;
    }

    getAddOrUpdateButtonTooltip = () => {
        if (this.state.multiringGroupName.length === 0 || this.state.multiringGroupDescription.length === 0) {
            return "Fill fields";
        }
        
        if (this.props.isUpdate) {
            if (this.state.multiringGroupName === this.props.multiringGroupToUpdate.name.split('@')[0] &&
                this.state.multiringGroupDescription === this.props.multiringGroupToUpdate.description) {
                return "No changes detected";
            }

            // Only check when name was changed
            if (this.state.multiringGroupName !== this.props.multiringGroupToUpdate.name.split('@')[0] && !this.state.nameAvailable) {
                return "Multi-ring group name is not available, please change";
            }

            return null;
        }

        if (this.state.multiringGroupName.length > 64) {
            return "Name must be less than 64 characters";
        }

        if (this.state.multiringGroupDescription.length > 256) {
            return "Description must be less than 256 characters";
        }

        if (!this.state.nameAvailable) {
            return "Multi-ring group name is not available, please change";
        }

        return null;
    }

    onAddOrUpdateButtonClicked = () => {
        const doneInfo = {
            multiringGroupName: this.state.multiringGroupName,
            multiringGroupDescription: this.state.multiringGroupDescription,
            isUpdate: this.props.isUpdate
        }

        if (this.props.isUpdate) {
            doneInfo.callGroupId = this.props.multiringGroupToUpdate.callGroupId
        }

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.props.onHide();
    }

    render() {
        const headerText = this.props.isUpdate ? 'Update multi-ring group' : 'Add multi-ring group';
        const doneButtonText = this.props.isUpdate ? 'Update' : 'Add';

        return <Modal size="md" style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide} centered>
            <Modal.Header>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} controlId="multiringGroupName">
                        <Form.Label column sm="5">
                            Name
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="multiringGroupName" value={this.state.multiringGroupName} autoComplete="off" onChange={this.handleMultiringGroupNameChanged} />
                        </Col>                       
                    </Form.Group>
                    <Form.Group as={Row} controlId="multiringGroupDomain">
                        <Form.Label column sm="5">
                            Domain
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="multiringGroupDomain" readOnly value={this.props.domainName} />
                        </Col>                       
                    </Form.Group>                  
                    <Form.Group as={Row} controlId="multiringGroupDescription">
                        <Form.Label column sm="5">
                            Description
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="multiringGroupDescription" value={this.state.multiringGroupDescription} autoComplete="off" onChange={this.handleMultiringGroupDescriptionChanged} />
                        </Col>   
                    </Form.Group>                
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.props.onHide}>Cancel</Button>
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddOrUpdateButtonBeEnabled()} disabledTooltip={this.getAddOrUpdateButtonTooltip()} onClick={this.onAddOrUpdateButtonClicked}>{doneButtonText}</ButtonWithDisabledTooltip>
            </Modal.Footer>
        </Modal>;
    }
}
