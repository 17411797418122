import { Component } from 'react';

import css from './MarketplaceReport.module.scss';

export class MarketplaceTotalReportLine extends Component {
    render() {
        const totalMonthly = this.props.totalMonthly;
        const totalYearly = this.props.totalYearly;
        const totalQuantity = this.props.totalQuantity;
        const totalSubscriptions = this.props.totalSubscriptions;
        const totalRecorded = this.props.totalRecorded;
        const monthPayments = this.props.monthPayments;
        const months = this.props.months;
        const showDetails = this.props.showDetails;
        const showExpectedPayments = this.props.showExpectedPayments;
        const showActualPayments = this.props.showActualPayments;
        const allLinesShown = this.props.allLinesShown;
        const showDifferencePayments = this.props.markDifferences;

        const detailColumns = showDetails ? <>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
            </> :
            null

        return (
            <tr>
                <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}/>
                {detailColumns}
                <td className={`${css.tableCellAlignRight} ${css.tableCellGray}`}>
                    <b>Total: {totalSubscriptions}</b>
                </td>
                <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}>
                    <b>{totalMonthly}/{totalYearly}</b>
                </td>
                <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}>
                    <b>{totalQuantity}</b>
                </td>
                <td className={`${css.tableCellAlignCenter} ${css.tableCellGray}`}>
                    <b>{totalRecorded}</b>
                </td>
                {monthPayments.map((value, index) => {

                    const month = months[index];

                    let actualValue = month && value.actual && value.actual > 0 && allLinesShown ? value.actual + month.adjustedPaymentTotal : value.actual;

                    const expectedLine = showExpectedPayments ? <p className={css.textColorPurple}><b>${value.expected.toFixed(2)}</b></p> : null;
                    const actualLine = showActualPayments && actualValue ? <p className={css.textColorBlue}><b>${actualValue.toFixed(2)}</b></p> : null;
                    const differenceLine = showDifferencePayments && value.difference ? <p className={css.textColorRed}><b>${value.difference.toFixed(2)}</b></p> : null;
                    const totalLine = showDifferencePayments && value.difference ? <p className={css.textColorRed}><b>${(actualValue + value.difference).toFixed(2)}</b></p> : null;

                    return <td key={index} className={`${css.tableCellAlignRight} ${css.tableCellGray}`}>
                            {expectedLine}
                            {actualLine}
                            {differenceLine}
                            {totalLine}
                            {/* <p className={css.tableCellTextSmall}>${month?.adjustedPaymentTotal.toFixed(2)}</p> */}
                        </td>                      
                    })}
            </tr>
        )
    }
}
