const alphaChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numChars = "0123456789";
const alpanumChars = alphaChars + numChars;
const markChars = "-_.!~*'()";
const unreservedChars = alpanumChars + markChars;
const userUnreservedChars = "&=+$,;?/";
const userChars = unreservedChars + userUnreservedChars;

// Method verifies if the provided Sip address (only username/hostname part) is valid.
// Remarks:
// - Escaped characters are not supported in the username
// - IP addresses are not allowed as hostname
// - Dot at end of hostname (for local domains) is not allowed
// - Hostname with only toplabel is not allowed
export const isSipAddressValid = (sipAddress) => {
    if (!sipAddress) {
        return false;
    }

    const splitOnUsernameAndHostname = sipAddress.split('@');
    if (splitOnUsernameAndHostname.length !== 2) {
        return false;
    }

    let username = splitOnUsernameAndHostname[0];
    let hostname = splitOnUsernameAndHostname[1];

    if (!isValidUsername(username) || 
        !isValidHostname(hostname)) {
        return false;
    }

    return true;
}

// Method verifies if the provided username is valid according to RFC 3261.
export const isValidUsername = (username) => {
    if (!username || username.length === 0) {
        return false;
    }

    for (var i = 0; i < username.length; i++) {
        if (!userChars.includes(username[i])) {
            return false;
        }
    }

    return true;
}

// Method verifies if the provided hostname is valid according to RFC 3261.
// Remarks:
// - IP addresses are not allowed as hostname
// - Dot at end of hostname (for local domains) is not allowed
// - Hostname with only toplabel is not allowed
export const isValidHostname = (hostname) => {
    if (!hostname || hostname.length === 0) {
        return false;
    }

    let splitOnDomainsAndTopLevelDomain = hostname.split('.');
    if (splitOnDomainsAndTopLevelDomain.length < 2) {
        return false;
    }

    let topLevelDomainLabels = splitOnDomainsAndTopLevelDomain.pop();
    let domainLabels = splitOnDomainsAndTopLevelDomain;

    if (!isValidTopLevelDomainLabel(topLevelDomainLabels)) {
        return false;
    }

    if (!domainLabels.every(domain => isValidDomainLabel(domain))) {
        return false;
    }

    return true;
}

// Method verifies if the provided top level domain label is valid according to RFC 3261.
export const isValidTopLevelDomainLabel = (topLevelDomainLabel) => {
    if (!isValidDomainLabel(topLevelDomainLabel)) {
        return false;
    }

    // Top level should start with Alpha char
    if (!alphaChars.includes(topLevelDomainLabel[0])) {
        return false;
    }

    return true;
}

// Method verifies if the provided domain name is valid according to RFC 3261.
export const isValidDomainLabel = (domainLabel) => {
    if (!domainLabel || domainLabel.length === 0) {
        return false;
    }

    // Only Alphanumeric characters and the hyphen are allowed.
    for (var i = 0; i < domainLabel.length; i++) {
        if (!(alpanumChars.includes(domainLabel[i]) ||
            domainLabel[i] === '-')) {
            return false;
        }
    }

    // The first and last characters may not be an hyphen.
    if (domainLabel[0] === '-' || domainLabel[domainLabel.length - 1] === '-') {
        return false;
    }

    return true;
}