import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';

import { WhitelistRange } from '../../../logic/apiCalls';

type ModalIpAddressDeleteProps = {
    show: boolean,
    onHide: () => void,
    onDelete: (newIpAddress: WhitelistRange) => void,
    ipAddressToDelete: WhitelistRange | undefined,
}

export class ModalIpAddressDelete extends Component<ModalIpAddressDeleteProps> {

    handleDelete = () => {
        if (this.props.ipAddressToDelete) {
            this.props.onDelete(this.props.ipAddressToDelete);
        }
    }

    render() {

        const ipAddressToDelete = this.props.ipAddressToDelete;
        const entryText = ipAddressToDelete ? ipAddressToDelete.ipAddressStart === ipAddressToDelete.ipAddressEnd ?  `IP address '${ipAddressToDelete.ipAddressStart}'` : `IP range '${ipAddressToDelete.ipAddressStart}-${ipAddressToDelete.ipAddressEnd}'` : "IP address ";
        const entryTextShort = ipAddressToDelete && ipAddressToDelete.ipAddressStart !== ipAddressToDelete.ipAddressEnd ?  "IP range " : "IP address ";
        
        return <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete {entryText}?<br />
                <ul>
                    <li>Description: {ipAddressToDelete?.description}</li>
                    <li>Location: {ipAddressToDelete?.location}</li>
                </ul>
                Any device that uses this WAN {entryTextShort} can no longer connect to the CyberGate service.
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.props.onHide}>
                    Cancel
                </Button>
                <Button color="primary" onClick={this.handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}