import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AuthenticationContext, authorizationStates } from '../msal/AuthenticationContext';
import { Spinner } from './Spinner';

export const ProtectedRoute = (props) => {
    const authenticationInfo = useContext(AuthenticationContext);

    if (authenticationInfo.isAuthenticated() && 
        authenticationInfo.authorizationState === authorizationStates.UNKNOWN ) {
        return <Spinner/>
    }

    if (authenticationInfo.isAuthenticated() && 
        authenticationInfo.isAuthorized()) {
        return <Route {...props} component={props.component} />
    }

    return <Redirect to='/' />
}