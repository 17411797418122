import { useContext, Component } from 'react';
import { Container } from 'reactstrap';
import { AuthenticationContext } from '../../../msal/AuthenticationContext';
import { TenantContext } from '../../../context/TenantContext';
import { NotificationContext } from '../../../context/NotificationContext';
import { ModalAccessGroupAdd } from './ModalAccessGroupAdd';
import { PortalAccessGroups } from './PortalAccessGroups';
import { Group } from '../../../logic/apiCalls';
import * as apiCalls from '../../../logic/apiCalls';
import { ButtonWithDisabledTooltip } from '../../../components/ButtonWithDisabledTooltip';
import { Alert } from 'react-bootstrap';

// The maximum allowed number of trunks per tenant.
const maximumNumberOfAccessGroups = 10;

type PortalAccessSettingsInternalProps = {
    authenticationContext: any,
    notificationContext: any,
    tenantContext: any,
}

type PortalAccessSettingsInternalState = {
    isGroupModalOpen: boolean,
    isLoadingPortalAccess: boolean,
    groups: Array<Group>,
}

class PortalAccessSettingsInternal extends Component<PortalAccessSettingsInternalProps, PortalAccessSettingsInternalState> {
    state: PortalAccessSettingsInternalState  = {
        isGroupModalOpen: false,
        isLoadingPortalAccess: true,
        groups: []
    }

    async componentDidMount() {
        this.getPortalAcces();
    }
    
    getPortalAcces = async () => {
        try {
            console.log("[getPortalAccess] Invoking the back-end API to get all portal access entries of this tenant.");

            const portalAccess = await apiCalls.getPortalAccess(this.props.authenticationContext);

            console.log(`[getPortalAccess] Successfully retrieved the portal access: ${portalAccess.length}`)
            this.setState({
                groups: portalAccess
            });
        }
        catch (err) {  
            console.log(`[getPortalAccess] Error retrieving the portal access: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                 
        }
        finally {
            this.setState({
                isLoadingPortalAccess: false
            });
        }
    }

    showAddGroupModal = () => {
        this.setState({
            isGroupModalOpen: true,
        });
    };

    hideAddGroupModal = () => {
        this.setState({
            isGroupModalOpen: false,
        });
    }

    handleGroupAdd = async (accessGroup : Group) => {
        try {
            this.hideAddGroupModal();    

            await apiCalls.addPortalAccess(this.props.authenticationContext, accessGroup.azureGroupId);

            this.props.notificationContext.setNotification(`Adding group for access to portal.`, `Group '${accessGroup.azureGroupName}' was assigned successfully.`, 'success');

            this.getPortalAcces();
        }
        catch (err: any) {
            this.props.notificationContext.setNotification("Adding group for access to portal.", `Failed to assign Group '${accessGroup.azureGroupName}'. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    handleGroupDelete = async (accessGroup : Group) => {
        try {
            await apiCalls.deletePortalAccess(this.props.authenticationContext, accessGroup.azureGroupId);

            this.props.notificationContext.setNotification(`Removing group for access to portal.`, `Group '${accessGroup.azureGroupName}' was unassigned successfully.`, 'success');

            this.getPortalAcces();
        }
        catch (err: any) {  
            this.props.notificationContext.setNotification("Removing group for access to portal.", `Failed to unassign Group '${accessGroup.azureGroupName}''. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    shouldAddGroupButtonBeEnabled = () => {
        if (this.state.groups.length >= maximumNumberOfAccessGroups) {
            return false;
        }

        return true;
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>
            Portal access settings
        </h1> 

        if (this.state.isLoadingPortalAccess) {
            return (
                <Container fluid>
                    <div>
                        {headerRendered}
                        {this.props.tenantContext.showWarningWhenDisabled()}
                        <p><em>Loading...</em></p>
                    </div>
                </Container>
            );
        }

        const groupsRendered = this.state.groups.length > 0 ? 
            <PortalAccessGroups groups={this.state.groups} handleDelete={this.handleGroupDelete}/> : 
            <Alert className="mt-3" variant='warning' transition={false}>
                No groups configured.
            </Alert>;

        return (
            <>
                <Container fluid>
                    <ModalAccessGroupAdd show={this.state.isGroupModalOpen} tenant={this.props.tenantContext} onHide={this.hideAddGroupModal} onAdd={this.handleGroupAdd} alreadySelectedGroups={this.state.groups} />
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <p>Assign Microsoft groups for access to this portal.</p>
                    <p>All members of these groups will have access to this portal.</p>
                    <p>Note that users with the following administrator roles will always have access to this portal: Global Administrator, Application Administrator, Cloud Administrator or Teams Administrator.</p>
                    <hr/>
                    <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddGroupButtonBeEnabled()} disabledTooltip="Maximum number of groups defined" onClick={this.showAddGroupModal}>Add group</ButtonWithDisabledTooltip>
                    {groupsRendered}
                    <br/>
                    <br/>
                </Container>
            </>
        );
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const PortalAccessSettings = (props : any) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <PortalAccessSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}