import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { DisplayNameIcon } from './DisplayNameIcon';
import { DeleteButton } from './DeleteButton';
import * as Utils from '../utils/helper';
import { EditButton } from './EditButton';
import { DeviceRecordedColumn } from './DeviceRecordedColumn';
import { DeviceIncomingCallsColumn } from './DeviceIncomingCallsColumn';

import css from './DeviceEntry.module.scss';

export const TrunkDeviceEntry = ({ tenant, device, deviceStatus, handleDelete, handleEdit }) => {
    const getLabelWithMaxLength = (label, maxLenght) => {
        if (!label) {
            return null;
        }

        if (label.length <= maxLenght) {
            return <>{label}</>
        }

        const labelTrimmed = label.substring(0, maxLenght - 2).trim() + "...";

        return <>
            <OverlayTrigger 
                placement='right'
                overlay={
                    <Tooltip>
                        {label}
                    </Tooltip>
                }
            >
                <span>{labelTrimmed}</span>
            </OverlayTrigger>
        </>;
    }

    let sipUsername = <i>[auto-detect]</i>;    
    if (device.sipUsername)
    {
        sipUsername = getLabelWithMaxLength(device.sipUsername, 30);
    }

    const deviceApplicationInstanceStatus = deviceStatus?.applicationInstanceStatus || "undetermined";
    const deviceRecorded = Utils.isTrue(device.recorded);
    const deviceIncomingCalls = Utils.isTrue(device.incomingCalls);
    const tenantRecordingEnabled = Utils.isTrue(tenant.callRecordingEnabled);
       
    return (
        <tr>
            <td>
                <div className={css.flexContainer}>
                    <div>
                        {getLabelWithMaxLength(device.deviceDescription, 50)}
                    </div>
                    <div className={css.flexItemRight}>
                        <DisplayNameIcon state={deviceStatus?.applicationInstanceStatus || "undetermined"}/>                
                    </div>
                </div>
            </td>
            <td className="align-middle">
                {sipUsername}
            </td>           
            <td className="align-middle">
                {Utils.isTrue(device.enabled) ? "yes" : "no"}
            </td>
            <td className="align-middle">
                <DeviceRecordedColumn deviceStatus={deviceApplicationInstanceStatus} deviceRecorded={deviceRecorded} tenantRecordingEnabled={tenantRecordingEnabled}/>
            </td>
            <td>
                <DeviceIncomingCallsColumn deviceStatus={deviceApplicationInstanceStatus} incomingCalls={deviceIncomingCalls}/>
            </td>
            <td className="align-middle">
                <DeleteButton className="float-right mr-1" tooltip="Delete" onClick={() => handleDelete(device)} />
                <EditButton className="float-right mr-1" tooltip="Edit" onClick={() => handleEdit(device)} />                
            </td>
        </tr>
    )
}