import React from 'react';
import css from './DeviceEntryLocationHeader.module.scss';

export const DeviceEntryLocationHeader = ({ location }) => {
  
    return (
        <tr className={css.row}>
            <td colSpan="7">
                <div>
                    {location}
                </div>
            </td>
        </tr>
    )
}