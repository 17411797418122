import { useContext, Component, Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'reactstrap';
import { Button } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { NotificationContext } from '../../context/NotificationContext';
import { Meetings } from '../../components/Meetings';
import { ModalMeetingAddOrUpdate } from '../../components/ModalMeetingAddOrUpdate';
import * as apiCalls from '../../logic/apiCalls';
import { isValidMeetingUrl } from '../../logic/meeting';
import { ButtonWithDisabledTooltip } from '../../components/ButtonWithDisabledTooltip';
import * as Misc from '../../utils/misc';

// The maximum allowed number of meetings per tenant.
const maximumNumberOfMeetings = 10;
const timeoutNoUsersLeftSeconds = 5;
const timeoutNoUsersJoinedSeconds = 120;

const fallbackDomain = {
    "capabilities": "OfficeCommunicationsOnline",
    "isDefault": true,
    "isInitial": false,
    "name": "cybergate.cybertwice.com",
    "type": "Managed"
};

class MeetingSettingsInternal extends Component {
    state = {
        isLoadingMeetings: true,
        isLoadingDomains: true,
        meetings: [],
        verifiedDomains: [fallbackDomain],
        meetingToDelete: {},
        isMeetingModalOpen: false,
        isUpdateMeetingModal: false,
        meetingToUpdate: null,
        isConfirmDeleteModalOpen: false,
        autoCloseOnLeave: true,
        autoCloseOnNoJoin: true,
    }

    // Get all meetings when the page is loaded.
    async componentDidMount() {
        this.getMeetings();
        this.getGlobalMeetingSettings();
        this.getOrganizationDomains();
    }

    showAddMeetingModal = () => {
        this.setState({
            isMeetingModalOpen: true,
            isUpdateMeetingModal: false,
        });
    };

    showUpdateMeetingModal = () => {
        this.setState({
            isMeetingModalOpen: true,
            isUpdateMeetingModal: true,
        });
    };

    hideMeetingModal = () => {
        this.setState({
            isMeetingModalOpen: false
        });
    };

    // Confirm delete of meeting modal showing/hiding stuff.
    showConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: true
        });
    };

    hideConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: false
        });
    };

    getMeetings = async () => {
        try {
            console.log("[getMeetings] Invoking the back-end API to get all meetings of this tenant.");

            const meetings = await apiCalls.getMeetings(this.props.authenticationContext);

            // Filter for valid meeting URL's to prevent malicous hyperlinks are rendered.
            const sanitizedMeetings = meetings.filter(meeting => isValidMeetingUrl(meeting.teamsMeetingUrl));

            console.log(`[getMeetings] Successfully retrieved the meetings: ${sanitizedMeetings.length}`)
            this.setState({
                meetings: sanitizedMeetings
            });
        }
        catch (err) {  
            console.log(`[getMeetings] Error retrieving the meetings: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();               
        }
        finally {
            this.setState({
                isLoadingMeetings: false
            });
        }
    }

    getGlobalMeetingSettings = async () => {
        try {
            console.log("[getGlobalMeetingSettings] Invoking the back-end API to get all global meeting settings of this tenant.");

            const globalMeetingSettings = await apiCalls.getGlobalMeetingSettings(this.props.authenticationContext);

            console.log(`[getGlobalMeetingSettings] Successfully retrieved the global meeting settings: timeoutNoUsersLeftSeconds = ${globalMeetingSettings.timeoutNoUsersLeftSeconds}, timeoutNoUsersJoinedSeconds = ${globalMeetingSettings.timeoutNoUsersJoinedSeconds}.`)
            this.setState({
                autoCloseOnLeave: globalMeetingSettings.timeoutNoUsersLeftSeconds !== Misc.intMaxValue,
                autoCloseOnNoJoin: globalMeetingSettings.timeoutNoUsersJoinedSeconds !== Misc.intMaxValue,
            });
        }
        catch (err) {  
            console.log(`[getGlobalMeetingSettings] Error retrieving the global meeting settings: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                  
        }
    }

    getOrganizationDomains = async () => {
        try {
            console.log("[getOrganizationDomains] Invoking the Graph API to get the organization domains of this tenant.");

            const receivedVerifiedDomains = await apiCalls.getAvailableDomains(this.props.authenticationContext);

            console.log(`[getOrganizationDomains] Successfully retrieved the verified domains: ${JSON.stringify(receivedVerifiedDomains)}`)

            // Find the default domain and put this domain at the top.
            let defaultDomainIndex = receivedVerifiedDomains.findIndex(domain => domain.isDefault === true);
            if (defaultDomainIndex >= 0) {
                let defaultdomain = receivedVerifiedDomains.splice(defaultDomainIndex, 1)[0];
                receivedVerifiedDomains.unshift(defaultdomain);
            }

            this.setState({
                verifiedDomains: receivedVerifiedDomains,
            });
        }
        catch (err) {
            // The global error is not set here, because failure of retrieving the organization names should not break the interface.
            console.log(`[getOrganizationDomains] Error while retrieving organization verified domains for tenant: ${JSON.stringify(err)}`);
        }
        finally {
            this.setState({
                isLoadingDomains: false,
            });
        }
    }

    handleMeetingDelete = (meeting) => {
        this.setState({
            meetingToDelete: meeting,
        });
        this.showConfirmDeleteModal();
    }

    handleMeetingEdit = (meeting) => {
        this.setState({
            meetingToUpdate: meeting,
        });
        this.showUpdateMeetingModal();
    }

    executeAddOrUpdate = async (doneInfo) => {
        if (doneInfo.isUpdate) {
            this.updateMeeting(doneInfo.callGroupId, doneInfo.meetingName, doneInfo.meetingDescription, doneInfo.meetingUrl);
        } else {
            this.addMeeting(doneInfo.meetingName, doneInfo.meetingDescription, doneInfo.meetingUrl);
        }

        this.hideMeetingModal();
    }

    // The Add meeting button should only be enabled when there are currently less than 10 meetings 
    // configured for this tenant.
    shouldAddMeetingButtonBeEnabled = () => {
        if (this.state.meetings.length >= maximumNumberOfMeetings) {
            return false;
        }

        return true;
    }

    constructMeetingName = (meetingName) => {
        return `${meetingName}@${fallbackDomain.name}`;
    }

    deleteMeeting = async () => {
        try {
            console.log(`[deleteMeeting] Invoking the back-end API to delete the meeting with Id ${this.state.meetingToDelete.callGroupId}.`);

            await apiCalls.deleteMeeting(this.props.authenticationContext, this.state.meetingToDelete.callGroupId);

            this.props.notificationContext.setNotification(`Deleting meeting.`, `'${this.state.meetingToDelete.name}' successfully deleted.`, 'success');

            this.getMeetings();
        }
        catch (err) {   
            this.props.notificationContext.setNotification(`Deleting meeting.`, `Error deleting '${this.state.meetingToDelete.name}'. ${err.toDetailedMessage()}`, 'danger');
        }

        this.hideConfirmDeleteModal();
    }

    addMeeting = async (meetingName, meetingDescription, meetingUrl) => {
        try {
            console.log(`[addMeeting] Invoking the back-end API to add the meeting with name ${meetingName}.`);

            await apiCalls.addMeeting(this.props.authenticationContext, this.constructMeetingName(meetingName), meetingDescription, meetingUrl);
            
            this.props.notificationContext.setNotification(`Adding meeting.`, `'${meetingName}' successfully added.`, 'success');
            this.getMeetings();
        }
        catch (err) {  
            this.props.notificationContext.setNotification(`Adding meeting.`, `Error adding '${meetingName}'. ${err.toDetailedMessage()}`, 'danger');
        }

        this.hideMeetingModal();
    }

    updateMeeting = async (callGroupId, meetingName, meetingDescription, meetingUrl) => {
        try {
            console.log(`[addMeeting] Invoking the back-end API to update the meeting with name ${meetingName}.`);

            await apiCalls.updateMeeting(this.props.authenticationContext, callGroupId, this.constructMeetingName(meetingName), meetingDescription, meetingUrl);
            
            this.props.notificationContext.setNotification(`Updating meeting.`, `'${meetingName}' successfully updated.`, 'success');
            this.getMeetings();
        }
        catch (err) {  
            this.props.notificationContext.setNotification(`Updating meeting.`, `Error updating '${meetingName}'. ${err.toDetailedMessage()}`, 'danger');
        }

        this.hideMeetingModal();

    }

    updateGlobalMeetingSettings = async (autoCloseOnLeave, autoCloseOnNoJoin) => {
        try {
            console.log(`[deleteMeeting] Invoking the back-end API to update the global meeting settings.`);

            await apiCalls.updateGlobalMeetingSettings(this.props.authenticationContext, autoCloseOnLeave ? timeoutNoUsersLeftSeconds : Misc.intMaxValue, autoCloseOnNoJoin ? timeoutNoUsersJoinedSeconds : Misc.intMaxValue);

            this.props.notificationContext.setNotification(`Updating global meeting settings.`, 'successfully updated', 'success');
        }
        catch (err) {   
            this.props.notificationContext.setNotification(`Updating global meeting settings.`, `Error updating global meeting settings. ${err.toDetailedMessage()}`, 'danger');
        }
    }

    checkNameAvailable = async (name) => {
        try {
            const fullName = this.constructMeetingName(name);

            console.log(`[checkNameAvailable] Invoking the back-end API to check if ${fullName} is available.`);

            const result = await apiCalls.isCyberGateNameAvailable(this.props.authenticationContext, fullName);

            console.log(`[checkNameAvailable] Successfully checked name ${fullName}: ${result.isAvailable ? "is available" : "is not available" }`)
            
            return result.isAvailable;
        }
        catch (err) { 
            console.log(`[checkNameAvailable] Error checking name availability: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        return false;
    }

    changeAutoCloseOnLeave = () => {
        this.updateGlobalMeetingSettings(!this.state.autoCloseOnLeave, this.state.autoCloseOnNoJoin);
        this.setState({
            autoCloseOnLeave: !this.state.autoCloseOnLeave,
        });
    }

    changeAutoCloseOnNoJoin = () => {
        this.updateGlobalMeetingSettings(this.state.autoCloseOnLeave, !this.state.autoCloseOnNoJoin);
        this.setState({
            autoCloseOnNoJoin: !this.state.autoCloseOnNoJoin,
        });
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>Meeting settings</h1> 

        if (this.state.isLoadingMeetings || this.state.isLoadingDomains) {
            return (
                <Container fluid>
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <p><em>Loading...</em></p>
                </Container>
            );
        }
    
        const confirmDeleteModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmDeleteModalOpen} onHide={this.hideConfirmDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete meeting '{this.state.meetingToDelete.name}'?</Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.deleteMeeting}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        const meetingsRendered = this.state.meetings.length > 0 ? 
            <Meetings 
                meetings={this.state.meetings} 
                verifiedDomains={this.state.verifiedDomains} 
                handleEdit={this.handleMeetingEdit} 
                handleDelete={this.handleMeetingDelete} /> : 
            <Fragment/>;

        return (
            <Container fluid>
                <ModalMeetingAddOrUpdate 
                        show={this.state.isMeetingModalOpen}
                        onHide={this.hideMeetingModal} 
                        isUpdate={this.state.isUpdateMeetingModal}
                        domainName={fallbackDomain.name}
                        meetingToUpdate={this.state.meetingToUpdate}
                        checkNameAvailableFunction={this.checkNameAvailable}
                        onAddOrUpdate={this.executeAddOrUpdate} />
                {confirmDeleteModalRendered}
                {headerRendered}
                {this.props.tenantContext.showWarningWhenDisabled()}
                <p>The meeting feature makes it possible to connect your device to a meeting. The meeting feature is intended for camera devices only. The participants configured here are actively invited to join the meeting.</p>
                <hr/>
                {/* Disabled because for now we want meeting calls to always end (2022-03-22).
                <div>
                    <h4>Global Settings</h4>
                    <Form>
                    <Form.Group as={Row} controlId="meetingLeaveTimeout" className="mb-2">
                            <Form.Check type="checkbox" className="ml-3 mr-3" onClick={this.changeAutoCloseOnLeave} checked={this.state.autoCloseOnLeave}/>                      
                            <p className="mb-0">
                                Auto close meeting on all users leaving
                            </p>
                        </Form.Group>
                        <Form.Group as={Row} controlId="meetingJoinTimeout" className="mb-2">
                            <Form.Check type="checkbox" className="ml-3 mr-3" onClick={this.changeAutoCloseOnNoJoin} checked={this.state.autoCloseOnNoJoin}/>                      
                            <p className="mb-0">
                                Auto close meeting on no users joining
                            </p>
                        </Form.Group>
                    </Form>
                </div>
                <hr/> */}
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddMeetingButtonBeEnabled()} disabledTooltip="Maximum number of meetings defined" onClick={this.showAddMeetingModal}>Add meeting</ButtonWithDisabledTooltip>            
                {meetingsRendered}                  
                <br/>
                <br/>
            </Container>
        );
    }
}

// Wrap the component so that multiple contexts are available via the props.
export const MeetingSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <MeetingSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}