import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { TopMenu } from './menu/TopMenu';
import { SideMenu } from './menu/SideMenu';

import css from './Layout.module.scss';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className={css.layout}>
                <TopMenu />
                <SideMenu/>
                <Container fluid={true}>
                    <div className={css.layoutContent}>
                        {this.props.children}
                    </div>
                </Container>
            </div>         
        );
    }
}
