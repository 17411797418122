import { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Button } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import { triggerDownloadBlob } from '../../utils/download';
import { DistributorReportLines } from '../../components/DistributorReportLines';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { NotificationContext } from '../../context/NotificationContext';
import * as apiCalls from '../../logic/apiCalls';

import sharedCss from '../Shared.module.scss';

class DistributorReportInternal extends Component {

    state = {
        errorMessage: "",
        subscriptionReport: [],
        report: [],
        showDetails: false,
    }

    async componentDidMount() {
        await this.generateReport();
    }

    generateReport = async () => {
        console.log("Start generating report...");

        try {
            console.log("[generateReport] Invoking the back-end API to get the distributor report.");

            const report = await apiCalls.getDistributorReport(this.props.authenticationContext);
            this.setState({
                report: report
            });
        }
        catch (err) {
            console.log(`[generateReport] Error retrieving the distributor report: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification(); 
        }
    }    

    exportDistributorReportClick = async () => {
        try {
            console.log("[exportDistributorReport] Invoking the back-end API to export the distributor report in CSV format.");

            const distributorReportExport = await apiCalls.getDistributorReportCsv(this.props.authenticationContext);

            triggerDownloadBlob(distributorReportExport, 'DistributorReport.csv');
        }
        catch (err) {
            console.log(`[exportDistributorReportClick] Error exporting the distributor report: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    handleShowDetailsClick = () => {
        this.setState((prevState) => {
            return {
                showDetails: !prevState.showDetails
            }
        });
    }

    render() {
        let reportLines = this.state.report?.lines
        let months = this.state.report?.months

        if (!reportLines) {
            reportLines = [];
        }

        if (!months) {
            months = [];
        }
    
        let totalMaxLicenses = 0;
        let totalInUseLicenses = 0;
        let monthLicenses = [];
        if (reportLines.length > 0) {
            for (var reportLine of reportLines) {
                totalMaxLicenses += reportLine.licensesMax;
                totalInUseLicenses += reportLine.licensesInUse;

                let monthIndex = 0;
                if (reportLine.months.length !== this.state.report.months.length)
                {
                    continue;
                }

                for (var month of reportLine.months) {
                    // Create object when not jet exists for index.
                    if (!monthLicenses[monthIndex])
                    {
                        monthLicenses[monthIndex] = {};
                    }

                    if (monthLicenses[monthIndex].max)
                    {
                        monthLicenses[monthIndex].max += month.max;
                    }
                    else
                    {
                        monthLicenses[monthIndex].max = month.max;
                    }

                    if (monthLicenses[monthIndex].inUse)
                    {
                        monthLicenses[monthIndex].inUse += month.inUse;
                    }
                    else
                    {
                        monthLicenses[monthIndex].inUse = month.inUse;
                    }

                    monthIndex++
                }
            }
        }

        // Sort the report lines on tenant label.
        reportLines.sort((a,b) => (a.creationTime < b.creationTime) ? 1 : ((b.creationTime < a.creationTime) ? -1 : 0))
        
        return <Container fluid className={sharedCss.background}>
            <h1>
                Distributor report
                <Link className="float-right" to='/portal/distributor-settings'>
                    <Button color="primary">Close</Button>
                </Link>
            </h1>
            <Form>
                <div className="mb-3 form-group row">
                    <div className="col-sm-1"><Button color="primary" onClick={this.exportDistributorReportClick}>Export to CSV</Button></div>   
                </div>  
                <div className="mb-3 form-group row">
                    <div className="col-sm-1"><Form.Check id="showDetailsCheckbox" style={{ zIndex: 0 }} custom checked={this.state.showDetails} type="checkbox" label="Show details" onChange={this.handleShowDetailsClick} /></div>
                </div>
            </Form>            
            <div style={{ overflow: 'auto' }}>
                <DistributorReportLines showDetails={this.state.showDetails} months={this.state.report.months || []} reportLines={reportLines || []} totalMaxLicenses={totalMaxLicenses} totalInUseLicenses={totalInUseLicenses} monthLicenses={monthLicenses} />
            </div>            
        </Container>;
    }
}

export const DistributorReport = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
  
    return (
        <DistributorReportInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}