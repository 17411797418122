import { Component, createContext, useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { AuthenticationContext, authorizationStates } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import * as apiCalls from '../logic/apiCalls';

export const TenantContext = createContext();
const maxDistributorDepth = 3;

class TenantProviderInternal extends Component {
    authorizationState = authorizationStates.UNKNOWN;

    constructor() {
        super();

        this.state = {
            isLoading: true,
            tenantId: "",        
            tenantLabel: "unknown",
            tenantType: "tenant",
            isDistributor: false,
            isCyberTwice: false,
            isReseller: false,
            enabled: true,
            callForwardingEnabled: false,
            trunkSupportEnabled: false,
            secureOnlyPolicyEnabled: false,
            callRecordingEnabled: false,
            manualLicenseCount: 0,
            marketplaceLicenseCount: 0,
            distributorLicenseCount: 0,
            selfLicenseCount: 0,
            totalLicenseCount: 0,
            manualSubscriptions: [],
            marketplaceSubscriptions: [],
            distributorSubscriptions: [],
            selfSubscriptions: [],
            tenantLogo: null,
            distributor: null,
            adminConsentGrantedFeatures: "undetermined",

            showWarningWhenDisabled: () => { 
                return this.state.enabled ? null :
                    <div className='my-4'>
                        <Alert variant='warning' style={{ opacity: 1 }}>
                            Warning: Your account has been disabled! CyberGate calling is blocked. Please contact your distributor.
                        </Alert>
                    </div>
            },
            refreshAll: async () => {
                this.refreshAll();
            },
            refreshTenant: async () => {
                this.refreshTenant();
            },
            refreshSubscriptions: async () => {
                this.refreshSubscriptions();
            },
            isNextLevelDistributorAReseller: () => {
                return this.state.isDistributor && this.state.distributor && this.state.distributor?.distributorDepth + 1 === maxDistributorDepth;
            }
        }
    }

    componentDidMount() {
        this.checkAuthorizationState();
    }

    componentDidUpdate() {
        this.checkAuthorizationState();
    }

    checkAuthorizationState = () => {
        const newAuthorizationState = this.props.authenticationContext.authorizationState;
        if (this.authorizationState !== newAuthorizationState)
        {
            if (newAuthorizationState === authorizationStates.AUTHORIZED)
            {
                this.refreshAll();
            }

            this.authorizationState = newAuthorizationState;
        }
    }

    refreshAll = async () => {
        await this.getTenant();
        await this.getSubscriptions();
        await this.getTenantLogo();
    }

    refreshTenant = async () => {
        await this.getTenant();
    }

    refreshSubscriptions = async () => {
        await this.getSubscriptions();
    }

    getTenant = async () => {
        console.log("Start retrieving tenant...");

        let isDistributor = false;
        
        try {
            console.log("[getTenant] Invoking the back-end API to get the tenant.");

            const tenant = await apiCalls.getTenant(this.props.authenticationContext);

            isDistributor = tenant.isDistributor;

            this.setState({
                tenantId: this.props.authenticationContext.user.tenantId,
                tenantLabel: tenant.tenantLabel,
                tenantType: tenant.isDistributor ? "distributor" : "tenant",
                isDistributor: tenant.isDistributor,
                isCyberTwice: this.props.authenticationContext.user.tenantId === "0c1fb16c-0190-47e0-b839-5ec8665eb699",
                enabled: tenant.enabled,
                callForwardingEnabled: tenant.callForwardingEnabled,
                trunkSupportEnabled: tenant.trunkSupportEnabled,
                callRecordingEnabled: tenant.callRecordingEnabled,
                secureOnlyPolicyEnabled: tenant.secureOnlyPolicyEnabled,
                adminConsentGrantedFeatures: tenant.adminConsentGrantedFeatures
            });
        }
        catch (err) {
            console.log(`[getTenant] Error retrieving the tenant information: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        finally {
            if (isDistributor) {
                this.getDistributor();
            } else {
                this.setState({
                    isLoading: false, 
                });
            }
        }
    }

    getSubscriptions = async () => {
        try {
            console.log("[getSubscriptions] Invoking the back-end API to get all subscriptions of this tenant.");

            const subscriptions = await apiCalls.getSubscriptions(this.props.authenticationContext);

            // TODO: remove old definitions (Marketplace, Distributed, Distributor)!
            const manualSubscriptions = subscriptions.filter(sub => sub.type === "Manual");
            const marketplaceSubscriptions = subscriptions.filter(sub => sub.type === "Marketplace" || sub.type === "ManagedByMarketplace");
            const distributorSubscriptions = subscriptions.filter(sub => sub.type === "Distributed" || sub.type === "ManagedByDistributor");
            const selfSubscriptions = subscriptions.filter(sub => sub.type === "Distributor" || sub.type === "ManagedBySelf");

            const manualSubscriptionsCount = this.getSubscriptionsSum(manualSubscriptions);
            const marketplaceSubscriptionsCount = this.getSubscriptionsSum(marketplaceSubscriptions);
            const distributorSubscriptionsCount = this.getSubscriptionsSum(distributorSubscriptions);
            const selfSubscriptionsCount = this.getSubscriptionsSum(selfSubscriptions);

            this.setState({
                manualLicenseCount: manualSubscriptionsCount,
                marketplaceLicenseCount: marketplaceSubscriptionsCount,
                distributorLicenseCount: distributorSubscriptionsCount,
                selfLicenseCount: selfSubscriptionsCount,
                totalLicenseCount: manualSubscriptionsCount + marketplaceSubscriptionsCount + distributorSubscriptionsCount + selfSubscriptionsCount,
    
                manualSubscriptions: manualSubscriptions,
                marketplaceSubscriptions: marketplaceSubscriptions,
                distributorSubscriptions: distributorSubscriptions,
                selfSubscriptions: selfSubscriptions,
            });
        }
        catch (err) {
            // because of refresh no-longer setting error.
            console.log(`[getTenant] Error retrieving the subscriptions: ${err}`);
            //this.props.notificationContext.setCommunicationFailureNotification();
        }
    }

    getSubscriptionsSum = (subscriptions) => {
        let subscriptionSum = 0;
        for (var sub of subscriptions) {
            //if (sub.enabled) {
                subscriptionSum += sub.quantity;
            //}
        }
        return subscriptionSum;
    }

    getDistributor = async () => {
        console.log("Start retrieving distributor...");

         try {
            console.log("[getTenant] Invoking the back-end API to get the distributor.");

            const distributor = await apiCalls.getDistributor(this.props.authenticationContext);

            this.setState({
                distributor: distributor,
                tenantType: distributor.mayCreateDistributors ? "distributor" : "reseller",
                isReseller: !distributor.mayCreateDistributors,
                // hasOwnLicenses: distributor.licensesDistributor > 0,
            });
        }
        catch (err) {
            console.log(`[getDistributor] Error retrieving the distributor information: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
        finally {
            this.setState({
                isLoading: false, 
            });
        }
    }

    getTenantLogo = async () => {
        try {
            console.log("[getTenantLogo] Invoking the back-end API to get the distributor logo.");

            const tenantLogo = await apiCalls.getTenantLogo(this.props.authenticationContext);

            this.setState({
                tenantLogo: URL.createObjectURL(tenantLogo)
            });
        }
        catch (err) {
            if (err.statusCode === 404)
            {
                console.log("[getTenantLogo] No distributor logo was set, using CyberTwice logo.");
                this.setState({
                    tenantLogo: "./images/cybertwice_logo_v2_notext.svg"
                });
                return;
            }
            console.log(`[getTenantLogo] Error retrieving distributor logo: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
        }
    }
    
    render() {
        return (
            <TenantContext.Provider value={this.state}>
                {this.props.children}
            </TenantContext.Provider>
        )
    }
}

export const TenantProvider = (props) => {
    const authenticationContext = useContext(AuthenticationContext);       
    const notificationContext = useContext(NotificationContext);
  
    return (
        <TenantProviderInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}