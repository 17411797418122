import { Component, Fragment, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup  from 'react-bootstrap/InputGroup';
import { Button } from 'reactstrap';
import { DeleteButton } from './DeleteButton';
import { CallGroupSupervisor } from './CallGroupSupervisor';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';

import * as apiCalls from '../logic/apiCalls';
import * as SipHelper from '../utils/sipHelper';

import css from './ModalEditCallGroupSupervisors.module.scss';

// The maximum allowed number of supervisors in a multi-ring group.
const maximumNumberOfSupervisors = 15;

export class ModalEditCallGroupSupervisorsInternal extends Component {
    state = {
        originalSupervisors: [],
        updatedSupervisors: [],
        newSupervisorUsername: "",
        newSupervisorDomainName: this.props.verifiedDomains[0].name,
        showUsernameWarning: false
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }

    setInitialValues = async () => {
        this.setState(
            {
                originalSupervisors: this.props.callGroup.supervisors.map(sup=>sup), 
                updatedSupervisors: this.props.callGroup.supervisors.map(sup=>sup),
                newSupervisorUsername: "",
                newSupervisorDomainName: this.props.verifiedDomains[0].name,
                showUsernameWarning: false
            }
        );
    }

    handleSupervisorAdd = async () => {
        var supervisorTeamsUsername = this.createSupervisorAddress();

        try {
            console.log("[handleSupervisorAdd] Invoking the back-end API to verify if the added supervisor exists in this tenant.");

            const resolvedUser = await apiCalls.resolveUsername(this.props.authenticationContext, supervisorTeamsUsername);
            
            if (!resolvedUser.resolved) {
                this.setState(
                    {
                        showUsernameWarning: true,   
                    }
                );                
                return;
            }
        }
        catch (err) {  
            console.log(`[handleSupervisorAdd] Error resolving username ${supervisorTeamsUsername}: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();
            return;
        }

        const supervisor = {
            teamsUserName: supervisorTeamsUsername,
        };

        this.setState(prevState => ({
            updatedSupervisors: [...prevState.updatedSupervisors, supervisor],
            newSupervisorUsername: "",
        }));
    }

    handleSupervisorDelete = (supervisortoDelete) => {
        let updatedSupervisors = this.state.updatedSupervisors.filter(sup => sup.teamsUserName !== supervisortoDelete.teamsUserName);

        this.setState({
            updatedSupervisors: updatedSupervisors,
        });
    }
    
    handleSupervisorNameChange = (e) => {
        const trimmedInput = e.target.value.trim();

        if (trimmedInput.length > 0 && !SipHelper.isValidUsername(trimmedInput)) {
            return;
        }

        this.setState({
            newSupervisorUsername: trimmedInput,
            showUsernameWarning: false
        });
    }

    handleSupervisorDomainSelectionChange = (e) => {
        this.setState({
            newSupervisorDomainName: e.target.value
        });
    }

    handleUpdate = () => {
        this.props.onUpdate(this.props.callGroup, this.state.updatedSupervisors);        
    }

    createSupervisorAddress = () => {
        return `${this.state.newSupervisorUsername}@${this.state.newSupervisorDomainName}`;
    }

    // The Add (supervisor entry) should only be enabled when the provided address is a valid SIP address,
    // there is no existing entry with the same name and there are no more than 15 existing supervisor in the multi-ring group.
    shouldAddButtonBeEnabled = () => {
        return this.getAddButtonTooltip() === null;
    }

    shouldUpdateButtonBeEnabled = () => {
        var orignalSupervisors = this.state.originalSupervisors.map(sup => sup.teamsUserName).join(",");
        var updatedSupervisors = this.state.updatedSupervisors.map(sup => sup.teamsUserName).join(",");

        return orignalSupervisors !== updatedSupervisors;
    }

    getAddButtonTooltip = () => {
        let supervisorAddress = this.createSupervisorAddress();

        if (!SipHelper.isSipAddressValid(supervisorAddress)) {
            return "Enter valid username";
        }

        const supervisorAddressLowerCase = supervisorAddress.toLowerCase();
        if (this.state.updatedSupervisors.some(sup => sup.teamsUserName?.toLowerCase() === supervisorAddressLowerCase)) {
            return "Username already exists";
        }

        if (this.props.callGroup.supervisors.length >= maximumNumberOfSupervisors) {
            return "Maximum number of supervisors reached";
        }

        return null;
    }

    render()
    {
        return <>
            <Modal size="lg" style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide} data-backdrop="static" data-keyboard="false">
                <Modal.Header>
                    <Modal.Title>Define supervisors of multi-ring group '{this.props.callGroup.name}'</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h5>
                        Current supervisors
                    </h5>
                    <table className="table table-striped table-bordered my-4">
                        <thead className="thead-dark">
                            <tr key="modalSupervisorsHeader">
                                <th scope="col">Teams username</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.updatedSupervisors.map((supervisor) => {
                                return <tr key={supervisor.id}>
                                    <td className="align-middle">
                                        <CallGroupSupervisor supervisor={supervisor} />
                                    </td>
                                    <td className="align-middle">
                                        <DeleteButton tooltip="Delete supervisor" onClick={() => this.handleSupervisorDelete(supervisor)} />
                                    </td>
                                </tr>
                            })
                            }
                        </tbody>
                    </table>     
                    <Form className="mt-4" onSubmit={e => e.preventDefault()}>
                        <Form.Group as={Row} controlId="newCallGroupSupervisor">
                            <Col sm="4">
                                <InputGroup>
                                    <Form.Control type="text" value={this.state.newSupervisorUsername} autoComplete="off" onChange={this.handleSupervisorNameChange} />
                                </InputGroup>    
                            </Col>
                            <Col className="" sm="0">
                                <h4>@</h4>
                            </Col>
                            <Col sm="4">
                                <Form.Control as="select" onChange={this.handleSupervisorDomainSelectionChange}>
                                    {this.props.verifiedDomains.map((domain) => {
                                        return <option key={domain.name} value={domain.name}>{domain.name}</option>
                                    })}                        
                                </Form.Control>
                            </Col>    
                            <ButtonWithDisabledTooltip color='primary' size='sm' disabled={!this.shouldAddButtonBeEnabled()} disabledTooltip={this.getAddButtonTooltip()} onClick={this.handleSupervisorAdd}>
                                Add supervisor
                            </ButtonWithDisabledTooltip>                                                
                        </Form.Group>
                        <Form.Group>
                            {this.state.showUsernameWarning ?
                                <Form.Text className={`${css.warningText}`}>
                                    Username does not exist in your organization
                                </Form.Text> :
                                <Fragment/>}           
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleUpdate} disabled={!this.shouldUpdateButtonBeEnabled()}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}

// Wrap the wrapped component so that multiple contexts are available.
export const ModalEditCallGroupSupervisors = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
      
    return (
        <ModalEditCallGroupSupervisorsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}