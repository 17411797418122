import { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthenticationContext, authenticationStates, authorizationStates } from '../msal/AuthenticationContext';
import { ErrorHandlingContext } from '../context/ErrorHandlingContext';
import { NotificationContext } from '../context/NotificationContext';
import { marketPlaceHelper } from '../utils/marketPlaceHelper';
import * as apiCalls from '../logic/apiCalls';

import sharedCss from './Shared.module.scss';

// The possible states that a MarketPlace subscription can have.
const saasSubscriptionStatus = {    
    NOTSTARTED: 0,
    PENDINGFULFILLMENTSTART: 1,
    SUBSCRIBED: 2,
    SUSPENDED: 3,
    UNSUBSCRIBED: 4
}

export const LandingPage = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const errorHandlingContext = useContext(ErrorHandlingContext);
    const notificationContext = useContext(NotificationContext);

    const [subscriptionActivated, setSubscriptionActivated] = useState();
    const [subscription, setSubscription] = useState();
    const [redirectToLicenseOverview, setRedirectToLicenseOverview] = useState();    

    const subscriptionIdRef = useRef(null);
    
    const getSubscriptionFromToken = useCallback(async () => {
        if (!authenticationContext.isAuthorized()) {
            console.log('[LandingPage, getSubscriptionFromToken] User is not authorized yet, no work to do...');            
            return;
        }
        
        try {
            console.log('[LandingPage, getSubscriptionFromToken] User is authorized, retrieving token from URL...');
            const tokenValue = marketPlaceHelper.getMarketPlaceTokenFromUrl();

            console.log("[LandingPage, getSubscriptionFromToken] Marketplace token retrieved from URL, invoking the back-end API to resolve this token to a subscription.");
            const subscription = await apiCalls.resolveMarketplaceToken(authenticationContext, tokenValue);
            
            if (subscription.saasSubscriptionStatus === saasSubscriptionStatus.PENDINGFULFILLMENTSTART) {
                subscriptionIdRef.current = subscription.id; // Store the id which will be used during the activation step.
                setSubscription(subscription);
            } else {
                setRedirectToLicenseOverview(true);
            }
        }
        catch (err) {
            errorHandlingContext.setError(`Error retrieving the subscription with token. ${err.toDetailedMessage()}`, err);
        }
    }, [authenticationContext, errorHandlingContext])   
    
    const activateSubscription = async () => {
        try {
            console.log("[activateSubscription] Activating the subscription!");

            await apiCalls.activateSubscription(authenticationContext, subscriptionIdRef.current);
            setSubscriptionActivated(true);
        }
        catch (err) {
            notificationContext.setNotification(`Error`, `Error activating the subscription. ${err.toDetailedMessage()}`, 'danger');               
        }
    }

    useEffect(() => {
        getSubscriptionFromToken();
    }   
    , [getSubscriptionFromToken]);

    useEffect(() => {
        if (subscriptionActivated) {
            const id = setTimeout(() => setRedirectToLicenseOverview(true), 3000);

            return () => {
                clearTimeout(id);
            }
        }
    }, [subscriptionActivated])

    useEffect(() => {
        if (authenticationContext.getAuthenticationState() === authenticationStates.NOT_AUTHENTICATED) {
            console.log('[LandingPage] User is not authenticated and no login procedure is currently active, invoking loginFromMarketplace method...');
            try {
                authenticationContext.loginFromMarketplace();   
            }
            catch (err)
            {
                errorHandlingContext.setError("Error logging in from marketplace.", err);
            }
        }
    });

    if (authenticationContext.authorizationState === authorizationStates.UNAUTHORIZED) {
        console.log("[LandingPage] User is unauthorized, redirecting to 'unauthorized' page.");
        // The authorization is finished and this user is not authorized to use this application.

        // This is an edge case scenario, because this only happens when an unauthorized user is already logged-in and has directly opened this landingspage. 
        // Normally a user first needs to log-in and will be on the post-login page while the authentication/authorization is being finished. In that case an unauthorizes user
        // will be redirected to the unauthorized page by the post-login page.
        return <Redirect to='/unauthorized'/>
    }

    if (!authenticationContext.isAuthenticated()) {
        // Show the spinner while redirecting to Microsoft...
        return (<div className="d-flex justify-content-center align-middle mt-5">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>)
    }
  
    if (redirectToLicenseOverview) {
        return <Redirect to='/portal/license-overview' />
    }    

    const pageHeader = (
        <div className="page-header d-flex">
            <div className="p-4">
                <img className={sharedCss.logo} src="./images/cybertwice_logo_v2_notext.svg" alt="Cybertwice logo" />
            </div>
        </div>
    );       

    if (!subscription) {
        return (
            <Container>
                <div className={sharedCss.background}>
                    {pageHeader}
                    <div className="mx-3">
                        Getting your subscription details...
                    </div>
                </div> 
            </Container>            
        );
    }

    return (
        <Container>
            <div className={sharedCss.background}>
                {pageHeader}

                <div className="mx-3">
                    <h1>Subscription details</h1>
                    <Form>
                        <Form.Group as={Row} controlId="formSubscriptionName">
                            <Form.Label column sm={2}>
                                Name
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" readOnly value={subscription.name} />
                            </Col>                            
                        </Form.Group>
                        <Form.Group as={Row} controlId="formSubscriptionPlan">
                            <Form.Label column sm={2}>
                                Plan
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" readOnly value={subscription.planId} />
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row} controlId="formSubscriptionQuantity">
                            <Form.Label column sm={2}>
                                Quantity
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" readOnly value={subscription.quantity} />
                            </Col>                        
                        </Form.Group>
                        <Form.Group as={Row} controlId="formSubscriptionInitiator">
                            <Form.Label column sm={2}>
                                Initator
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" readOnly value={subscription.purchaser.emailId} />
                            </Col>                            
                        </Form.Group>
                        
                        <Alert className="mt-3" variant="info" style={{opacity: 1}}>
                            Please activate the subscription by clicking the 'Activate' button below. After activation you will be redirected to the management portal where you can configure the CyberGate service.
                        </Alert>        
                    </Form>
                    <Button variant='primary' className='mb-3' onClick={activateSubscription}>Activate</Button>

                    {subscriptionActivated ? <strong className="mx-3">Subscription successfully activated!</strong> : null}
                </div>
            </div>
        </Container>       
    );
};


