import { Component, useContext } from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

import { InfoIcon }  from './InfoIcon';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import * as apiCalls from '../logic/apiCalls';

class TenantTrunkConfigInternal extends Component {
    static contextType = AuthenticationContext;

    state = {
        isLoading: true,
        trunks: [],
        isConfirmChangeTrunkSupportModalOpen: false,
    }

    async componentDidMount() {
        this.getTrunks();
    }

    showConfirmChangeTrunkSupportModal = () => {
        this.setState({
            isConfirmChangeTrunkSupportModalOpen: true,
        });
    };

    hideConfirmChangeTrunkSupportModal = () => {
        this.setState({
            isConfirmChangeTrunkSupportModalOpen: false,
        });
    };

    executeTrunkSupportChange = () => {
        this.props.onTrunkSupportChange();

        this.hideConfirmChangeTrunkSupportModal();
    };

    getTrunks = async () => {
        try {
            console.log("[getTrunks] Invoking the back-end API to get all trunks of this tenant.");

            const trunks = await apiCalls.getTrunks(this.props.authenticationContext);

            this.setState({
                trunks: trunks,
            });               
        }
        catch (err) {  
            console.log(`[getTrunks] Error retrieving the trunks: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification(); 
        }
        finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    render() {
        const trunkSupportEnabled = this.props.tenantInfo.trunkSupportEnabled;

        const confirmChangeTrunkSupportModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmChangeTrunkSupportModalOpen} onHide={this.hideConfirmChangeTrunkSupportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {trunkSupportEnabled ?
                        <>
                            Are you sure you want to disable trunk support?
                        </> :
                        <>
                            Are you sure you want to enable trunk support?
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmChangeTrunkSupportModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.executeTrunkSupportChange}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        const headerRendered = 
            <div>
                <h4>
                    Trunk Support <InfoIcon tooltip='This setting defines whether the CyberGate will support SIP trunk configuration.' />
                </h4>
            </div>
        
        const trunkSupportText = trunkSupportEnabled ?
            "SIP Trunk support is enabled" :
            "SIP Trunk support is disabled";

        const trunksupportDescription = "SIP Trunk support is necessary when your intercoms will be connected to the CyberGate service via a single SIP device. This is an advanced feature that most users don't need. Currently CyberGate only supports the 'Commend VirtuoSIS' SIP trunks. Please contact CyberTwice when you have questions regarding this feature."
        const trunkSupportButtonText = trunkSupportEnabled ?
            "Disable trunk support" :
            "Enable trunk support";
        const buttonRendered = this.state.isLoading ? null : <ButtonWithDisabledTooltip className="mb-3" color="primary" disabled={trunkSupportEnabled && this.state.trunks?.length > 0} disabledTooltip="Cannot be disabled while there are trunks defined" onClick={this.showConfirmChangeTrunkSupportModal}>{trunkSupportButtonText}</ButtonWithDisabledTooltip>;

        const trunkSupportSectionRendered =  
            <div>
                {headerRendered}
                <p/>
                <p>{trunkSupportText}</p>
                <p>{trunksupportDescription}</p>                        
                {buttonRendered}
            </div>

        return (
            <div>
                {confirmChangeTrunkSupportModalRendered}
                {trunkSupportSectionRendered}
            </div>
            );
    }
}

export const TenantTrunkConfig = (props) => {
    const authenticationContext = useContext(AuthenticationContext);  
    const notificationContext = useContext(NotificationContext);
  
    return (
        <TenantTrunkConfigInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}