import { Component, Fragment } from 'react';
import { AccessDeviceEntry } from './AccessDeviceEntry'; 
import { DeviceEntryLocationHeader } from './DeviceEntryLocationHeader';

export class AccessDeviceEntries extends Component {
    // Ensure undefined, null or empty string are all treated as null value. This ensures that all
    // these variations are grouped together and are displayed last.
    determineLocationForGrouping = (location) => {
        if (!location || location.length === 0) {
            return null;
        }

        return location;
    }

    determineLocationDisplayName = (location) => {
        if (!location || location.length === 0) {
            return "Other locations";
        }

        return location;
    }    

    renderDevices = () => {
        const uniqueLocations = this.props.devices.map(item => this.determineLocationForGrouping(item.location)).filter((v, i, a) => a.indexOf(v) === i).sort();

        const devicesRendered = uniqueLocations.map(location => {
            const devicesWithLocation = this.props.devices.filter((v, i, a) => this.determineLocationForGrouping(v.location) === location);

            const devicesOfLocationRendered = devicesWithLocation.map(device => {
                return <AccessDeviceEntry key={device.deviceId} device={device} onUpdate={this.props.onUpdate}/>
            });

            return <Fragment key={this.determineLocationDisplayName(location)}>
                <tbody>
                    <DeviceEntryLocationHeader key="header" location={this.determineLocationDisplayName(location)}/>
                </tbody>
                <tbody>
                    <tr/>
                    {devicesOfLocationRendered}
                </tbody>
            </Fragment>
        });
        
        return <Fragment>
                {devicesRendered}
            </Fragment>;
    }

    render () {
        return (
            <div>
                <table className="table table-striped table-bordered my-2">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Device display name</th>
                            <th scope="col">Microsoft group(s)</th>
                        </tr>
                    </thead>
                    {this.renderDevices()}
                </table>           
            </div>
        );
    }
}