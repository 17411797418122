import { Component, useContext } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { UserLogin } from '../UserLogin';
import { TenantContext } from '../../context/TenantContext';

import css from './TopMenu.module.scss';

class TopMenuInternal extends Component {
  //static displayName = TopMenu.name;

  constructor (props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  async componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.collapsed && prevState.collapsed){
      this.setState({collapsed: false})
    }
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    const logoRendered = this.props.tenantContext.tenantLogo === null ? 
      null : 
      <img className="d-inline-block align-center tenant-logo" src={this.props.tenantContext.tenantLogo} alt="Distributor logo"></img>

    return (
      <header className={css.navMenu}>
        <Navbar className={`${css.topbar} navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3`} color="light" light expand="lg">
          <Container fluid={true}>
            <NavbarBrand className={`${css.navLogo} text-left`} >
              {logoRendered}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="ml-auto" />
            <Collapse className={`${css.navbarUser}`} style={{display: this.state.collapsed ? 'block' : ''}} navbar>
            <ul className="navbar-nav ml-auto">
              <UserLogin isTopMenuOpen={this.state.collapsed} {...this.props} />
            </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

// Wrap the component so that multiple contexts are available via the props.
export const TopMenu = (props) => {
  const tenantContext = useContext(TenantContext);

  return (
      <TopMenuInternal tenantContext={tenantContext} {...props}/>
  )
}
