import { Component } from 'react';

export class Pager extends Component {

    pageClick = (pageNumber) => {
        console.log(`pageClick: ${pageNumber}`);
        if (this.props.loadPage) {
            this.props.loadPage(pageNumber);
        }
    }

    getPage = (pageNumber) => {
        const currentPage = this.props.currentPage;

        return <li className={currentPage === pageNumber ? "page-item active" : "page-item"}><button className="page-link" onClick={() => this.pageClick(pageNumber)}>{pageNumber}</button></li>
    }

    getPages = () => {
        const pageCount = this.props.pageCount;
        const currentPage = this.props.currentPage;
        const separator = <li className="page-item disabled"><a className="page-link" tabindex="-1" aria-disabled="true">...</a></li>;

        if (pageCount <= 1) {
            return null;
        } else if (pageCount <= 2) {
            return <>
                {this.getPage(1)}
                {this.getPage(2)}
            </>;
        } else if (pageCount <= 3) {
            return <>
                {this.getPage(1)}
                {this.getPage(2)}
                {this.getPage(3)}
            </>;
        } else if (currentPage <= 2) {
            return <>
                {this.getPage(1)}
                {this.getPage(2)}
                {separator}
                {this.getPage(pageCount)}
            </>;
        } else if (currentPage >= (pageCount - 1)) {
            return <>
                {this.getPage(1)}
                {separator}
                {this.getPage(pageCount-1)}
                {this.getPage(pageCount)}
            </>;
        } else {
            return <>
                {this.getPage(1)}
                {separator}
                {this.getPage(currentPage)}
                {separator}
                {this.getPage(pageCount)}
            </>;
        }
    }

    render() {
        const pageCount = this.props.pageCount;
        const currentPage = this.props.currentPage;

        const previousRendered = pageCount > 1 ? <li className={currentPage > 1 ? "page-item" : "page-item disabled"}><button className="page-link" onClick={() => this.pageClick(currentPage - 1)}>Previous</button></li> : null;
        const nextRendered = pageCount > 1 ? <li className={currentPage < pageCount ? "page-item" : "page-item disabled"}><button className="page-link" onClick={() => this.pageClick(currentPage + 1)}>Next</button></li> : null;

        return <nav aria-label="Page navigation example">
            <ul className="pagination">
                {previousRendered}
                {this.getPages()}
                {nextRendered}
            </ul>
        </nav>
    }
}
