import { Component, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup  from 'react-bootstrap/InputGroup';
import { Alert } from 'react-bootstrap';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';
import { CallGroupParticipants } from './CallGroupParticipants';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import { DeleteButton } from '../components/DeleteButton';
import { EditButton } from '../components/EditButton';
import { CopyButton } from '../components/CopyButton';
import * as SipHelper from '../utils/sipHelper';
import * as apiCalls from '../logic/apiCalls';

import css from './MultiringGroup.module.scss';

// The maximum allowed number of participants (multi-ring group participants) in a multi-ring group.
const maximumNumberOfParticipants = 15;

class MultiringGroupInternal extends Component {
    state = {
        newParticipantUsername: "",
        newParticipantDomainName: this.props.verifiedDomains[0].name,
        multiringGroup: this.props.multiringGroup,
        participants: this.props.multiringGroup.participants,
    }

    getParticipants = async () => {
        try {
            console.log("[getParticipants] Invoking the back-end API to get all participants of this multi-ring group.");

            const multiringGroup = await apiCalls.getMultiringGroup(this.props.authenticationContext, this.props.multiringGroup.callGroupId);

            this.setState({
                participants: multiringGroup.participants
            });
        }
        catch (err) {  
            console.log(`[getEnties] Error retrieving the multi-ring group ${this.props.multiringGroup.callGroupId}: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification(); 
        }
    }

    handleMultiringGroupParticipantDelete = (multiringGroupParticipant) => {
        this.deleteMultiringGroupParticipant(multiringGroupParticipant);        
    }

    deleteMultiringGroupParticipant = async (multiringGroupParticipant) => {
        try {
            console.log(`[deleteMultiringGroupParticipant] Invoking the back-end API to delete the multi-ring group participant with Id '${multiringGroupParticipant.participantId}' from multi-ring group with Id '${this.props.multiringGroup.callGroupId}'.`);

            await apiCalls.deleteCallGroupParticipant(this.props.authenticationContext, this.props.multiringGroup.callGroupId, multiringGroupParticipant.participantId);

            this.props.notificationContext.setNotification(`Deleting multi-ring group participant.`, `'${multiringGroupParticipant.teamsUserName}' successfully deleted from multi-ring group '${this.props.multiringGroup.name}'.`, 'success');
                
            this.getParticipants();
        }
        catch (err) {   
            this.props.notificationContext.setNotification(`Deleting multi-ring group participant.`, `Error deleting '${multiringGroupParticipant.teamsUserName}' from multi-ring group '${this.props.multiringGroup.name}'. ${err.toDetailedMessage()}`, 'danger');                     
        }
    }
    
    handleParticpantNameChange = (e) => {
        const trimmedInput = e.target.value.trim();

        if (trimmedInput.length > 0 && !SipHelper.isValidUsername(trimmedInput)) {
            return;
        }

        this.setState({
            newParticipantUsername: trimmedInput
        });
    }

    handleParticpantDomainSelectionChange = (e) => {
        this.setState({
            newParticipantDomainName: e.target.value
        });
    }

    createParticipantAddress = () => {
        return `${this.state.newParticipantUsername}@${this.state.newParticipantDomainName}`;
    }

    handleParticipantAdd = async () => {        
        const teamsUserName = this.createParticipantAddress();

        try {
            console.log(`[handleParticipantAdd] Invoking the back-end API to add a multi-ring group entry with for participant '${teamsUserName}' from multi-ring group with Id '${this.props.multiringGroup.callGroupId}'.`);

            await apiCalls.addCallGroupParticipant(this.props.authenticationContext, this.props.multiringGroup.callGroupId, teamsUserName);

            this.props.notificationContext.setNotification(`Adding multi-ring group participant.`, `'${teamsUserName}' successfully added to multi-ring group '${this.props.multiringGroup.name}'.`, 'success');

            this.getParticipants();
            this.setState({
                newParticipantUsername: ""
            });
        }
        catch (err) {
            if (err.statusCode === 422)
            {
                this.props.notificationContext.setNotification(`Adding multi-ring group participant.`, `User '${teamsUserName}' is not known in the Active Directory of your organization.`, 'danger');
            }
            else
            {
                this.props.notificationContext.setNotification(`Adding multi-ring group participant.`, `Error adding '${teamsUserName}' to multi-ring group '${this.props.multiringGroup.name}'. ${err.toDetailedMessage()}`, 'danger');
            }
        }
    }

    // The Add participant (multi-ring group entry) should only be enabled when the provided address is a valid SIP address,
    // there is no existing entry with the same name and there are no more than 10 existing participants in the multi-ring group.
    shouldAddButtonBeEnabled = () => {
        return this.getAddButtonTooltip() === null;
    }

    getAddButtonTooltip = () => {
        let participantAddress = this.createParticipantAddress();

        if (!SipHelper.isSipAddressValid(participantAddress)) {
            return "Enter valid username";
        }

        const participantAddressLowerCase = participantAddress.toLowerCase();
        if (this.state.participants.some(entry => entry.teamsUserName.toLowerCase() === participantAddressLowerCase)) {
            return "Username already exists";
        }

        if (this.state.participants.length >= maximumNumberOfParticipants) {
            return "Maximum number of users reached";
        }

        return null;
    }

    copyToClipboard = (valueToCopy) => {
        navigator.clipboard.writeText(valueToCopy);
    }

    render () {
        const renderedCallGroupParticipants = this.state.participants.length > 0 ?
            <CallGroupParticipants participants={this.state.participants} handleParticipantDelete={this.handleMultiringGroupParticipantDelete} /> :
            <Alert variant='warning' transition={false}>
                This multi-ring group does not yet contain any participants.
            </Alert>

        return (
            <div className={`card bg-light mt-5 ${css.myCardWidth}`}>
                <div className="card-body m-6">
                    <DeleteButton className="float-right" tooltip="Delete multi-ring group" onClick={() => this.props.handleDelete(this.props.multiringGroup)}/>
                    <EditButton className="float-right mr-1" tooltip="Edit" onClick={() => this.props.handleEdit(this.props.multiringGroup)} />
                    <h5 className="card-title">{this.props.multiringGroup.name}
                        <CopyButton className="ml-3" tooltip="Copy SIP address" onClick={() => this.copyToClipboard(this.props.multiringGroup.name)}/>
                    </h5>

                    <p>{this.props.multiringGroup.description}</p>
                    {renderedCallGroupParticipants}
                    <Form className="mt-4" onSubmit={e => e.preventDefault()}>
                        <Form.Group as={Row} controlId="newMultiringGroupPArticipant">
                            <Col sm="4">
                                <InputGroup>
                                    <Form.Control type="text" value={this.state.newParticipantUsername} autoComplete="off" onChange={this.handleParticpantNameChange} />
                                </InputGroup>
                            </Col>
                            <Col className="" sm="0">
                                <h4>@</h4>
                            </Col>
                            <Col sm="3">
                                <Form.Control as="select" onChange={this.handleParticpantDomainSelectionChange}>
                                    {this.props.verifiedDomains.map((domain) => {
                                        return <option key={domain.name} value={domain.name}>{domain.name}</option>
                                    })}                        
                                </Form.Control>
                            </Col>    
                            <ButtonWithDisabledTooltip color='primary' size='sm' disabled={!this.shouldAddButtonBeEnabled()} disabledTooltip={this.getAddButtonTooltip()} onClick={this.handleParticipantAdd}>Add</ButtonWithDisabledTooltip>
                        </Form.Group>
                    </Form>                
                </div>
            </div>
        )
    }
}

export const MultiringGroup = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
  
    return (
        <MultiringGroupInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}