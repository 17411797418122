import { Component } from 'react';
import { MarketplaceReportPayment } from './MarketplaceReportPayment';

import css from './MarketplaceReport.module.scss';

export class MarketplaceReportLine extends Component {
    render() {
        const reportLine = this.props.reportLine;
        const lastTransaction = this.props.lastTransaction;
        const showDetails = this.props.showDetails;
        const showExpectedPayments = this.props.showExpectedPayments;
        const showActualPayments = this.props.showActualPayments;
        const markDifferences = this.props.markDifferences;
        const disableMarking = reportLine.tenantEnabled ? "" : css.tableCellOrange

        const detailColumns = showDetails ? 
            <>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.tenantId}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.tenantEnabled ? "Yes" : "No"}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.saasSubscriptionStatus}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.autoRenew ? "Yes" : "No"}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.isFreeTrial ? "Yes" : "No"}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.emailAddress}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.subscriptionName}
                </td>
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.subscriptionId}
                </td>
            </>
            : null;

        return (
            <tr height="70px">
                <td className={`align-middle ${disableMarking}`}>
                    {reportLine.tenantLabel}
                </td>
                {detailColumns}
                <td className="align-middle">
                    {reportLine.creationTime}
                </td>
                <td className="align-middle">
                    {reportLine.paymentPeriod}
                </td>
                <td className="align-middle">
                    {reportLine.enabled ? reportLine.quantity : "-"}
                </td>
                <td className="align-middle">
                    {reportLine.recordingEnabled ? "Yes" : "No"}
                </td>
                {reportLine.months.map((value, index) => {
                    return <MarketplaceReportPayment key={index} reportLine={reportLine} month={value} lastTransaction={lastTransaction} showExpectedPayments={showExpectedPayments} showActualPayments={showActualPayments} markDifferences={markDifferences}/>
                })}  
            </tr>
        )
    }
}