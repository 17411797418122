import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './InfoIcon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid'


export const InfoIcon = ({tooltip}) => {

    return (
        <OverlayTrigger 
            placement='right'
            overlay={
                <Tooltip>
                    {tooltip}
                </Tooltip>
            }
        >
            <span className="ml-2 info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
            </span>
        </OverlayTrigger>        
    )     
}