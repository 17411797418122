import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'reactstrap';
import * as SipHelper from '../utils/sipHelper';
import { isValidMeetingUrl } from '../logic/meeting';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';

const descriptionRegex = new RegExp("^[0-9a-zA-zÀ-ž\\s\\-_@.!(),]+$");

export class ModalMeetingAddOrUpdate extends Component {
    meetingNameStableTimer = null;

    state = {
        meetingName: "",
        meetingDescription: "",
        meetingUrl: "",
        nameAvailable: null,
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }
    
    async componentWillUnmount() {
        clearTimeout(this.meetingNameStableTimer);
    }

    setInitialValues = () => {
        const isUpdate = this.props.isUpdate;

        this.setState(
            {
                meetingName: isUpdate ? this.props.meetingToUpdate.name.split('@')[0] : "",
                meetingDescription: isUpdate ? this.props.meetingToUpdate.description : "",
                meetingUrl: isUpdate ? this.props.meetingToUpdate.teamsMeetingUrl : "",
            }
        );
    }

    // The name that is provided for the new meeting will be used as the 'username' part
    // of the Sip address that will be entered in 'To' address of the device.
    // For this reason the provided name has to be a valid Sip username.
    handleMeetingNameChanged = (e) => {
        const trimmedInput = e.target.value.trim();

        if (trimmedInput.length > 0 && !SipHelper.isValidUsername(trimmedInput)) {
            return;
        }

        this.setState({
            nameAvailable: null,
            meetingName: trimmedInput,
        });

        clearTimeout(this.meetingNameStableTimer);
        this.meetingNameStableTimer = setTimeout(async () => {
            const nameAvailable = await this.props.checkNameAvailableFunction(this.state.meetingName);
            this.setState({
                nameAvailable: nameAvailable,
            });
        }, 500);
    }

    handleMeetingDescriptionChanged = (e) => {
        if (e.target.value.length > 0 && !descriptionRegex.test(e.target.value)) {
            return;
        }

        this.setState({
            meetingDescription: e.target.value,
        });
    }

    handleMeetingUrlChanged = (e) => {
        this.setState({
            meetingUrl: e.target.value,
        });
    }

    // The Save button of the new meeting modal should only be enabled when both the name and the description
    // is not empty and when the provided name is not the same as an existing meeting.
    shouldAddOrUpdateButtonBeEnabled = () => {
        return this.getAddOrUpdateButtonTooltip() === null;
    }

    getAddOrUpdateButtonTooltip = () => {
        if (this.state.meetingName.length === 0 || this.state.meetingDescription.length === 0 || this.state.meetingUrl.length === 0) {
            return "Fill fields";
        }

        if (!isValidMeetingUrl(this.state.meetingUrl)) {
            return "Enter valid Teams meeting URL";
        }
        
        if (this.props.isUpdate) {
            if (this.state.meetingName === this.props.meetingToUpdate.name.split('@')[0] &&
                this.state.meetingDescription === this.props.meetingToUpdate.description &&
                this.state.meetingUrl === this.props.meetingToUpdate.teamsMeetingUrl) {
                return "No changes detected";
            }

            // Only check when name was changed
            if (this.state.meetingName !== this.props.meetingToUpdate.name.split('@')[0] && !this.state.nameAvailable) {
                return "Meeting name is not available, please change";
            }

            return null;
        }

        if (this.state.meetingName.length > 64) {
            return "Name must be less than 64 characters";
        }

        if (this.state.meetingDescription.length > 256) {
            return "Description must be less than 256 characters";
        }

        if (this.state.meetingUrl.length > 1024) {
            return "URL must be less than 1024 characters";
        }

        if (!this.state.nameAvailable) {
            return "Meeting name is not available, please change";
        }

        return null;
    }

    onAddOrUpdateButtonClicked = () => {
        const doneInfo = {
            meetingName: this.state.meetingName,
            meetingDescription: this.state.meetingDescription,
            meetingUrl: this.state.meetingUrl,
            isUpdate: this.props.isUpdate
        }

        if (this.props.isUpdate) {
            doneInfo.callGroupId = this.props.meetingToUpdate.callGroupId
        }

        this.props.onAddOrUpdate(doneInfo);
    }

    handleCancelButtonClicked = () => {
        this.props.onHide();
    }

    pasteFromClipboard = () => {
        navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.readText().then(
                    clipText => this.setState({
                        meetingUrl: clipText,
                    })
                );
            }
        });
    }

    render() {
        const headerText = this.props.isUpdate ? 'Update meeting' : 'Add meeting';
        const doneButtonText = this.props.isUpdate ? 'Update' : 'Add';

        return <Modal size="md" style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide} centered>
            <Modal.Header>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} controlId="meetingName">
                        <Form.Label column sm="5">
                            Name
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="meetingName" name="meetingName" value={this.state.meetingName} autoComplete="off" onChange={this.handleMeetingNameChanged} />
                        </Col>                       
                    </Form.Group>
                    <Form.Group as={Row} controlId="meetingDomain">
                        <Form.Label column sm="5">
                            Domain
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="meetingDomain" readOnly value={this.props.domainName} />
                        </Col>                       
                    </Form.Group>                  
                    <Form.Group as={Row} controlId="meetingDescription">
                        <Form.Label column sm="5">
                            Description
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="meetingDescription" value={this.state.meetingDescription} autoComplete="off" onChange={this.handleMeetingDescriptionChanged} />
                        </Col>   
                    </Form.Group>
                    <Form.Group as={Row} controlId="meetingUrl">
                        <Form.Label column sm="5">
                            Teams meeting URL
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control id="meetingUrl" value={this.state.meetingUrl} autoComplete="off" onChange={this.handleMeetingUrlChanged}  />
                        </Col>
                        {/* Disabled because not supported by all browsers (2022-03-22).
                        <Col sm="1">
                            <PasteButton className="float-right" tooltip="Paste Teams meeting URL" onClick={() => this.pasteFromClipboard()}/>
                        </Col>                             */}
                    </Form.Group>                  
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.props.onHide}>Cancel</Button>
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddOrUpdateButtonBeEnabled()} disabledTooltip={this.getAddOrUpdateButtonTooltip()} onClick={this.onAddOrUpdateButtonClicked}>{doneButtonText}</ButtonWithDisabledTooltip>
            </Modal.Footer>
        </Modal>;
    }
}
