import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, CustomInput } from 'reactstrap';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ip2int } from '../../../utils/misc';
import { entryCount, rangeOverlaps } from '../../../utils/whiteListHelper';
import { WhitelistRange } from '../../../logic/apiCalls';
import { ButtonWithDisabledTooltip } from '../../../components/ButtonWithDisabledTooltip';


type ModalIpAddressAddOrUpdateProps = {
    show: boolean,
    isUpdate: boolean,
    ipAddressToUpdate: WhitelistRange | undefined,
    onHide: () => void,
    onAdd: (newIpAddress: WhitelistRange) => void,
    onUpdate: (updatedIpAddress: WhitelistRange) => void,
    whitelist: Array<WhitelistRange>,
    currentWanIpAddress: string,
    maxIpAddresses: number,
}

type ModalIpAddressAddOrUpdateState = {
    ipAddressStart: string,
    ipAddressEnd: string,
    description: string,
    location: string,
    enabled: boolean,
}

export class ModalIpAddressAddOrUpdate extends Component<ModalIpAddressAddOrUpdateProps, ModalIpAddressAddOrUpdateState> {
    partialIpV4AddressValidationRegex: RegExp;
    ipV4AddressValidationRegex: RegExp;
    displayNameValidationRegex: RegExp;

    state: ModalIpAddressAddOrUpdateState  = {
        ipAddressStart: "",
        ipAddressEnd: "",
        description: "",
        location: "",
        enabled: true,
    }

    constructor(props : ModalIpAddressAddOrUpdateProps) {
        super(props);
        
        // Build the description validation Regex.
        let nonPrintableCharStart = String.fromCharCode(0x00); // NULL char
        let nonPrintableCharEnd = String.fromCharCode(0x1F); // S7 char
        let delRegex = String.fromCharCode(0xF); // DEL char
        let backslashRegex = "\\\\"; // The parsing of the literal string will reduce this to 2 backslashes, the parsing of this string by the RegExp class will reduce it to a single literal backslash to be matched.
        let forwardslashRegex = "\\/"; 
        let bracketOpeningRegex = "\\["; 
        let bracketClosingRegex = "\\]"; 
        let quotationRegex = "\\\""; 
        let additionalCharsRegex = "!@#$%^&*()={};:";

        this.partialIpV4AddressValidationRegex = new RegExp("^[0-9\\.]{0,15}$");
        this.ipV4AddressValidationRegex = new RegExp("^(?:(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(\\.(?!$)|$)){4}$");
        this.displayNameValidationRegex = new RegExp(`^[^${nonPrintableCharStart}-${nonPrintableCharEnd}${delRegex}${backslashRegex}${forwardslashRegex}${bracketOpeningRegex}${bracketClosingRegex}${quotationRegex}${additionalCharsRegex}]*$`);
    }

    componentDidUpdate(prevProps : ModalIpAddressAddOrUpdateProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const isUpdate = this.props.isUpdate;

        if (isUpdate) {
            this.setState(
                {
                    ipAddressStart: this.props.ipAddressToUpdate ? this.props.ipAddressToUpdate.ipAddressStart : "",
                    ipAddressEnd: this.props.ipAddressToUpdate ? this.props.ipAddressToUpdate.ipAddressEnd : "",
                    description: this.props.ipAddressToUpdate ? this.props.ipAddressToUpdate.description : "",
                    location: this.props.ipAddressToUpdate ? this.props.ipAddressToUpdate.location : "",
                    enabled: this.props.ipAddressToUpdate ? this.props.ipAddressToUpdate.enabled : true,
                }
            );
        } else {
            this.setState({
                ipAddressStart: "",
                ipAddressEnd: "",
                description: "",
                location: "",
                enabled: true,
            });
        }
    }

    handleDoneButtonClicked = () => {
        const trimmedDisplayName = this.state.description.trim();
        const trimmedLocation = this.state.location?.trim() ?? '';
        const actualIpAddressEnd = this.state.ipAddressEnd ? this.state.ipAddressEnd : this.state.ipAddressStart;

        const doneInfo : WhitelistRange = {
            id: this.props.isUpdate && this.props.ipAddressToUpdate ? this.props.ipAddressToUpdate.id : 0,
            ipAddressStart: this.state.ipAddressStart,
            ipAddressEnd: actualIpAddressEnd,
            description: trimmedDisplayName,
            location: trimmedLocation,
            enabled: this.state.enabled,
        }

        if (this.props.isUpdate) {
            this.props.onUpdate(doneInfo);
        } else {
            this.props.onAdd(doneInfo);
        }
    }

    handleCancelButtonClicked = () => {
        this.props.onHide();
    }

    handleIpAddressStartChanged = (e : any) => {
        const updatedIpAddress = e.target.value;

        if (updatedIpAddress.length > 0 && !this.isPartialIpAddress(updatedIpAddress)) {
            return;
        }
                
        this.setState({
            ipAddressStart: updatedIpAddress
        });
    }

    handleIpAddressEndChanged = (e : any) => {
        const updatedIpAddress = e.target.value;

        if (updatedIpAddress.length > 0 && !this.isPartialIpAddress(updatedIpAddress)) {
            return;
        }
                
        this.setState({
            ipAddressEnd: updatedIpAddress
        });
    }

    handleDescriptionChanged = (e : any) => {
        const updatedDescription = e.target.value;

        if (updatedDescription.length > 0 && !this.displayNameValidationRegex.test(updatedDescription)) {
            return;
        }
                
        this.setState({
            description: updatedDescription
        });
    }

    handleLocationChanged = (e : any) => {
        const updatedLocation = e.target.value;

        if (updatedLocation.length > 0 && !this.displayNameValidationRegex.test(updatedLocation)) {
            return;
        }
                
        this.setState({
            location: updatedLocation
        });
    }

    toggleEnabled = () => {
        this.setState({
            enabled: !this.state.enabled
        });
    }

    doesRangeOverlap = () : boolean => {
        return rangeOverlaps(this.state.ipAddressStart, this.state.ipAddressEnd, this.props.whitelist);
    }

    maxIpAddressesReached = () : boolean => {
        const count = entryCount(this.props.whitelist);

        const newEntryStart = ip2int(this.state.ipAddressStart);

        let newEntryEnd  = newEntryStart;
        
        if (this.state.ipAddressEnd) {
            newEntryEnd = ip2int(this.state.ipAddressEnd);
        }
        
        const newEntryCount = newEntryEnd - newEntryStart + 1

        return (count + newEntryCount) >= this.props.maxIpAddresses;
    }

    isPartialIpAddress = (ipAddress : string) : boolean => {
        return this.partialIpV4AddressValidationRegex.test(ipAddress);
    }

    shouldAddOrUpdateButtonBeEnabled = () => {
        return this.getAddOrUpdateButtonTooltip() === null;
    }

    getAddOrUpdateButtonTooltip = () => {
        if (!this.props.show) {
            return null;
        }

        if (!this.props.isUpdate) {
            if (!this.ipV4AddressValidationRegex.test(this.state.ipAddressStart)) {
                return "IP address start is not complete or invalid";
            }

            if (this.state.ipAddressEnd && !this.ipV4AddressValidationRegex.test(this.state.ipAddressEnd)) {
                return "IP address end is not complete or invalid";
            }

            if (this.state.ipAddressEnd && ip2int(this.state.ipAddressEnd) < ip2int(this.state.ipAddressStart)) {
                return "IP address end is smaller than start";
            }

            if (this.doesRangeOverlap()) {
                return "IP address/range overlaps with an existing entry";
            }

            if (this.maxIpAddressesReached()) {
                return "Maximum number of IP addresses reached";
            }
        }

        if (this.state.description.length > 256) {
            return "Description must be less than 256 characters";  
        }

        if (this.state.location.length > 64) {
            return "Location must be less than 64 characters";  
        }

        if (this.state.ipAddressStart !== this.props.ipAddressToUpdate?.ipAddressStart || 
            this.state.ipAddressEnd !== this.props.ipAddressToUpdate?.ipAddressEnd || 
            this.state.description !== this.props.ipAddressToUpdate?.description || 
            this.state.location !== this.props.ipAddressToUpdate?.location || 
            this.state.enabled !== this.props.ipAddressToUpdate?.enabled) { 
                return null;
        }

        return "No changes detected";
    }

        // Determines if the 'Add current IP' button should be shown or not. 
    // Only when the WAN IP address has been requested at the back end and it is not already in the list of configured IP addresses, the button should be shown.
    shouldShowAddCurrentWanIP = () => {
        if (!this.props.currentWanIpAddress) {
            return false;
        }

        if (this.props.currentWanIpAddress && 
            (
                rangeOverlaps(this.props.currentWanIpAddress, this.props.currentWanIpAddress, this.props.whitelist) ||
                this.state.ipAddressStart ||
                this.state.ipAddressEnd
            )) {
            return false
        }

        return true;
    }

    handleAddCurrentWanIpAddress = () => {
        this.setState({
            ipAddressStart: this.props.currentWanIpAddress
        });
    }

    render() {
        const isRange = this.state.ipAddressStart !== this.state.ipAddressEnd;
        const shouldShowRange = !this.props.isUpdate || isRange;

        const headerText = this.props.isUpdate ? isRange ? 'Update an WAN IP range entry' : 'Update an WAN IP address entry' : 'Add WAN IP Address or Range';
        const doneButtonText = this.props.isUpdate ? 'Update' : 'Add';

        const ipAddressEndRendered = shouldShowRange ? 
            <Form.Group controlId="ipAddressEndFormGroup">
                <Form.Label>WAN IP Address End{this.props.isUpdate ? "" : " (optional)"}</Form.Label>
                <Row>
                    <Col sm="5">
                        <Form.Control type="text" placeholder="Enter IPv4 address" disabled={this.props.isUpdate} value={this.state.ipAddressEnd} autoComplete="off" onChange={this.handleIpAddressEndChanged} />
                    </Col>
                </Row>
            </Form.Group> :
            null;

        const addCurrentIpButtonRendered = this.shouldShowAddCurrentWanIP() ? 
            <>
                <Button color="primary" onClick={this.handleAddCurrentWanIpAddress} >Use current WAN IP ({this.props.currentWanIpAddress})</Button>
            </> : 
            null;

        const enabledSwitchRendered = this.props.isUpdate ? <Form.Group controlId="enabledFormGroup">
                <label className="my-1 mr-2" htmlFor="enableIpAddressSwitch">Enable</label>
                <CustomInput checked={this.state.enabled} type="switch" onChange={this.toggleEnabled} id="enableIpAddressSwitch" z-index={0} />
                <Form.Text className="text-muted">
                    When disabled, traffic from a device using the address/range will be blocked.
                </Form.Text>
            </Form.Group> : null;

        return <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                    <Form.Group controlId="ipAddressStartFormGroup">
                        <Form.Label>WAN IP Address{shouldShowRange ? " Start" : null}</Form.Label>
                        <Row>
                            <Col sm="5">
                                <Form.Control type="text" placeholder="Enter IPv4 address" disabled={this.props.isUpdate} value={this.state.ipAddressStart} autoComplete="off" onChange={this.handleIpAddressStartChanged} />
                            </Col>
                            <Col sm="7">
                                {addCurrentIpButtonRendered}
                            </Col>
                        </Row>
                    </Form.Group>
                    {ipAddressEndRendered}
                    <Form.Group controlId="descriptionFormGroup">
                        <Form.Label>Description (optional)</Form.Label>
                        <Form.Control type="text" placeholder="Enter description" value={this.state.description} autoComplete="off" onChange={this.handleDescriptionChanged} />
                        <Form.Text className="text-muted">
                            The IP address description is used for administrative use only
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="locationFormGroup">
                        <Form.Label>Location (optional)</Form.Label>
                        <Form.Control type="text" placeholder="Enter location" value={this.state.location} autoComplete="off" onChange={this.handleLocationChanged} />
                        <Form.Text className="text-muted">
                            The IP address location is used for administrative use only
                        </Form.Text>
                    </Form.Group>
                    {enabledSwitchRendered}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={this.handleCancelButtonClicked}>Cancel</Button>
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddOrUpdateButtonBeEnabled()} disabledTooltip={this.getAddOrUpdateButtonTooltip()} onClick={this.handleDoneButtonClicked}>{doneButtonText}</ButtonWithDisabledTooltip>{' '}
            </Modal.Footer>
        </Modal>;
    }
}