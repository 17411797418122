import React from 'react';
import { Button } from 'reactstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/fontawesome-free-solid'


export const ExpandButton = ({ className, onClick, expanded }) => {
    const expandIcon = expanded ? faChevronDown : faChevronRight;

    return (
        <OverlayTrigger
            trigger={['hover', 'hover']} // Small work-around to prevent a react-bootstrap warning that 'focus' should be added for touch and keyboard only users.
            placement='right'
            overlay={
                <Tooltip>
                    {expanded ? "collapse" : "expand"}
                </Tooltip>
            }
        >
            <Button onClick={onClick} color="secondary" size="sm" className={className}>
                <FontAwesomeIcon icon={expandIcon} />
            </Button>
        </OverlayTrigger>
    )
}