import { Component, useContext } from 'react';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { NotificationContext } from '../context/NotificationContext';
import { ExpandButton } from './ExpandButton';
import * as apiCalls from '../logic/apiCalls';

import css from './GlobalStatisticsOverview.module.scss';

export class GlobalStatisticsOverviewInternal extends Component {
    state = {
        globalTenantCount: 0,
        globalTenantCountMarketplace: 0,
        globalTenantCountDistributor: 0,
        globalTenantCountDirect: 0,
        globalTenantCountRecorded: 0,
        globalTenantCountAvailabilityUsage: 0,
        globalTenantCountDoorOpenUsage: 0,
        globalLicenseCountTotal: 0,
        globalLicenseCountMarketplace: 0,
        globalLicenseCountDistributor: 0,
        globalLicenseCountDirect: 0,
        globalDeviceCount: 0,
        globalDeviceCountActive: 0,

        tenantExpanded: false,
        licenseExpanded: false,
        deviceExpanded: false,
    }

    async componentDidMount() {
        this.getGlobalStatistics();
    }

    getGlobalStatistics = async () => {
        try {
            console.log(`[getGlobalStatistics] Invoking the back-end API to retrieve the global statistics.`);

            const globalStatistics = await apiCalls.getGlobalStatistics(this.props.authenticationContext);

            if (globalStatistics)
            {
                this.setState({
                    globalTenantCount: globalStatistics.tenantCount,
                    globalTenantCountMarketplace: globalStatistics.tenantCountMarketplace,
                    globalTenantCountDistributor: globalStatistics.tenantCountDistributor,
                    globalTenantCountDirect: globalStatistics.tenantCountDirect,
                    globalTenantCountRecorded: globalStatistics.tenantCountRecorded,
                    globalTenantCountAvailabilityUsage: globalStatistics.tenantCountAvailabilityUsage,
                    globalTenantCountDoorOpenUsage: globalStatistics.tenantCountDoorOpenUsage,
                    globalLicenseCountTotal: globalStatistics.licenseCountTotal,
                    globalLicenseCountMarketplace: globalStatistics.licenseCountMarketplace,
                    globalLicenseCountDistributor: globalStatistics.licenseCountDistributor,
                    globalLicenseCountDirect: globalStatistics.licenseCountDirect,
                    globalDeviceCount: globalStatistics.deviceCount,
                    globalDeviceCountActive: globalStatistics.deviceCountActive,
                });
            }
        }
        catch (err) {
            console.log(`[getGlobalStatistics] Error retrieving global statistics: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification(); 
        }
    }

    toggleTenantExpand = () => {
        this.setState({
            tenantExpanded: !this.state.tenantExpanded
        });
    }

    toggleLicenseExpand = () => {
        this.setState({
            licenseExpanded: !this.state.licenseExpanded
        });
    }

    toggleDeviceExpand = () => {
        this.setState({
            deviceExpanded: !this.state.deviceExpanded
        });
    }

    render() {
        const tenantExpandedRendered = this.state.tenantExpanded ? <>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Marketplace"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalTenantCountMarketplace}</th>
            </tr>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Distributor"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalTenantCountDistributor}</th>
            </tr>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Direct"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalTenantCountDirect}</th>
            </tr>
        </> : null

        const licenseExpandedRendered = this.state.licenseExpanded ? <>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Marketplace"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalLicenseCountMarketplace}</th>
            </tr>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Distributors"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalLicenseCountDistributor}</th>
            </tr>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Direct"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalLicenseCountDirect}</th>
            </tr>
        </> : null

        const deviceExpandedRendered = this.state.deviceExpanded ? <>
            <tr height="35px">
                <th scope="col"className="text-left font-weight-normal">
                    {" ⤷ Active"}
                </th>
                <th scope="col" className="text-right font-weight-normal">{this.state.globalDeviceCountActive}</th>
            </tr>
        </> : null

        return (
            <>
                <div>
                    <h4>CyberTwice Statistics</h4>
                </div>
                <table className={`table table-striped table-bordered my-2 ${css.tableSize}`} table-layout="fixed" width="800px">
                    <tbody>
                        <tr height="35px" onClick={this.toggleTenantExpand}>
                            <td className="align-middle" width="250px">
                                <div className={css.flexContainer}>
                                    <div>
                                        {"Tenant Count"}
                                    </div>
                                    <div className={css.flexItemRight}>
                                        <ExpandButton className="float-right" onClick={this.toggleTenantExpand} expanded={this.state.tenantExpanded}/>
                                    </div>
                                </div>
                            </td>
                            <td width="80px" className="text-right font-weight-normal">{this.state.globalTenantCount}</td>
                        </tr>
                        {tenantExpandedRendered}
                        <tr height="35px">
                            <td className="align-middle">
                                <div className={css.flexContainer}>
                                    <div>
                                        {"Licenses Total"}
                                    </div>
                                    <div className={css.flexItemRight}>
                                        <ExpandButton className="float-right" onClick={this.toggleLicenseExpand} expanded={this.state.licenseExpanded}/>
                                    </div>
                                </div>
                            </td>
                            <td className="text-right font-weight-normal">{this.state.globalLicenseCountTotal}</td>
                        </tr>
                        {licenseExpandedRendered}
                        <tr height="35px">
                            <td className="align-middle">
                                <div className={css.flexContainer}>
                                    <div>
                                        {"Device Count"}
                                    </div>
                                    <div className={css.flexItemRight}>
                                        <ExpandButton className="float-right" onClick={this.toggleDeviceExpand} expanded={this.state.deviceExpanded}/>
                                    </div>
                                </div>
                            </td>
                            <td className="text-right font-weight-normal">{this.state.globalDeviceCount}</td>
                        </tr>
                        {deviceExpandedRendered}
                    </tbody>
                </table>
                <br/>
                <div>
                    <h5>Feature usage</h5>
                </div>
                <table className={`table table-striped table-bordered my-2 ${css.tableSize}`} table-layout="fixed" width="800px">
                    <tbody>
                        <tr height="35px">
                            <th scope="col" width="250px" className="text-left font-weight-normal">{"Recording"}</th>
                            <th scope="col" width="80px" className="text-right font-weight-normal">{this.state.globalTenantCountRecorded}</th>
                        </tr>
                        <tr height="35px">
                            <th scope="col" width="250px" className="text-left font-weight-normal">{"Availability"}</th>
                            <th scope="col" width="80px" className="text-right font-weight-normal">{this.state.globalTenantCountAvailabilityUsage}</th>
                        </tr>
                        <tr height="35px">
                            <th scope="col" width="250px" className="text-left font-weight-normal">{"Open door button"}</th>
                            <th scope="col" width="80px" className="text-right font-weight-normal">{this.state.globalTenantCountDoorOpenUsage}</th>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }
}

export const GlobalStatisticsOverview = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
  
    return (
        <GlobalStatisticsOverviewInternal authenticationContext={authenticationContext} notificationContext={notificationContext} {...props}/>
    )
}
