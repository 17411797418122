import { FC, Fragment } from 'react';
import { WhitelistRange } from '../../../logic/apiCalls';
import { EditButton } from '../../../components/EditButton';
import { DeleteButton } from '../../../components/DeleteButton';
import * as Utils from '../../../utils/helper';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { ip2int } from '../../../utils/misc';

import css from './WhitelistEntries.module.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

interface WhitelistEntriesProps {
    entries : WhitelistRange[];
    handleDelete: (entry: WhitelistRange) => void;
    handleEdit: (entry: WhitelistRange) => void;
}

export const WhitelistEntries: FC<WhitelistEntriesProps> = ({ entries: addresses, handleDelete, handleEdit }) => {

    const tableData = addresses.map(address => {
        return {
            id: address.id,
            ipAddressStart: address.ipAddressStart,
            ipAddressEnd: address.ipAddressStart !== address.ipAddressEnd ? address.ipAddressEnd : '',
            description: address.description,
            location: address.location,
            enabled: Utils.isTrue(address.enabled) ? 'Yes' : 'No',
            action: <>
                <EditButton className="mr-1" tooltip="Edit" onClick={() => handleEdit(address)} />
                <DeleteButton className="mr-1" tooltip="Remove" onClick={() => handleDelete(address)} />
            </>
        }        
    })

    const caret = (order: any, column: any) => {
        if (!order) return (<>&nbsp;<span style={{color: 'grey'}}>&#9650;</span><span style={{color: 'grey'}}>&#9660;</span></>);
        else if (order === 'asc') return (<>&nbsp;<span style={{color: 'white'}}>&#9650;</span><span style={{color: 'grey'}}>&#9660;</span></>);
        else if (order === 'desc') return (<>&nbsp;<span style={{color: 'grey'}}>&#9650;</span><span style={{color: 'white'}}>&#9660;</span></>);
        return null;
      };
   
    const columns = [
        {
            id: 'columnIpAddress',
            dataField: 'ipAddressStart',
            text: 'IP Start',
            sort: true,
            sortCaret: caret,
            sortFunc: (a : any, b : any, order : any, dataField : any) => {
                const aNumber = ip2int(a);
                const bNumber = ip2int(b);

                if (order === 'asc') {
                  return aNumber - bNumber;
                }
                return bNumber - aNumber;
            },
            headerStyle: { width: '150px' }
        },
        {
            id: 'columnIpAddress',
            dataField: 'ipAddressEnd',
            text: 'IP End',
            headerStyle: { width: '150px' }
        },
        {
            id: 'columnDescription',
            dataField: 'description',
            text: 'Description',
            sort: true,
            sortCaret: caret,
            headerStyle: { width: '50%' }
        },
        {
            id: 'columnLocation',
            dataField: 'location',
            text: 'Location',
            sort: true,
            sortCaret: caret,
            headerStyle: { width: '50%' }
        },
        {
            id: 'columnEnabled',
            dataField: 'enabled',
            text: 'Enabled',
            sort: true,
            sortCaret: caret,
            align: 'center',
            headerStyle: { width: '100px' }
        },
        {
            id: 'columnAction',
            dataField: 'action',
            text: 'Action',       
            align: 'center',
            headerStyle: { width: '95px' }
        }];

    const defaultSorted = [{
        id: 'ipAddressdefaultSorted',
        dataField: 'ipAddressStart',
        order: 'asc'
    }] as [{ id: any; dataField: any; order: SortOrder }];

    const rowStyle = (row : any) : any => {
        const style : any = {};
        if (row.enabled !== 'Yes') {
            style.color = 'lightgrey';
        }
      
        return style;
      };

    return (
        <BootstrapTable keyField='id' data={ tableData } columns={ columns } defaultSorted={ defaultSorted } striped={true} bordered={true} hover={true} headerClasses='thead-dark' classes={css.whiteListTable} rowStyle={rowStyle}/>
    )
}