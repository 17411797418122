import { Component } from 'react';
import { Button } from 'react-bootstrap'
import TooltipButton from 'react-bootstrap-tooltip-button2';

export class ButtonWithDisabledTooltip extends Component {
    render() {
        const buttonColor = this.props.color;
        const buttonClassName = this.props.className;
        const buttonOnClick = this.props.onClick;
        const buttonDisabled = this.props.disabled;
        const buttonDisabledTooltip = this.props.disabledTooltip;

        const buttonRendered = <Button color={buttonColor} className={buttonClassName} disabled={buttonDisabled} onClick={buttonOnClick}>{this.props.children}</Button>

        if (!buttonDisabled) {
            return buttonRendered;
        }

        return <TooltipButton
            renderedButton={buttonRendered}
            tooltipPlacement='right'
            tooltipText={buttonDisabledTooltip}
            tooltipId='tt1'
        />;
    }
}