import { Component } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as Utils from '../utils/helper';
import * as Misc from '../utils/misc';
import { EditButton } from './EditButton';
import { InfoIcon }  from './InfoIcon';

import css from './DistributorEntry.module.scss';

export class DistributorEntry extends Component {
    getLabelWithMaxLength = (label, maxLenght) => {
        if (!label) {
            return null;
        }

        if (label.length <= maxLenght) {
            return <>{label}</>
        }

        const labelTrimmed = label.substring(0, maxLenght - 2).trim() + "...";

        return <>
            <OverlayTrigger 
                placement='right'
                overlay={
                    <Tooltip>
                        {label}
                    </Tooltip>
                }
            >
                <span>{labelTrimmed}</span>
            </OverlayTrigger>
        </>;
    }

    handleDistributorEdit  = () => {
        this.props.onEdit(this.props.distributor);
    }

    render() {
        const distributor = this.props.distributor;
        const colorClass = Utils.isTrue(distributor.enabled) ? css.tableCellGreen : css.tableCellRed;

        let distributorMaxQuantity = "-";
        if (Utils.isTrue(distributor.enabled)) {
            distributorMaxQuantity = Misc.intToString(distributor.maxQuantity);
        } 

        return (
            <tr>
                <td className="text-left">
                    <div className={css.flexContainer}>
                        <div>
                            {distributor.tenantLabel}
                        </div>
                        <div className={css.flexItemRight}>
                            <InfoIcon className="float-right" tooltip={`Tenant ID: ${distributor.tenantId}`} />
                        </div>
                    </div>
                </td>
                <td className="text-left">
                    <div>
                        {this.getLabelWithMaxLength(distributor.emailAddress, 50)}
                    </div>                    
                </td>
                <td className="text-center">
                    {String(distributor.creationTime).split('T')[0]}
                </td>
                <td className={`text-center ${colorClass}`}>
                    {Utils.isTrue(distributor.enabled) ? 'yes' : 'no'}
                </td>
                <td className="text-right">
                    {distributorMaxQuantity}
                    {this.props.editEnabled && <EditButton className="float-left" tooltip="Edit distributor" onClick={this.handleDistributorEdit}/> }
                </td>
                <td className="text-right">
                    {Misc.intToString(distributor.quantityRecursive)}
                </td>                     
            </tr>
        )
    }
}