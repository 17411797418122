export const isTrue = (value) => {
    if (value === undefined) {
        return false;
    }

    if (typeof(value) === "boolean") {
        return value;
    }

    if (!typeof(value) === "string") {
        return false;
    }

    const lowerCaseValue = value.toLowerCase();
    return lowerCaseValue === 'true' || lowerCaseValue === 'yes';
}

// Method ensures the provided error is an instance of the Error class. 
export const normalizeError = (err) => {
    if (err instanceof Error) {
        return err;
    }

    if (typeof (err) === 'string') {
        var errParts = err.split('|');
        const errorObject = errParts.length > 1 ?
            { message: errParts[1], debug: errParts[0] } :
            { message: err };

        const error = new Error(errorObject.message);
        error.debug = errorObject.debug;
       
        return error;
    } 

    return new Error(err.toString());
}