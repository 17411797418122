import { useContext, Component } from 'react';
import { Container } from 'reactstrap';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { NotificationContext } from '../../context/NotificationContext';
import { TrunkEntries } from '../../components/TrunkEntries';
import { ModalTrunkAddOrUpdate } from '../../components/ModalTrunkAddOrUpdate';
import { ModalTrunkConfirmDelete } from '../../components/ModalTrunkConfirmDelete';
import * as apiCalls from '../../logic/apiCalls';
import { ButtonWithDisabledTooltip } from '../../components/ButtonWithDisabledTooltip';
import * as Misc from '../../utils/misc';
import { Alert } from 'react-bootstrap';

// The maximum allowed number of trunks per tenant.
const maximumNumberOfTrunks = 10;

class TrunkSettingsInternal extends Component {
    state = {
        isLoading: true,
        trunks: [],
        trunkToUpdate: null,
        trunkToDelete: null,
        isTrunkModalOpen: false,
        isTrunkModalUpdate: false,
        isConfirmDeleteModalOpen: false,
    }

    // Get all trunks when the page is loaded.
    async componentDidMount() {
        this.getTrunks();
    }

    showAddTrunkModal = () => {
        this.setState({
            isTrunkModalUpdate: false,
            isTrunkModalOpen: true,
        });
    };

    showUpdateTrunkModal = () => {
        this.setState({
            isTrunkModalUpdate: true,
            isTrunkModalOpen: true,
        });
    };

    hideTrunkModal = () => {
        this.setState({
            isTrunkModalOpen: false,
        });
    }

    showConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: true,
        });
    };

    hideConfirmDeleteModal = () => {
        this.setState({
            isConfirmDeleteModalOpen: false,
        });
    };

    handleTrunkEdit = (trunk) => {
        this.setState({
            trunkToUpdate: trunk,
        });
        this.showUpdateTrunkModal();
    }

    handleTrunkDelete = (trunk) => {
        this.setState({
            trunkToDelete: trunk,
        });
        this.showConfirmDeleteModal();
    }

    getTrunks = async () => {
        try {
            console.log("[getTrunks] Invoking the back-end API to get all trunks of this tenant.");

            const trunks = await apiCalls.getTrunks(this.props.authenticationContext);

            this.setState({
                trunks: trunks,
            });               
        }
        catch (err) {
            console.log(`[getTrunks] Error retrieving the trunks: ${err}`);
            this.props.notificationContext.setCommunicationFailureNotification();                
        }
        finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    executeAddOrUpdate = async (doneInfo) => {
        const trunkAction = doneInfo.isUpdate ? 'updating' : 'adding';
        const trunkActionDone = doneInfo.isUpdate ? 'updated' : 'added';

        try {
            console.log(`[executeAddOrUpdate] Invoking the back-end API ${trunkAction} the trunk.`);

            if (doneInfo.isUpdate)
            {
                await apiCalls.updateTrunk(this.props.authenticationContext, doneInfo.sipTrunkId, doneInfo.name, doneInfo.autoAddEnabled);
            }
            else
            {
                await apiCalls.addTrunk(this.props.authenticationContext, doneInfo.name, doneInfo.autoAddEnabled);
            }

            
            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(trunkAction)} trunk.`, `Trunk '${doneInfo.name}' was successfully ${trunkActionDone}.`, 'success');
            
            this.getTrunks();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(trunkAction)} trunk.`, `Error ${trunkAction} trunk '${doneInfo.name}'. ${err.toDetailedMessage()}`, 'danger');
        }
        finally {
            this.hideTrunkModal();
        }
    }

    executeDelete = async () => {
        try {
            console.log(`[deleteTrunk] Invoking the back-end API to delete the trunk with Id ${this.state.trunkToDelete.sipTrunkId}.`);

            await apiCalls.deleteTrunk(this.props.authenticationContext, this.state.trunkToDelete.sipTrunkId);

            this.props.notificationContext.setNotification(`Deleting trunk.`, `Trunk '${this.state.trunkToDelete.name}' was successfully deleted.`, 'success');

            this.getTrunks();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`Deleting trunk.`, `Error deleting trunk '${this.state.trunkToDelete.name}'. ${err.toDetailedMessage()}`, 'danger');
        }

        this.hideConfirmDeleteModal();
    }

    shouldAddTrunkButtonBeEnabled = () => {
        if (this.state.trunks.length >= maximumNumberOfTrunks) {
            return false;
        }

        return true;
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render () {
        const headerRendered = <h1>SIP trunk settings</h1> 

        if (this.state.isLoading) {
            return (
                <Container fluid>
                    {headerRendered}
                    {this.props.tenantContext.showWarningWhenDisabled()}
                    <p><em>Loading...</em></p>
                </Container>
            );
        }

        const trunksRendered = this.state.trunks.length > 0 ? 
            <TrunkEntries tenant={this.props.tenantContext} trunks={this.state.trunks} handleDelete={this.handleTrunkDelete} handleEdit={this.handleTrunkEdit}/> : 
            <Alert className="mt-3" variant='warning' transition={false}>
                No trunks configured. Please add a trunk which will be connected to the CyberGate service.
            </Alert>;

        return (
            <Container fluid>
                <ModalTrunkAddOrUpdate isOpen={this.state.isTrunkModalOpen} isUpdate={this.state.isTrunkModalUpdate} tenant={this.props.tenantContext} trunkToUpdate={this.state.trunkToUpdate} onHide={this.hideTrunkModal} onAddOrUpdate={this.executeAddOrUpdate} />
                <ModalTrunkConfirmDelete isOpen={this.state.isConfirmDeleteModalOpen} trunkToDelete={this.state.trunkToDelete} onHide={this.hideConfirmDeleteModal} onConfirmed={this.executeDelete} />
                {headerRendered}
                <p>The username and password for your trunks are automatically generated when a trunk is added.</p>
                <ButtonWithDisabledTooltip color="primary" disabled={!this.shouldAddTrunkButtonBeEnabled()} disabledTooltip="Maximum number of SIP trunks defined" onClick={this.showAddTrunkModal}>Add SIP trunk</ButtonWithDisabledTooltip>
                {trunksRendered}
                <br/>
                <br/>
            </Container>
        );
    }
}

// Wrap the component so that multiple contexts are available via the props.
export const TrunkSettings = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <TrunkSettingsInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}