import React from 'react';
import { SubscriptionEntry } from './SubscriptionEntry'; 
import { InfoIcon }  from './InfoIcon';

export const SubscriptionEntries = ({ subscriptions, isMarketplace, handleEdit }) => {
    return (
        <div>
            <table className="table table-striped table-bordered my-2">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Created</th>
                        {isMarketplace ? <th scope="col">Plan</th> : null}
                        <th scope="col">Quantity</th>
                        {isMarketplace ? <th scope="col">Requested quantity</th> : null}
                        <th scope="col">
                            Enabled
                            {isMarketplace ? <InfoIcon tooltip='The CyberGate service only works when the subscription is enabled. This value is controlled by the Microsoft Marketplace.'/> : null}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions.map((value) => {
                        return <SubscriptionEntry key={value.subscriptionId} subscription={value} isMarketplace={isMarketplace} handleEdit={handleEdit}/>
                    })
                    }
                </tbody>
            </table>           
        </div>
    )
}