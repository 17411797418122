import { Component } from 'react';
import { TrunkDeviceEntries } from './TrunkDeviceEntries';
import { Alert } from 'react-bootstrap';

class TrunkDeviceGroupInternal extends Component {
    state = {
        newParticipantUsername: "",
    }

    handleAddDevice = () => {
        this.props.onAddDevice(this.props.trunk);
    }

    handleEditDevice = (device) => {
        this.props.onDeviceEdit(this.props.trunk, device);
    }

    render () {
        const renderedDeviceEntries = this.props.devices.length > 0 ?
            <TrunkDeviceEntries tenant={this.props.tenant} devices={this.props.devices} devicesStatus={this.props.devicesStatus} handleDelete={this.props.onDeviceDelete} handleEdit={this.handleEditDevice} isTrunkedDevice={true}/>:
            <>
                <br/>
                <br/>
                <Alert variant='warning' transition={false}>
                    SIP Trunk has no devices.
                </Alert>
            </>;

        return (
            <div className="card bg-light m-3 mt-5">
                <div className="card-header">
                    <h5>{this.props.trunk.name}</h5>
                </div>
                <div className="card-body">
                    {/* <Button color="primary" onClick={this.handleAddDevice}>Add device</Button> */}
                    {renderedDeviceEntries}
                </div>
            </div>
        );
    }
}

export const TrunkDeviceGroup = (props) => {
  
    return (
        <TrunkDeviceGroupInternal {...props}/>
    )
}