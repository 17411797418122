import React, { useContext } from 'react';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuHeader } from './SideMenuHeader';
import { TenantContext } from '../../context/TenantContext';
import { urls } from '../../logic/urls';

import {
    DropdownItem
} from 'reactstrap';

export const MobileMenu = () => {

    const tenantContext = useContext(TenantContext);

    var isAdminConsentGranted = tenantContext.adminConsentGrantedFeatures.includes('initial');
    const isDistributor = tenantContext.isDistributor;
    const isReseller = tenantContext.isReseller;
    const isTrunkSupportEnabled = tenantContext.trunkSupportEnabled;

    const AdministrationMenuRendered = <>
        <SideMenuHeader text="Administration" />
        <SideMenuItem text="Licensing" icon="file-alt" url={urls.license} />
        {isDistributor ? isReseller ?
            <SideMenuItem text="Reseller" icon="exchange-alt" url={urls.reseller} /> :
            <SideMenuItem text="Distributor" icon="exchange-alt" url={urls.distributor} /> :
            null}
    </>;

    const BasicMenuRendered = <>
        <SideMenuHeader text="Basic" />
        <SideMenuItem text="Global" icon="globe" url={urls.tenant} />
        <SideMenuItem text="Network" icon="shield-alt" url={urls.network}/>
        {isAdminConsentGranted ?
            <>
                <SideMenuItem text="Portal access" icon="key" url={urls.portalAccess} />
                {isTrunkSupportEnabled ?
                    <SideMenuItem text="SIP trunk" icon="sign-in-alt" url={urls.trunk} /> :
                    null
                }
                <SideMenuItem text="Device" icon="cogs" url={urls.device} />
                <SideMenuItem text="Multi-ring" icon="arrows-alt" url={urls.multiring} />
            </> :
            null
        }
    </>;

    const CameraMenuRendered = <>
        {isAdminConsentGranted ?
            <>
                <SideMenuHeader text="Camera" />
                <SideMenuItem text="Meeting" icon="users" url={urls.meeting} />
            </> :
            null
        }
    </>;

    const AppMenuRendered = <>
        {isAdminConsentGranted ?
            <>
                <SideMenuHeader text="Teams App" />
                <SideMenuItem text="Availability" icon="user-circle" url={urls.multiringAccess} />
                <SideMenuItem text="Device" icon="window-restore" url={urls.deviceAccess} />
            </> :
            null
        }
    </>;

    return (
        <>
            {AdministrationMenuRendered}
            {BasicMenuRendered}
            {CameraMenuRendered}
            {AppMenuRendered}
            <DropdownItem divider />
        </>
    )
}