import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import * as Misc from '../utils/misc';
import { ButtonWithDisabledTooltip } from './ButtonWithDisabledTooltip';


export class ModalSubscriptionUpdate extends Component {
    quantityInitial = 0;
    quantityMinimal = 0;
    quantityMaximum = 0;

    state = {
        isMarketplace: false,
        quantityCurrent: 0,

    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        this.quantityInitial = this.props.quantity;
        this.quantityMinimum = this.props.quantityMinimum ? this.props.quantityMinimum : 0;
        this.quantityMaximum = Misc.intMaxValue;

        if (this.props.quantityMaximum) {
            this.quantityMaximum = this.props.quantityMaximum;
        } else if (this.props.subscriptionCountAvailable) {
            this.quantityMaximum = Misc.intLimitedSum(this.props.quantity, this.props.subscriptionCountAvailable);
        }

        this.setState({
            quantityCurrent: this.props.quantity,
        });
    }

    handleQuantityChanged = (e) => {
        let newQuantity = e.target.value;

        if (newQuantity < 0) {
            newQuantity = 0;
        }

        this.setState({
            quantityCurrent: newQuantity
        });
    }

    handleUpdate = () => {
        this.props.onUpdate(parseInt(this.state.quantityCurrent));
    }

    updateButtonEnabled = () => {
        return this.getUpdateButtonTooltip() === null;
    }

    getUpdateButtonTooltip = () => {
        if (this.state.quantityCurrent === this.quantityInitial) {
            return "No changes detected";
        }

        if (this.state.quantityCurrent > this.quantityMaximum) {
            return `Maximum license count is ${this.quantityMaximum}`
        }

        if (this.state.quantityCurrent < this.quantityMinimum) {
            return `Minimum license count is ${this.quantityMinimum}`
        }
        return null;
    }

    render()
    {
        const subscriptionLabel = this.props.label ? ` for '${this.props.label}'` : ""

        return <>
            <Modal style={{ opacity: 1 }} show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${this.props.isMarketplace ? "Request update of" : "Update"} license count${subscriptionLabel}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="mt-3" onSubmit={e => e.preventDefault()}>
                        <Form.Group controlId="setTenantLicenseCount">
                            <Form.Label>Set number of licenses</Form.Label>
                            <Form.Control type="number" value={this.state.quantityCurrent} onChange={this.handleQuantityChanged}/>
                            <Form.Text className="text-muted">
                                1 license allows 1 device to register to the CyberGate
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <ButtonWithDisabledTooltip color="primary" disabled={!this.updateButtonEnabled()} disabledTooltip={this.getUpdateButtonTooltip()} onClick={this.handleUpdate}>
                        {this.props.isMarketplace ? "Request" : "Update"}
                    </ButtonWithDisabledTooltip >
                </Modal.Footer>
            </Modal>
        </>;
    }
}