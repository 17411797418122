import { Component } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as Utils from '../utils/helper';
import * as Misc from '../utils/misc';
import { EditButton } from './EditButton';
import { InfoIcon }  from './InfoIcon';

import css from './TenantEntry.module.scss';

export class TenantEntry extends Component {
    getLabelWithMaxLength = (label, maxLenght) => {
        if (!label) {
            return null;
        }

        if (label.length <= maxLenght) {
            return <>{label}</>
        }

        const labelTrimmed = label.substring(0, maxLenght - 2).trim() + "...";

        return <>
            <OverlayTrigger 
                placement='right'
                overlay={
                    <Tooltip>
                        {label}
                    </Tooltip>
                }
            >
                <span>{labelTrimmed}</span>
            </OverlayTrigger>
        </>;
    }

    handleTenantEdit  = () => {
        this.props.onEdit(this.props.tenant);
    }

    render() {
        const tenant = this.props.tenant;
        const colorClass = Utils.isTrue(tenant.enabled) ? css.tableCellGreen : css.tableCellRed;

        let tenantQuantity = "-";
        if (Utils.isTrue(tenant.enabled)) {
            tenantQuantity = Misc.intToString(tenant.quantityRecursive);
        } 

        return (
            <tr>
                <td className="text-left">
                    <div className={css.flexContainer}>
                        <div>
                            {tenant.tenantLabel}
                        </div>
                        <div className={css.flexItemRight}>
                            <InfoIcon className="float-right" tooltip={`Tenant ID: ${tenant.tenantId}`} />
                        </div>
                    </div>
                </td>
                <td className="text-left">
                    <div>
                        {this.getLabelWithMaxLength(tenant.emailAddress, 50)}
                    </div>
                </td>
                <td className="text-center">
                    {String(tenant.creationTime).split('T')[0]}
                </td>
                <td className={`text-center ${colorClass}`}>
                    {Utils.isTrue(tenant.enabled) ? 'yes' : 'no'}
                </td>
                <td className="text-right">
                    {tenantQuantity}
                    {this.props.editEnabled && <EditButton className="float-left" tooltip="Edit tenant" onClick={this.handleTenantEdit}/> }
                </td>                       
            </tr>
        )
    }
}