import { Component } from 'react';

import css from './MarketplaceReport.module.scss';

export class MarketplaceReportPayment extends Component {
    render() {
        const month = this.props.month;
        const reportLine = this.props.reportLine;
        const lastTransaction = this.props.lastTransaction;
        const showExpectedPayments = this.props.showExpectedPayments;
        const showActualPayments = this.props.showActualPayments;
        const markDifferences = this.props.markDifferences;

        const expectedPaymentLine = showExpectedPayments && month.expectedPayment > 0 ? <p className={`${css.tableCellTextSmall} ${css.textColorPurple}`}>{month.expectedQuantity + ") $" + month.expectedPayment.toFixed(2)}</p> : null
        const actualPaymentLine = showActualPayments && month.actualPayment !== 0 ? <p className={`${css.tableCellTextSmall} ${css.textColorBlue}`}>{month.actualQuantity + ") $" + month.actualPayment.toFixed(2)}</p> : null

        const missingPayment = month.expectedPayment > 0 && 
                                (month.actualPayment <= 0 ||
                                 month.actualPayment < 0.75 * month.expectedPayment);
        const extraPayment = month.actualPayment > 0 && 
                                 (month.expectedPayment <= 0 ||
                                  month.expectedPayment < 0.75 * month.actualPayment);
        const extraNegativePayment = month.actualPayment < 0;

        const futureMonth = lastTransaction < month.date;                            

        const marking = !futureMonth && markDifferences && missingPayment ? css.tableBorderedRed : 
                        !futureMonth && markDifferences && extraPayment ? css.tableBorderedGreen : 
                        !futureMonth && markDifferences && extraNegativePayment ? css.tableBorderedRed : 
                        "";

        if (month.firstMonth) {
            return <td className={`align-middle ${month.lastMonth?css.tableCellOrange:css.tableCellLightGreen} ${css.tableCellAlignCenter} ${marking}`}>
                    <p>{parseInt(reportLine.creationTime.substring(8, 10)) + "th"}</p>
                    {actualPaymentLine}
                </td>;
        } else if (month.lastMonth) {
            return <td className={`align-middle ${css.tableCellOrange} ${css.tableCellAlignCenter} ${marking}`}>
                    {expectedPaymentLine}
                    {actualPaymentLine ? actualPaymentLine : <p>-</p>}
                </td>;
        } else if (month.expectedPayment > 0) {
            return <td className={`align-middle ${month.active?css.tableCellGreen:""} ${css.tableCellAlignRight} ${marking}`}>
                    {expectedPaymentLine}
                    {actualPaymentLine ? actualPaymentLine : <p>-</p>}
                </td>;
        } else if (month.active) {
            return <td className={`align-middle ${css.tableCellLightGreen} ${marking}`}>
                    {actualPaymentLine ? <p>-</p> : null}
                    {actualPaymentLine}
                </td>
        }
        
        return <td className={`align-middle ${marking}`}>
                    {actualPaymentLine ? <p>-</p> : null}
                    {actualPaymentLine}
                </td>;
    }
}