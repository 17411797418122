import React, { Component, useContext } from 'react';
import { Nav } from 'reactstrap';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuHeader } from './SideMenuHeader';
import { TenantContext } from '../../context/TenantContext';
import { urls } from '../../logic/urls';

import css from './SideMenu.module.scss';

class SideMenuInternal extends Component {
    render() {
        var isAdminConsentGranted = this.props.tenantContext.adminConsentGrantedFeatures.includes('initial');
        const isDistributor = this.props.tenantContext.isDistributor;
        const isReseller = this.props.tenantContext.isReseller;
        const isTrunkSupportEnabled = this.props.tenantContext.trunkSupportEnabled;

        const AdministrationMenuRendered = <>
            <SideMenuHeader text="Administration" />
            <SideMenuItem text="Licensing" icon="file-alt" url={urls.license}/>
            {isDistributor ? isReseller ?
                <SideMenuItem text="Reseller" icon="exchange-alt" url={urls.reseller}/> :
                <SideMenuItem text="Distributor" icon="exchange-alt" url={urls.distributor}/> :
                null }
        </>;

        const BasicMenuRendered = <>
            <SideMenuHeader text="Basic" />
            <SideMenuItem text="Global" icon="globe" url={urls.tenant}/>
            <SideMenuItem text="Network" icon="shield-alt" url={urls.network}/>
            {isAdminConsentGranted ? 
                <>
                    <SideMenuItem text="Portal access" icon="key" url={urls.portalAccess} />
                    {isTrunkSupportEnabled ? 
                        <SideMenuItem text="SIP trunk" icon="sign-in-alt" url={urls.trunk}/> :
                        null
                    }
                    <SideMenuItem text="Device" icon="cogs" url={urls.device}/>
                    <SideMenuItem text="Multi-ring" icon="arrows-alt" url={urls.multiring}/>
                </> :
                null
            }
        </>;

        const CameraMenuRendered = <>
            {isAdminConsentGranted ? 
                <>
                    <SideMenuHeader text="Camera" />
                    <SideMenuItem text="Meeting" icon="users" url={urls.meeting}/>
                </> : 
                null
            }
        </>;

        const AppMenuRendered = <>
            {isAdminConsentGranted ? 
                <>
                    <SideMenuHeader text="Teams App" />
                    <SideMenuItem text="Availability" icon="user-circle" url={urls.multiringAccess}/>
                    <SideMenuItem text="Device" icon="window-restore" url={urls.deviceAccess}/>
                </> : 
                null
            }
        </>;

        return (
            <div className={css.sideMenu}>
                <Nav vertical={true} tabs={false}>
                    <div className="flex-grow">
                        {AdministrationMenuRendered}
                        {BasicMenuRendered}
                        {CameraMenuRendered}
                        {AppMenuRendered}
                    </div>
                </Nav>
            </div>
        );
    }
}

// Wrap the wrapped SideMenu component so that the UserContext and AdminConsentContext are accessible via the props.
export const SideMenu = (props) => {
    const tenantContext = useContext(TenantContext);
  
    return (
        <SideMenuInternal tenantContext={tenantContext} {...props}/>
    )
}