import { Component } from 'react';
import { CallGroupSupervisor } from './CallGroupSupervisor';

export class CallGroupSupervisors extends Component {
    render () 
    {
        return (
            <ul>
                {this.props.supervisors.map((value) => {
                    return <li key={value.id}>
                        <CallGroupSupervisor supervisor={value} iconSize={"25px"} />
                    </li>
                })}                   
            </ul>               
        );
    }
}