import moment from 'moment';

// Method calculates the number of hours, minutes and seconds from the provided number of seconds.
function partitionDuration(seconds) {
    let hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;

    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    seconds = Math.round(seconds);

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
    }
}

export function durationToDisplayString(durationSeconds) {
    var duration = partitionDuration(durationSeconds);
    
    const hourText = duration.hours > 1 ? "hours" : "hour";
    const minuteText = duration.minutes > 1 ? "minutes" : "minute";
    const secondText = duration.seconds > 1 ? "seconds" : "second";

    if (duration.hours > 0) {
        return `${duration.hours} ${hourText} ${duration.minutes} ${minuteText}`;
    } else if (duration.minutes > 0) {
        return `${duration.minutes} ${minuteText} ${ duration.seconds} ${secondText}`;
    } else {
        return `${duration.seconds} ${secondText}`;
    }
}

export function dateStringToDisplayString(dateString) {
    const dateTime = moment(dateString);
    return dateTime.local().format("D MMM yyyy HH:mm:ss");
}

function valueToString(value, minimumLength = 0) {
    let stringValue = `${value}`;

    if (stringValue.length < minimumLength) {
        stringValue = '0'.repeat(minimumLength - stringValue.length) + stringValue;
    }
    
    return stringValue;
}

export function durationToPlayerDisplayString(durationSeconds) {
    const duration = partitionDuration(durationSeconds);

    return valueToString(duration.hours) + ":" + valueToString(duration.minutes, 2) + ":" + valueToString(duration.seconds, 2);
}

export function durationToTranscriptionDisplayString(durationSeconds) {
    const duration = partitionDuration(durationSeconds);

    if (duration.hours > 0) {
        return valueToString(duration.hours) + ":" + valueToString(duration.minutes, 2) + ":" + valueToString(duration.seconds, 2);
    } else {
        return valueToString(duration.minutes, 2) + ":" + valueToString(duration.seconds, 2);
    }    
}

export function getReadableTimestamp() {
    const now = moment();
    return `${now.format("YYYY-MM-DD HH:mm:ss")}.${valueToString(now.milliseconds(),3)}`;
}