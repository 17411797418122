import { useContext, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import { Container } from 'reactstrap';
import Alert from 'react-bootstrap/Alert';
import { AuthenticationContext } from '../../msal/AuthenticationContext';
import { TenantContext } from '../../context/TenantContext';
import { NotificationContext } from '../../context/NotificationContext';
import { SubscriptionEntries } from '../../components/SubscriptionEntries';
import { ModalSubscriptionUpdate } from '../../components/ModalSubscriptionUpdate';
import * as apiCalls from '../../logic/apiCalls';
import * as Misc from '../../utils/misc';

class LicenseOverviewInternal extends Component {
    subscriptionBeingEdited = {};
    subscriptionBeingEditedIsMarketplace = false;
    newQuantity = null;
    confirmChangeQuantityText = "";
    refreshSubscriptionsTimer = 0;
    refreshSubscriptionsTimeoutTimer = 0;

    state = {
        isChangeQuantityModalOpen: false,
        isConfirmChangeQuantityModalOpen: false,
    }

    async componentDidMount() {
        await this.refreshSubscriptions();
        this.refreshSubscriptionsTimerStart();
    }

    componentWillUnmount() {
        this.refreshSubscriptionsTimerStop();
    }

    refreshSubscriptionsTimerStart = () => {
        console.log("[refreshSubscriptionsTimerStart]");

        this.refreshSubscriptionsTimer = setInterval(this.refreshSubscriptions, 5000); 
        this.refreshSubscriptionsTimeoutTimer = setInterval(this.refreshSubscriptionsTimerStop, 2* 60 * 60 * 1000); 
    }

    refreshSubscriptionsTimerStop = () => {
        console.log("[refreshSubscriptionsTimerStop]");

        clearTimeout(this.refreshSubscriptionsTimer);
        clearTimeout(this.refreshSubscriptionsTimeoutTimer);
    }
    
    refreshSubscriptions = () => {
        this.props.tenantContext.refreshSubscriptions();
    }

    showChangeQuantityModal = () => {
        this.setState({
            isChangeQuantityModalOpen: true,
        });
    };

    hideChangeQuantityModal = () => {
        this.setState({
            isChangeQuantityModalOpen: false,
        });
    };

    showConfirmChangeQuantityModal = () => {
        this.setState({
            isConfirmChangeQuantityModalOpen: true,
        });
    };

    hideConfirmChangeQuantityModal = () => {
        this.setState({
            isConfirmChangeQuantityModalOpen: false,
        });
    };

    executeChangeQuantity = async () => {
        const LicenseAction = this.subscriptionBeingEditedIsMarketplace ? "requesting" : "updating"
        
        try {
            if (this.subscriptionBeingEditedIsMarketplace)
            {
                await apiCalls.updateMarketplaceSubscriptionQuantity(this.props.authenticationContext,
                    this.subscriptionBeingEdited.subscriptionId,
                    this.newQuantity);
            } else {
                await apiCalls.updateDistributorQuantity(this.props.authenticationContext,
                    this.newQuantity);
            }

            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(LicenseAction)} license`, `New quantity for license '${this.subscriptionBeingEdited.name}' successfully ${this.subscriptionBeingEditedIsMarketplace ? "requested" : "updated"}`, 'success');
            this.props.tenantContext.refreshSubscriptions();
        }
        catch (err) {
            this.props.notificationContext.setNotification(`${Misc.capitalizeFirstLetter(LicenseAction)} license`, `Error ${LicenseAction} new quantity for license '${this.subscriptionBeingEdited.name}'.  ${err.toDetailedMessage()}`, 'danger');            
        } finally {
            this.subscriptionBeingEdited = {};
            this.subscriptionBeingEditedIsMarketplace = false;
            this.hideConfirmChangeQuantityModal();
        }
    }

    handleEdit = (subscription, quantity, isMarketplace) => {
        this.subscriptionBeingEdited = subscription;
        this.subscriptionBeingEditedIsMarketplace = isMarketplace
        this.newQuantity = quantity;

        this.showChangeQuantityModal();
    }

    handleExecuteChangeQuantityRequest = (newQuantity) => {
        this.newQuantity = newQuantity;
        this.hideChangeQuantityModal();
            
        if (this.subscriptionBeingEditedIsMarketplace) {
            const isIncrease = newQuantity > this.subscriptionBeingEdited.quantity;
            let message = `Are you sure you want update to your license count for '${this.subscriptionBeingEdited.name}' to ${newQuantity}?\r\n`;

            if (isIncrease) {
                message += 'This will increase the amount that is billed for this license.';
            }
            else {
                message += `If you have currently more than ${newQuantity} devices active, one will become unlicensed.`;
            }

            this.confirmChangeQuantityText = message;
            this.showConfirmChangeQuantityModal();
        } else {
            this.executeChangeQuantity();
        }
    }

    getSubscriptionsRendered = () => {
        let numberOfSubscriptionTypes = 0;
        let hasMarketplaceSubscriptions = false;
        let hasDistributorSubscriptions = false;
        let hasSelfSubscriptions = false;
        let hasManualSubscriptions = false;

        if (this.props.tenantContext.marketplaceSubscriptions.length > 0) {
            numberOfSubscriptionTypes++;
            hasMarketplaceSubscriptions = true;
        }

        if (this.props.tenantContext.distributorSubscriptions.length > 0) {
            numberOfSubscriptionTypes++;
            hasDistributorSubscriptions = true;
        }

        if (this.props.tenantContext.selfSubscriptions.length > 0) {
            numberOfSubscriptionTypes++;
            hasSelfSubscriptions = true;
        }

        if (this.props.tenantContext.manualSubscriptions.length > 0) {
            numberOfSubscriptionTypes++;
            hasManualSubscriptions = true;
        }

        // Only show type titles when there is more than 1 subscription type.
        const showTypeTitles = numberOfSubscriptionTypes > 1;

        const marketplaceSubscriptionsRendered = hasMarketplaceSubscriptions ?
            <>
                {showTypeTitles ? <div>
                    <h4>Marketplace</h4>
                </div>: null}
                <div>
                    <SubscriptionEntries subscriptions={this.props.tenantContext.marketplaceSubscriptions} isMarketplace={true} handleEdit={this.handleEdit}/>
                </div>
            </> :
            null;

        const distributorSubscriptionsRendered = hasDistributorSubscriptions ?
            <>
                {showTypeTitles ? <div>
                    <h4>Managed by distributor</h4>
                </div>: null}
                <div>
                    <SubscriptionEntries subscriptions={this.props.tenantContext.distributorSubscriptions} isMarketplace={false} />
                </div>
            </> :
            null;

        const selfSubscriptionsRendered = hasSelfSubscriptions ?
            <>
                {showTypeTitles ? <div>
                    <h4>For own use</h4>
                </div>: null}
                <div>
                    <SubscriptionEntries subscriptions={this.props.tenantContext.selfSubscriptions} isMarketplace={false} handleEdit={this.handleEdit}/>
                </div>
            </> :
            null;

        const manualSubscriptionsRendered = hasManualSubscriptions ?
            <>
                {showTypeTitles ? <div>
                    <h4>Fixed</h4>
                </div>: null}
                <div>
                    <SubscriptionEntries subscriptions={this.props.tenantContext.manualSubscriptions} isMarketplace={false} />
                </div>
            </> :
            null;

        return <>
            {marketplaceSubscriptionsRendered}
            {distributorSubscriptionsRendered}
            {selfSubscriptionsRendered}
            {manualSubscriptionsRendered}
        </>;
    }

    // ------------------------------
    // Rendering starts here...
    // ------------------------------
    render() {
        const headerRendered = <h1>License overview</h1>

        const confirmChangeQuantityModalRendered = <>
            <Modal style={{ opacity: 1 }} show={this.state.isConfirmChangeQuantityModalOpen} onHide={this.hideConfirmChangeQuantityModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm request</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.confirmChangeQuantityText}</Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={this.hideConfirmChangeQuantityModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.executeChangeQuantity}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

        const subscriptionsRendered = this.props.tenantContext.manualSubscriptions.length <= 0 && 
            this.props.tenantContext.marketplaceSubscriptions.length <= 0 &&
            this.props.tenantContext.distributorSubscriptions.length <= 0 &&
            this.props.tenantContext.selfSubscriptions.length <= 0 ? 
            <div>
                <Alert variant='warning' style={{ opacity: 1 }}>
                    No subscriptions found for this tenant.<br/>
                    Please use the 'Activate' link in the e-mail you received from Microsoft to add the subscription.
                </Alert>
            </div> : 
            this.getSubscriptionsRendered();

        return (
            <Container fluid>
                <ModalSubscriptionUpdate 
                    label={this.subscriptionBeingEdited?.name}
                    quantity={this.newQuantity}
                    isMarketplace={this.subscriptionBeingEditedIsMarketplace}
                    show={this.state.isChangeQuantityModalOpen}
                    onHide={this.hideChangeQuantityModal}
                    onUpdate={this.handleExecuteChangeQuantityRequest} />
                {confirmChangeQuantityModalRendered}
                {headerRendered}
                {this.props.tenantContext.showWarningWhenDisabled()}
                {subscriptionsRendered}
                <br/>
                <br/>          
            </Container>
        );
    }
}

export const LicenseOverview = (props) => {
    const authenticationContext = useContext(AuthenticationContext);
    const notificationContext = useContext(NotificationContext);
    const tenantContext = useContext(TenantContext);
  
    return (
        <LicenseOverviewInternal authenticationContext={authenticationContext} notificationContext={notificationContext} tenantContext={tenantContext} {...props}/>
    )
}