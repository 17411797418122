import { PureComponent } from 'react';
import { Spinner } from 'react-bootstrap';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getColor } from '../logic/chartLogic';

export class ChartLine extends PureComponent {
    // The ResponsiveContainer of the Recharts library has an resizing issue when used inside a flex container. 
    // The work-around described here is applied:
    // https://github.com/recharts/recharts/issues/172#issuecomment-523362671

    render() {

        const loadingOverlayRendered = (<div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spinner animation="border" variant="secondary" />
        </div>)

        const yDomain = this.props.limits ? [this.props.limits[0].minValue, this.props.limits[0].maxValue] : ['auto', 'auto'];

        return (
            <div style={{ position: 'relative', width: '100%', paddingBottom: '300px' }}>

                {this.props.isLoading ? loadingOverlayRendered : (<div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, }}>
                        <ResponsiveContainer>
                            <LineChart
                                width={500}
                                height={300}
                                data={this.props.data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey={this.props.keyName} />
                                <YAxis domain={yDomain} allowDataOverflow/>
                                <Legend/>
                                <Tooltip />
                                {
                                    this.props.lines.map((lineData, index, array) => {
                                        return <Line key={lineData.valueName} stackId={this.props.stacked ? "1" : index } name={lineData.displayName} dataKey={lineData.valueName} stroke={getColor(index, array.length)} strokeWidth={5}/>
                                    })
                                }                            
                            </LineChart>
                        </ResponsiveContainer>
                    </div>) }
            </div>
        )
    }
}