import React from 'react';
import { TrunkDeviceGroup } from './TrunkDeviceGroup'

export const TrunkDeviceGroups = ({ tenant, trunks, devices, devicesStatus, onDeviceEdit, onDeviceDelete, onAddDevice }) => {
    return (
        <div>
            {trunks.map((trunk) => {
                const trunkDevices = devices.filter(device => device.sipTrunkId === trunk.sipTrunkId);

                return <TrunkDeviceGroup 
                    key={trunk.sipTrunkId}
                    tenant={tenant}
                    trunk={trunk}
                    devices={trunkDevices}
                    devicesStatus={devicesStatus}
                    onDeviceEdit={onDeviceEdit}
                    onDeviceDelete={onDeviceDelete}
                    onAddDevice={onAddDevice}  />
            })}        
        </div>
    )
}