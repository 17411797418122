import React from 'react';
import { EditButton } from './EditButton';
import * as Utils from '../utils/helper';

// The possible states that a MarketPlace subscription can have.
/*
const saasSubscriptionStatusDescription = {
    0: "NotStarted",
    1: "PendingFulfillmentStart",
    2: "Subscribed",
    3: "Unsubscribed",
    4: "Suspended",
    5: "Unsubscribed"
} 
*/

export const SubscriptionEntry = ({subscription, isMarketplace, handleEdit}) => {
    const enabled = Utils.isTrue(subscription.enabled);
    const allowEdit = Utils.isTrue(subscription.allowEdit);

    const editButtonRendered = handleEdit && enabled && allowEdit ? <EditButton className="float-right" tooltip="Edit subscription" onClick={
        () => handleEdit(
            subscription,
            isMarketplace ? subscription.requestedQuantity : subscription.quantity,
            isMarketplace)}/> : null;

    return (
        <tr>
            <td className="align-middle">
                {subscription.name}
            </td>
            <td className="align-middle">
                {String(subscription.creationTime).split('T')[0]}
            </td>
            {isMarketplace ? 
                <td className="align-middle">
                    {subscription.planId}
                </td> :
                null
            }
            <td className="align-middle">
                {subscription.quantity}
                {isMarketplace ? null : editButtonRendered}
            </td>
            {isMarketplace ? 
                <td className="align-middle">
                    <span style={{"verticalAlign":"middle"}}>
                        {allowEdit ? subscription.requestedQuantity : "-"}
                    </span>
                    {editButtonRendered}
                </td> :
                null
            }
            <td className="align-middle">
                {enabled ? 'yes' : 'no'}
            </td>           
        </tr>
    )
}