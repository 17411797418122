import React from 'react';
import { DeleteButton } from './DeleteButton';
import { WarningIconWithTooltip } from './WarningIconWithTooltip';

import css from './CallGroupParticipant.module.scss';

export const CallGroupParticipant = ({participant, handleParticipantDelete}) => {
    return (
        <tr>
            <td className="align-middle">
                <div className={css.flexContainer}>
                    <div>
                        {participant.teamsUserName}
                    </div>
                    <div className={css.flexItemRight}>
                        <WarningIconWithTooltip show={!participant.isValid} tooltip="Teams username cannot be resolved. This user will not be called by CyberGate." />
                    </div>
                </div>
            </td>
            <td className="align-middle">
                <DeleteButton className="float-right" tooltip="Delete participant" onClick={() => handleParticipantDelete(participant)} />
            </td>
        </tr>
    )
}