import React, { Fragment } from 'react';
import { TrunkDeviceEntry } from './TrunkDeviceEntry'; 
import { InfoIcon }  from './InfoIcon';

export const TrunkDeviceEntries = ({ tenant, devices, devicesStatus, handleDelete, handleEdit }) => {
   
    const renderDevices = () => {
        const devicesRendered = devices.map(device => {
            const deviceStatus = devicesStatus.find(deviceStatus => deviceStatus.deviceId === device.deviceId)

            return <TrunkDeviceEntry key={device.deviceId} tenant={tenant} device={device} deviceStatus={deviceStatus} handleDelete={handleDelete} handleEdit={handleEdit}/>
        })
        
        return <Fragment>
                {devicesRendered}
            </Fragment>;
    }

    return (
        <div>
            <table className="table table-striped table-bordered my-2">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Display name</th>
                        <th scope="col">SIP username</th>
                        <th scope="col">
                            Licensed
                            <InfoIcon tooltip='The device will be licensed automatically when the total quantity of all the available licenses is sufficient. A non-licensed device will not be able to connect to the CyberGate service.'/>                            
                        </th>
                        <th scope="col">Recorded</th>
                        <th scope="col">Teams to device</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderDevices()}
                </tbody>
            </table>           
        </div>
    )
}